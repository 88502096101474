import { BannerType, ShareServices } from '@client/types'

export const MDZ_PN_TOKEN_KEY = 'schonFirebasePushToken'
export const MDZ_AUTH_FIREBASE_TOKEN_KEY = 'schonAuthFirebaseToken'
export const MDZ_DEFAULT_FIREBASE_TOPIC = 'breaking_news_js'
export const MDZ_FIREBASE_USER_DATA = 'schonFirebaseUserDataNew'
export const FIREBASE_VAPID_KEY =
  'BOYOnObd_wXJAxFtZhqyOnfIDndfMZny1oQ4FSaovGMyAwzjY4pSeklHsS6-fxBXDGtwf38cCY74U_4jtpi3F6U'

export const FIREBASE_FUNCTIONS = {
  SUBSCRIBE_TO_PLAN: 'subscribeToPlan',
  DONATE_ONCE: 'donateOnce',
  UNSUBSCRIBE: 'deleteSubscription',
  MERGE_USERS: 'mergeUsers',
  SEND_MESSAGE: 'sendMessage',
  DELETE_USER: 'deleteUser'
}

export const LIVE_SLIDES_ON_PAGE = 30

export enum ScreenViewType {
  LIST = 'LIST',
  GRID = 'GRID'
}

export const MediaQuerySizes = {
  MOBILE: 512,
  PORTRAIT_TABLET: 768,
  LANDSCAPE_TABLET: 1024,
  DESKTOP: 1010,
  WIDE_DESKTOP: 1200
}

export const MediaQueryLandscape = '(min-width: 64em)'

export const UIElementsSizes = {
  TOPBAR_HEIGHT: 45,
  TOPBAR_GAP: 15
}

export const CARD_PARAMS = {
  TOP_BAR_HEIGHT: 44,
  GAP: 15
}

export const LOGIN_TYPES = {
  GOOGLE: 'google',
  EMAIL: 'email-link',
  ANONYMOUS: 'anonymous'
}

export const REQUEST_STATUS = {
  SUCCESS: 'success',
  LOADING: 'loading',
  FAILURE: 'failure',
  NONE: 'none'
}

export const RE_CAPTCHA_KEY = '6LcM0iEfAAAAABz-lMzNTBlz9P1H-5yeCkeJJhJD'

export const utmSource: Record<ShareServices, string> = {
  fb: 'facebook.com',
  tw: 't.co',
  vk: 'vk.com',
  ok: 'ok.ru',
  tg: 'telegram.org',
  pdf: 'samizdat',
  unblock: 'unblock'
}

export const utmMedium: Record<ShareServices, string> = {
  fb: 'share_fb',
  tw: 'share_twitter',
  vk: 'share_vk',
  ok: 'share_ok',
  tg: 'share_telegram',
  pdf: 'share_samizdat',
  unblock: 'share_unblock'
}

export const MetaScreenTitles: Record<string, Record<string, string>> = {
  ru: {
    news: 'Schön Берлин',
    special: 'Schön'
  },
  en: {
    news: 'Schön'
  }
}

export const SCREENS = [
  '/',
  '/articles',
  '/cards',
  '/razbor',
  '/games',
  '/shapito',
  '/podcasts'
]

export const DFPCodes: Record<string, string> = {
  DESKTOP_AFT: 'div-gpt-ad-en-meduzaio41765',
  DESKTOP_STICKY: 'div-gpt-ad-en-meduzaio41766',
  DESKTOP_MAIN_ATF: 'div-gpt-ad-en-meduzaio41767',
  DESKTOP_MAIN_IN_CONTENT: 'div-gpt-ad-en-meduzaio41768',
  DESKTOP_NEWS_FEED: 'div-gpt-ad-en-meduzaio41769',
  DESKTOP_AFTER_MATERIAL: 'div-gpt-ad-en-meduzaio41770',
  DESKTOP_LONGREAD_BANNER: 'div-gpt-ad-en-meduzaio41771',
  MOBILE_TOP_BANNER: 'div-gpt-ad-en-meduzaio41772',
  MOBILE_IN_CONTENT: 'div-gpt-ad-en-meduzaio41773',
  MOBILE_AFTER_MATERIAL: 'div-gpt-ad-en-meduzaio41774'
}

export const BannerTypes: Record<string, BannerType> = {
  TOP: 'TOP',
  AFTER_MATERIAL: 'AFTER_MATERIAL',
  MAIN_IN_CONTENT: 'MAIN_IN_CONTENT',
  IN_CONTENT: 'IN_CONTENT',
  WITHIN: 'WITHIN',
  SIDE: 'SIDE'
}

// Exponential backoff for live saga constants

export const INITIAL_RECONNECT_DELAY = 1000
export const RANDOM_DELAY_INTERVAL = 2000
export const BACKOFF_FACTOR = 1.5
export const MAX_RECONNECT_DELAY = 16000

// Paywall
export const FREE_MATERIALS = [
  'feature/2023/12/07/polzovatelskoe-soglashenie',
  'feature/2023/12/07/obrabotka-dannyh',
  'feature/2023/12/07/o-nas',
  'feature/2023/11/29/mineralnyy-sekretar-gid-po-berlinskim-vodam',
  'feature/2024/03/11/usloviya-i-pravila-oformleniya-podpiski',
  'feature/2024/03/20/samye-chastye-voprosy-pro-podpisku'
]

// Plans

export const PLANS = {
  dev: {
    monthly: 'price_1Op8vnFttUZW9ruBy0FWo5qN',
    yearly: 'price_1Op8vUFttUZW9ruBI6N41aAe'
  },
  prod: {
    monthly: 'price_1Op8jyFttUZW9ruBev8Jt0xu',
    yearly: 'price_1Op8nIFttUZW9ruBw05fsCle'
  }
}

export const PLANS_BY_ID = {
  price_1Op8vnFttUZW9ruBy0FWo5qN: {
    title: 'На&nbsp;4&nbsp;недели'
  },
  price_1Op8vUFttUZW9ruBI6N41aAe: {
    title: 'На&nbsp;год'
  },
  price_1Op8jyFttUZW9ruBev8Jt0xu: {
    title: 'На&nbsp;4&nbsp;недели'
  },
  price_1Op8nIFttUZW9ruBw05fsCle: {
    title: 'На&nbsp;год'
  }
}
