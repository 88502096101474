import React, { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import { PopoverData, DotsOnImageProps, SingleDot } from './DotsOnImage.types'

import { Image } from '../Image'
import { Popover } from '../Popover'
import { Footnote } from '../Footnote'

import viewportSize from '../utils/viewportSize'
import { MediaQuerySizes } from '../constants'

import makeClassName from '../utils/makeClassName'

import styles from './DotsOnImage.module.css'

export const DotsOnImage: React.FC<DotsOnImageProps> = ({
  block: { optimized, width, height, credit, display, dots }
}) => {
  const [ratio, setRatio] = useState(1)
  const [ref, inView, entry] = useInView()

  const container = useRef(null)

  const [popover, setPopover] = useState<PopoverData>({
    title: null,
    body: null,
    show: false,
    id: null
  })

  const [visible, setVisible] = useState(false)
  const defaultWidth = 375

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  useEffect(() => {
    if (popover.id) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => document.removeEventListener('click', handleClickOutside)
  }, [popover])

  useEffect(() => {
    setDotSize()
    window.addEventListener('resize', setDotSize)

    return () => document.removeEventListener('resize', setDotSize)
  }, [visible])

  const setDotSize = () => {
    const width = window.innerWidth

    if (width < defaultWidth) {
      const ratio = width / defaultWidth
      setRatio(ratio)
    } else {
      setRatio(1)
    }
  }

  const handleClickOutside = (event: MouseEvent): void => {
    const path = event.composedPath()

    if (path.indexOf(container.current) === -1) {
      setPopover({ show: false })
    }
  }

  const handleDotClick = (dot: SingleDot): void => {
    const shouldShow = dot.id !== popover.id
    const side = dot.position.x > 50 ? 'isLeft' : 'isRight'
    const align = dot.position.y > 50 ? 'isBottom' : 'isTop'

    const style = { top: `${dot.position.y}%`, left: `${dot.position.x}%` }

    setPopover({
      title: dot.title,
      body: dot.body,
      show: shouldShow,
      id: shouldShow ? dot.id : null,
      style,
      side,
      align
    })
  }

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.isVisible, visible]
      ])}
      data-testid="dots-on-image"
      ref={ref}
    >
      <Image
        fullscreen={false}
        optimized={optimized}
        width={width}
        height={height}
        alt={credit}
        display={display}
      />

      <div ref={container}>
        {dots.map((dot) => (
          <div
            className={styles.dot}
            key={dot.id}
            style={{
              top: `${dot.position.y}%`,
              left: `${dot.position.x}%`,
              transform: `scale(${ratio}) translate(-${50 / ratio}%, -${
                50 / ratio
              }%)`
            }}
          >
            <button
              className={makeClassName([
                [styles.marker, true],
                [styles.isActive, dot.id === popover.id]
              ])}
              type="button"
              style={{
                backgroundColor: dot.icon.color
              }}
              onClick={(): void => handleDotClick(dot)}
            >
              {dot.icon.type === 'svg' && (
                <div
                  dangerouslySetInnerHTML={{ __html: dot.icon.svg_string }}
                />
              )}
              {dot.icon.type === 'number' && dot.icon.number}
            </button>
          </div>
        ))}

        {popover.show && (
          <>
            {viewportSize().width >= MediaQuerySizes.LANDSCAPE_TABLET ? (
              <div
                className={makeClassName([
                  [styles.popover, true],
                  [styles[popover.side], !!popover.side],
                  [styles[popover.align], !!popover.align]
                ])}
                style={popover.style}
              >
                <Footnote
                  onClose={(): void => {
                    setPopover({ show: false })
                  }}
                >
                  {popover.title && <h3>{popover.title}</h3>}
                  <div dangerouslySetInnerHTML={{ __html: popover.body }} />
                </Footnote>
              </div>
            ) : (
              <Popover
                onClose={(): void => {
                  setPopover({ show: false })
                }}
              >
                {popover.title && <h3>{popover.title}</h3>}
                <div dangerouslySetInnerHTML={{ __html: popover.body }} />
              </Popover>
            )}
          </>
        )}
      </div>
    </div>
  )
}
