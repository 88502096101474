import React from 'react'
import { ShareData, ToolbarButtonTypes } from '@client/types'
import { StyleContext } from '@client/types'

import { ToolbarContainerButtons } from './ToolbarContainerButtons'

/*
 * Тулбар с кнопками шеров и кнопкой закладки, который появляется при
 * скролле материала сверху (в TopBar)
 * и есть внизу каждого материала
 */

export interface ToolbarContainerProps {
  url?: string
  place: string
  buttons: ShareData
  pdf?: string
  label?: string | null
  styleContext?: StyleContext
}

export const ToolbarContainer: React.FC<ToolbarContainerProps> = ({
  url,
  buttons,
  styleContext,
  label = null,
  place
}) => {
  const actualButtons: {
    type: ToolbarButtonTypes
    label?: string
    url?: string
  }[] = []

  if (buttons.share) {
    actualButtons.push(
      {
        type: 'fb'
      },
      {
        type: 'tg'
      },
      {
        type: 'tw'
      }
    )
  }

  if (false) {
    actualButtons.push({
      type: 'reaction'
    })
  }

  return (
    <>
      <ToolbarContainerButtons
        buttons={actualButtons}
        place={place}
        lang="ru"
        label={label}
        styleContext={styleContext}
        url={url}
      />
    </>
  )
}
