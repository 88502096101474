import { REQUEST_STATUS } from '@client/enums'
import { BookmarkDocument } from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import { fetchAccount } from '@store/Account/accountActions'

export interface AccountState {
  data: Record<string, BookmarkDocument>
  bookmarksRequestStatus: REQUEST_STATUS
}

const initialState: AccountState = {
  data: {},
  bookmarksRequestStatus: REQUEST_STATUS.IDLE
}

export const accountReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAccount.request, (state) => {
      state.bookmarksRequestStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchAccount.failure, (state) => {
      state.bookmarksRequestStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(fetchAccount.success, (state, { payload }) => {
      state.bookmarksRequestStatus = REQUEST_STATUS.SUCCESS
      state.data = payload
    })
})
