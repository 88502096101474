import React from 'react'
import { ImportantLeadProps } from './ImportantLead.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import { RenderBlocks } from '../RenderBlocks'

import styles from './ImportantLead.module.css'

export const ImportantLead: React.FC<ImportantLeadProps> = ({
  block: { only_on: onlyOn, data },
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], !!onlyOn]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="important-lead" className={makeClassName(classNames)}>
      {data.map((item) => (
        <RenderBlocks
          key={item.id}
          block={item}
          styleContext="isInImportantLead"
        />
      ))}
    </div>
  )
}
