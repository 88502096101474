import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REQUEST_STATUS } from '@client/constants'
import { FormItem } from '@common/Form/FormItem'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { deleteUser } from '@store/CurrentUser/currentUserActions'
import { hideModal } from '@store/Modal/modalActions'
import { Button } from '@storybook'

import { IconDeleteAccount } from '../Icons/IconDeleteAccount'

import styles from './Modal.module.css'

export const DeleteUserModal: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { deleteUserStatus, firebase_user } = useSelector(getCurrentUser)

  useEffect(() => {
    if (!firebase_user) {
      dispatch(hideModal())
      history.push('/')
    }
  }, [dispatch, firebase_user, history])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.iconLogin}>
            <IconDeleteAccount />
          </div>
          <h3>
            Вы&nbsp;уверены, что хотите
            <br />
            удалить аккаунт?
          </h3>

          <div className={styles.subtitle}>
            Если у&nbsp;вас есть активная подписка, вы&nbsp;просто можете
            отменить ее&nbsp;по&nbsp;
            <a
              href="https://billing.stripe.com/p/login/aEU6p99jrgQE8SY000"
              target="_blank"
              rel="noreferrer"
            >
              этой ссылке
            </a>
            . Не&nbsp;забывайте, что аккаунт может пригодиться и&nbsp;без
            подписки и&nbsp;это лучший способ оставаться на&nbsp;связи
            с&nbsp;нашей редакцией. А&nbsp;вообще, если что-то пошло
            не&nbsp;так, пожалуйста, напишите на&nbsp;почту
            red@schon.berlin&nbsp;&mdash; может быть, мы&nbsp;вместе разберемся
            и&nbsp;решим вашу проблему. Короче говоря, не&nbsp;спешите уходить!
            Нам вас будет не&nbsp;хватать
          </div>
        </div>
      </div>

      <div className={styles.controls}>
        <FormItem>
          <Button onClick={() => dispatch(hideModal())} theme="black">
            Вернуться в личный кабинет
          </Button>
        </FormItem>
        <FormItem>
          <Button
            onClick={() => dispatch(deleteUser.request())}
            theme="red"
            state={
              deleteUserStatus === REQUEST_STATUS.LOADING
                ? 'isLoading'
                : 'isDefault'
            }
          >
            Удалить аккаунт
          </Button>
        </FormItem>
        {deleteUserStatus === REQUEST_STATUS.FAILURE && (
          <div className={styles.error}>
            Ошибка. Попробуйте еще раз или напишите на&nbsp;
            <a href="mailto:support@schon.berlin">support@schon.berlin</a>
          </div>
        )}
      </div>
    </div>
  )
}
