export const mapDesktopPosition = (position: number): string => {
  const positions = {
    1: 'isLeftTop',
    2: 'isCenterTop',
    3: 'isRightTop',
    4: 'isLeftCenter',
    5: 'isCenterCenter',
    6: 'isRightCenter',
    7: 'isLeftBottom',
    8: 'isCenterBottom',
    9: 'isRightBottom'
  }

  return positions[position] || position[0]
}
