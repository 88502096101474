import React from 'react'
import { RichTitleProps } from './RichTitle.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import styles from './RichTitle.module.css'

export const RichTitle: React.FC<RichTitleProps> = ({
  block: {
    only_on: onlyOn,
    data: { featured, first, second, as: TagName = 'h1' }
  },
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true],
    [styles.featured, !!featured]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <TagName data-testid="rich-title" className={makeClassName(classNames)}>
      {first}
      {second && <span> {second}</span>}
    </TagName>
  )
}
