import React from 'react'
import { CardTitleProps } from './CardTitle.types'
import { makeClassName } from '../utils/makeClassName'
import styles from './CardTitle.module.css'

export const CardTitle: React.FC<CardTitleProps> = ({
  block: {
    data: { only_on: onlyOn, index, text }
  },
  styleContext
}) => {
  return (
    <div
      data-testid="card-title"
      className={makeClassName([
        [styles.root, true],
        [styles[onlyOn], !!onlyOn],
        [styles[styleContext], !!styleContext && !!styles[styleContext]]
      ])}
    >
      <div className={styles.index}>{index}</div>
      <h3 className={styles.title}>{text}</h3>
    </div>
  )
}
