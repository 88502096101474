import React, { useEffect, useRef } from 'react'
import { EmbedBlockProps } from './EmbedBlock.types'
import { EmbedBlock } from './EmbedBlock'

export const IframeBlock: React.FC<EmbedBlockProps> = ({
  block,
  block: { id },
  styleContext,
  lang = 'ru'
}) => {
  const element = useRef(null)

  const renderIframe = (): void => {
    const rootEl = document.createElement('div')
    const contentEl = document.createElement('div')
    const scriptEl = document.createElement('script')
    const style = document.createElement('style')
    const range = document.createRange()

    range.setStart(contentEl, 0)
    contentEl.setAttribute('id', 'content')
    scriptEl.textContent = `
        function getDocHeight() {
            var doc = document;
            var body = doc.body;
            var html = doc.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight,
                html.clientHeight, html.scrollHeight, html.offsetHeight );

            doc.body.style.margin="0";

            return document.getElementById('content') ? document.getElementById('content').offsetHeight : height;
        }

        function sendDocHeightMsg() {
            var height = getDocHeight();
            var message = JSON.stringify({
                action: {
                    type: "UPDATE_HEIGHT",
                    payload: {
                        id: "${block.id}",
                        height: height
                    }
                }
            });
            parent.postMessage("mdzMaterialEmbedHeight=" + message, '*');
        }

        setInterval(sendDocHeightMsg, 300);
        `

    style.type = 'text/css'
    style.innerHTML =
      block.type === 'embed_code'
        ? '.twitter-tweet { padding-top: 15px; margin: 0 !important; }'
        : '.twitter-tweet { margin: 0 !important; } .fb-post { display: block; } .instagram-media { min-width: 0 !important; }'

    contentEl.appendChild(range.createContextualFragment(block.data.html))
    rootEl.appendChild(scriptEl)
    rootEl.appendChild(contentEl)
    contentEl.appendChild(style)

    const frame = element.current.querySelector('iframe').contentDocument

    frame.open()
    frame.close()
    frame.documentElement.setAttribute('lang', lang)
    frame.body.appendChild(rootEl)
  }

  useEffect(() => {
    renderIframe()
  }, [])

  return (
    <EmbedBlock block={block} styleContext={styleContext}>
      <div
        ref={element}
        dangerouslySetInnerHTML={{
          __html: `<iframe id=${id} frameBorder="0" scrolling="no"></iframe>`
        }}
      />
    </EmbedBlock>
  )
}
