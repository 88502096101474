import React, { useEffect, useRef, useState } from 'react'
import { IconAccount } from '@client/components/Common/Icons/IconAccount'
import { Link } from '@common/Link'
import firebase from 'firebase/compat/app'

import styles from './Header.module.css'

interface UserDropdownProps {
  handleAuthClick: () => void
  firebaseUser: firebase.User | null
}

export const UserDropdown: React.FC<UserDropdownProps> = ({
  handleAuthClick,
  firebaseUser
}) => {
  const [isRendered, setIsRendered] = useState(false)
  const profileRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  if (!isRendered) {
    return null
  }

  return firebaseUser ? (
    <div className={styles.item} ref={profileRef}>
      <Link
        className={styles.profileControl}
        to="/account"
        aria-label="Аккаунт"
      >
        <IconAccount />
        Aккаунт
      </Link>
    </div>
  ) : (
    <button
      className={styles.item}
      type="button"
      onClick={handleAuthClick}
      aria-label="Войти"
    >
      <IconAccount />
      Войти
    </button>
  )
}
