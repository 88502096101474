import React, { memo } from 'react'
import { Link } from '@common/Link'
import { makeClassName } from '@utils/makeClassName'

import { Logo } from '../Header/Logo'

import { footerData } from './footerData'

import styles from './Footer.module.css'

export const FooterComponent = () => {
  const data = footerData
  const mod = 'short'

  return (
    <div
      className={makeClassName([
        [[styles.root], true],
        [[styles[mod]], true]
      ])}
    >
      <div className={styles.container}>
        <div className={styles.main}>
          <h2 className={styles.hidden}>{data.heading}</h2>
          <nav className={styles.nav}>
            {data.nav.map((item) => (
              <div
                className={makeClassName([
                  [[styles.group], true],
                  [[styles[item.key]], item.key && styles[item.key]]
                ])}
                key={`footer-${item.key}`}
              >
                <ul className={styles.groupList}>
                  {item.items.map((block) => {
                    if (block.link) {
                      return (
                        <li key={block.link}>
                          {block.type === 'internal' && (
                            <Link to={block.link}>
                              <span
                                dangerouslySetInnerHTML={{ __html: block.name }}
                              />
                            </Link>
                          )}

                          {block.type === 'external' && (
                            <a
                              href={block.link}
                              dangerouslySetInnerHTML={{ __html: block.name }}
                              target="_blank"
                              rel="noopener noreferrer"
                            />
                          )}
                        </li>
                      )
                    }

                    return null
                  })}
                </ul>
              </div>
            ))}
          </nav>
        </div>

        <div className={styles.panel}>
          <Logo />
        </div>
      </div>
    </div>
  )
}

export const Footer = memo(FooterComponent)

Footer.whyDidYouRender = true
