import React from 'react'
import { HalfBlockProps } from './HalfBlock.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import { RenderBlocks } from '../RenderBlocks'
import { Image } from '../Image'
import { MediaCaption } from '../MediaCaption'

import styles from './HalfBlock.module.css'

export const HalfBlock: React.FC<HalfBlockProps> = ({
  block: {
    only_on: onlyOn,
    data,
    data: {
      cover: { optimized, ratio, credit, caption }
    }
  },
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="half-block" className={makeClassName(classNames)}>
      <div className={styles.main}>
        <div className={styles.image}>
          <Image optimized={optimized} ratio={ratio} display="super_full" />
        </div>
        <div className={styles.body}>
          {data.blocks.map((item) => (
            <RenderBlocks key={item.id} block={item} styleContext={['rich']} />
          ))}
        </div>
      </div>
      <div className={styles.footer}>
        <MediaCaption
          credit={credit}
          caption={caption}
          styleContext={styleContext}
        />
      </div>
    </div>
  )
}
