import React from 'react'
import { Link } from 'react-router-dom'

import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { RelatedBlockProps } from './RelatedBlock.types'

import styles from './RelatedBlock.module.css'

export const RelatedBlock: React.FC<RelatedBlockProps> = ({
  block: {
    data: { title, related },
    only_on: onlyOn
  },
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="related-block" className={makeClassName(classNames)}>
      <h3 className={styles.header}>{title}</h3>
      <ul className={styles.items}>
        {related.map((item, index) => (
          <li key={item.id || index} className={styles[item.layout]}>
            <Link to={item.full_url || `/${item.url}`}>
              <span className={styles.first}>{item.title}</span>
              {item.second_title && ' '}
              {item.second_title && <span>{item.second_title}</span>}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}
