import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { useHistory } from 'react-router-dom'
import { IconMembershipPlan } from '@client/components/Common/Icons/IconMembershipPlan'
import { Link } from '@client/components/Common/Link'
import { PLANS_BY_ID } from '@client/constants'
import { formatPaymentTimestamp } from '@client/utils/formatPaymentTimestamp'
import { makeClassName } from '@client/utils/makeClassName'
import { Layout } from '@components/Layout'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { logout } from '@store/CurrentUser/currentUserActions'
import { showModal } from '@store/Modal/modalActions'
import { Button } from '@storybook'

import { IconAvatar } from '../../Common/Icons/IconAvatar'
import { IconSubscription } from '../../Common/Icons/IconSubscription'

import { AccountManagment } from './AccountManagment'

import styles from './Account.module.css'

export const Account: React.FC<RouteConfigComponentProps> = ({
  location: { pathname },
  route
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { firebase_user, account } = useSelector(getCurrentUser)

  useEffect(() => {
    if (!firebase_user) {
      history.push('/feature/2024/06/13/izdanie-schon-zakryvaetsya')
    }
  }, [history, firebase_user])

  const handleDeleteUser = () => {
    dispatch(
      showModal({
        type: 'deleteUser'
      })
    )
  }

  const handleLogoutClick = () => {
    dispatch(logout.request())
    history.push('/')
  }

  const subscription = account?.price_id && PLANS_BY_ID[account.price_id]
  const nextPaymentTimestamp = formatPaymentTimestamp(account?.subscribed_until)

  return (
    <Layout pathname={pathname} routeName={route && route.name} type="account">
      <Helmet titleTemplate="%s">
        <title>Личный кабинет</title>
      </Helmet>
      <div className={styles.root}>
        {firebase_user && (
          <section className={styles.container}>
            <div className={styles.blocks}>
              <div className={styles.block}>
                <div className={styles.profile}>
                  <h2 className={styles.title}>Личный кабинет</h2>
                  <div className={styles.avatar}>
                    <IconAvatar />
                  </div>
                  <div className={styles.user}>
                    <span className={styles.userLabel}>E-mail</span>
                    <input
                      className={styles.userEmail}
                      type="text"
                      value={firebase_user.email}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className={styles.block}>
                {account?.is_access_granted && subscription ? (
                  <div className={styles.details}>
                    <p className={styles.status}>Активна</p>
                    <div className={styles.subscriptionIcon}>
                      <IconMembershipPlan />
                    </div>
                    <h3 className={styles.subscriptionTitle}>
                      Подписка
                      <br />
                      <span
                        dangerouslySetInnerHTML={{ __html: subscription.title }}
                      />
                    </h3>
                    <p>Следующий платеж {nextPaymentTimestamp}</p>
                    <AccountManagment />
                  </div>
                ) : (
                  <div className={styles.promo}>
                    <div className={styles.promoMain}>
                      <div className={styles.promoIcon}>
                        <IconSubscription />
                      </div>
                      <h3 className={styles.subscriptionTitle}>
                        У вас пока нет подписки
                      </h3>
                      <p>
                        Наша редакция живет только благодаря читателям.
                        Подпишитесь,&nbsp;это&nbsp;недорого&nbsp;&mdash; как
                        коктейль в&nbsp;неплохом баре.
                        Кроме&nbsp;того,&nbsp;подписка&nbsp;&mdash; это пропуск
                        в&nbsp;наше сообщество.
                        То&nbsp;есть&nbsp;не&nbsp;только&nbsp;бесплатные статьи,
                        но&nbsp;еще и закрытые встречи для тех, кто
                        в&nbsp;клубе, а&nbsp;также другие приятные мелочи.
                      </p>
                    </div>
                    <div className={styles.promoLink}>
                      <Link to="/membership">подписаться</Link>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={styles.contacts}>
              <h3
                className={makeClassName([
                  [styles.subtitle, true],
                  [styles.isDefault, true]
                ])}
              >
                Написать нам
              </h3>
              <span className={styles.email}>
                <a href="mailto:red@schon.berlin">red@schon.berlin</a>
              </span>
            </div>
            <div className={styles.button}>
              <Button onClick={handleLogoutClick} theme="outline">
                Выйти из аккаунта
              </Button>
            </div>
            <div className={styles.button}>
              <h3
                className={makeClassName([
                  [styles.subtitle, true],
                  [styles.isDanger, true]
                ])}
              >
                Опасная зона
              </h3>
              <Button onClick={handleDeleteUser} theme="red">
                Удалить аккаунт
              </Button>
            </div>
          </section>
        )}
      </div>
    </Layout>
  )
}

Account.whyDidYouRender = true
