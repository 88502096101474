import { MembershipDataIntetface } from './Membership.types'

export const MembershipData: MembershipDataIntetface = {
  hero: {
    images: [
      'https://impro.schon.berlin/MzkOGj1DiRNB8CORIYaClgCt6HAg8GWmBuBszRUcxgI/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDEz/Lzk5NS9zbWFsbC9i/Slhra2M2aVRxYUNE/UkdvM1BwcHdBLmpw/Zw.webp',
      'https://impro.schon.berlin/NRPz1tyXOfQrZP2hljz_S2QuKzYyswdRR9l22X0wWiA/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzA1Ni9zbWFsbC9V/Rm5TYWg1OC1GUUJE/MzBZck1SUnRRLmpw/Zw.webp',
      'https://impro.schon.berlin/W9SNIJ_aKUNNeQvyqJ8kstS9wM9bKzpsDU6tZVrMzQg/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAwNS9zbWFsbC9r/MnpESXVDWEZEY1h4/Y1lEaGhPUGJ3Lmpw/Zw.webp',
      'https://impro.schon.berlin/pbUja5ANRLHX4XOmlAFdRec6_Lv17_OI7IInGUfM26Y/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAxMC9zbWFsbC9l/MmdTRUhRcmxqbXRC/aHh0OVdaWmR3Lmpw/Zw.webp',
      'https://impro.schon.berlin/4WceCoOWe0L7YJbsLCnUL1YK1GEmJkoY1bt9_KOF0KY/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAxNS9zbWFsbC85/bGZuMnFPM0NaS3RF/RVVteE91aGdnLmpw/Zw.webp',
      'https://impro.schon.berlin/iZ6K7NVWFk7l7NGOxvJx6Xc9Vmj9uV_cYgR7wQwyX8U/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAyMC9zbWFsbC9W/d1lnemNpVDgyV0dV/anF6VGZGXzFRLmpw/Zw.webp'
    ]
  },
  membership_plans: {
    monthly: {
      title: 'Подписка<br/>на четыре недели',
      price: '12'
    },
    yearly: {
      title: 'Подписка на год',
      price: '10',
      subtitle: 'в месяц',
      description: 'Мы спишем 120 евро'
    }
  }
}
