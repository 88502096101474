import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { MediaQueryLandscape } from '@client/constants'
import { setIsMobile } from '@store/App/appActions'
import useResizeObserver from 'use-resize-observer/polyfilled'

/*
хук для того, чтобы определять мобила или нет
*/

export const useWatchIsMobile = () => {
  const dispatch = useDispatch()

  const sizeHandler = useCallback(
    (size: { width: number | undefined; height: number | undefined }) => {
      if (!size.width) return

      const isMobile = !window.matchMedia(MediaQueryLandscape).matches

      dispatch(setIsMobile(isMobile))
    },
    [dispatch]
  )

  const onResize = useMemo(() => sizeHandler, [sizeHandler])

  const { ref } = useResizeObserver({ onResize })

  return ref
}
