import React from 'react'
import { Link } from 'react-router-dom'
import { ImageUrls, PromoScreenDocument } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import styles from './BlockPromo.module.css'

interface PromoBlockProps {
  block: PromoScreenDocument
  correlation: keyof ImageUrls
  origin: string
}

export const BlockPromo: React.FC<PromoBlockProps> = ({
  block,
  correlation
}) => {
  const {
    image: { optimised_urls: optimisedUrls, base_urls: baseUrls },
    footer: { action_url }
  } = block

  const imageKey = correlation === 'isMobile' ? 'isMobile' : correlation

  return (
    <article
      className={makeClassName([
        [styles.root, true],
        [styles[correlation], !!correlation && !!styles[correlation]]
      ])}
    >
      <Link className={styles.link} to={`${action_url}`}>
        <picture className={styles.picture}>
          <source
            type="image/webp"
            srcSet={`
                    ${optimisedUrls[imageKey]}`}
          />
          <source
            srcSet={`
                  ${baseUrls[imageKey]}`}
          />
          <img src={baseUrls[imageKey]} alt="" />
        </picture>
      </Link>
    </article>
  )
}
