import React from 'react'

import { CardTitle } from '../CardTitle'
import { ChapterBlock } from '../ChapterBlock'
import { EmbedBlockContainer } from '../EmbedBlock'
import { GroupedBlock } from '../GroupedBlock'
import { ListBlock } from '../ListBlock'
import { MaterialNote } from '../MaterialNote'
import { MetaContainer } from '../Meta/MetaContainer'
import { QuoteBlock } from '../QuoteBlock'
import { RelatedBlock } from '../RelatedBlock'
import { RelatedBook } from '../RelatedBook'
import { RichTitle } from '../RichTitle'
import { SensitiveBlock } from '../SensitiveBlock'
import { SimpleBlock } from '../SimpleBlock'
import { SimpleTitle } from '../SimpleTitle'
import { SourceBlock } from '../SourceBlock'
import { Table } from '../Table'
import { Tag } from '../Tag'

import { RenderBlocksProps } from './RenderBlocks.types'

export const RenderBlocks: React.FC<RenderBlocksProps> = ({
  block,
  styleContext,
  lang,
  isRead,
  isListened,
  bookmarkAction,
  isInBookmarks
}) => {
  switch (block.type) {
    case 'tag': {
      return (
        <Tag
          size="large"
          onlyOn={block.only_on}
          theme={block.data.theme}
          styleContext={styleContext}
        >
          {block.data.text}
        </Tag>
      )
    }

    case 'simple_title': {
      return <SimpleTitle block={block} styleContext={styleContext} />
    }

    case 'rich_title': {
      return <RichTitle block={block} styleContext={styleContext} />
    }

    case 'meta': {
      return (
        <MetaContainer
          block={block}
          lang={block.data.lang}
          styleContext={styleContext}
          isRead={isRead}
          isListened={isListened}
          isInBookmarks={isInBookmarks}
          bookmarkAction={bookmarkAction}
        />
      )
    }

    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'blockquote':
    case 'p':
    case 'context_p':
    case 'divider':
    case 'lead': {
      return <SimpleBlock block={block} styleContext={styleContext} />
    }

    case 'quote':
      return <QuoteBlock block={block} styleContext={styleContext} />

    case 'ol':
    case 'ul': {
      return <ListBlock block={block} styleContext={styleContext} />
    }

    case 'chapter-subtitle': {
      return <ChapterBlock block={block} styleContext={styleContext} />
    }

    case 'card_title': {
      return <CardTitle block={block} styleContext={styleContext} />
    }

    case 'related': {
      return <RelatedBlock block={block} styleContext={styleContext} />
    }
    case 'related_book': {
      return <RelatedBook block={block} styleContext={styleContext} />
    }

    case 'source':
      return <SourceBlock block={block} styleContext={styleContext} />

    case 'table':
      return <Table block={block} styleContext={styleContext} />

    case 'sensitive':
      return <SensitiveBlock block={block} styleContext={styleContext} />

    case 'embed':
    case 'embed_code':
    case 'game_embed':
    case 'image':
    case 'dots_on_image':
    case 'gif':
      return (
        <EmbedBlockContainer
          block={block}
          styleContext={styleContext}
          lang={lang}
        />
      )
    case 'grouped':
      return <GroupedBlock block={block} styleContext={styleContext} />

    case 'material_note':
      return <MaterialNote block={block} styleContext={styleContext} />

    default: {
      return null
    }
  }
}
