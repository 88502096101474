import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from '@utils/createAsyncAction'
import { ExchangeRatesResponse } from 'types/apiTypes'
import {
  CallToActionPayload,
  ChangeScreenViewPayload,
  PageViewPayload
} from 'types/storeTypes'

/*
 Отправляет стату на сервер
 */

export const sendStat = createAsyncAction<string, undefined, undefined>(
  '@APP/SEND_STAT'
)

/*
 Отправляет page view
 */

export const pageView = createAction<PageViewPayload>('@APP/PAGE_VIEW')

/*
 Меняет режим на по-порядку и обратно
 */

export const changeScreenView = createAction<ChangeScreenViewPayload>(
  '@APP/CHANGE_SCREEN_VIEW'
)

/*
Выставляет статусы из queryString
*/

export const setIsEmbedded = createAction('@APP/SET_IS_EMBEDDED')
export const setIsPreview = createAction('@APP/SET_IS_PREVIEW')
export const setIsInApp = createAction('@APP/SET_IS_IN_APP')
export const setIsMobile = createAction<boolean>('@APP/SET_IS_MOBILE')

/*
 Вызывает окно шера для материалов
 */

export const callToAction = createAction<CallToActionPayload>(
  '@APP/CALL_TO_ACTION'
)

/*
 Запрос на курс валют
 */

export const fetchExchangeRates = createAsyncAction<
  undefined,
  { response: ExchangeRatesResponse },
  undefined
>('@APP/FETCH_EXCHANGE_RATES')
