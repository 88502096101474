/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { IconSchonSmall } from '@client/components/Common/Icons/IconSchonSmall'
import { UserDropdown } from '@client/components/Layout/Header'
import { useScrollDirection } from '@client/hooks/useScrollDirection'
import { showModal } from '@client/store/Modal/modalActions'
import { makeClassName } from '@client/utils/makeClassName'

import styles from './Membership.module.css'

export const MembershipNav = ({ firebaseUser }: any) => {
  const dispatch = useDispatch()

  const direction = useScrollDirection()

  const handleAuthClick = () => {
    dispatch(
      showModal({
        type: 'loginHeader',
        coordinates: 0
      })
    )
  }

  return (
    <div
      className={makeClassName([
        [styles.nav, true],
        [styles.isHidden, direction === 'down']
      ])}
    >
      <Link to="/" className={styles.logo}>
        <IconSchonSmall />
      </Link>

      <div className={styles.auth}>
        <UserDropdown
          handleAuthClick={handleAuthClick}
          firebaseUser={firebaseUser}
        />
      </div>
    </div>
  )
}
