import React, { FC } from 'react'

import { makeClassName } from '../utils/makeClassName'

import { ButtonProps } from './Button.types'
import { ButtonLoader } from './ButtonLoader'

import styles from './Button.module.css'

export const Button: FC<ButtonProps> = ({
  theme = 'gold',
  size = 'default',
  state = 'isDefault',
  styleContext = 'isInToolbar',
  appearance,
  disabled = false,
  onClick,
  children
}) => {
  const handleClick = (): void => {
    if (disabled || !onClick) {
      return
    }

    onClick()
  }

  return (
    <button
      data-testid="button"
      className={makeClassName([
        [styles.root, true],
        [styles[size], !!size],
        [styles[theme], !!theme],
        [styles[appearance], !!appearance],
        [styles[state], !!state && !!styles[state]],
        [styles[styleContext], !!styleContext]
      ])}
      disabled={disabled}
      onClick={handleClick}
      type="button"
    >
      {state === 'isLoading' ? (
        <span className={styles.loader}>
          <ButtonLoader />
        </span>
      ) : (
        children
      )}
    </button>
  )
}
