import React from 'react'
import { SourceBlockProps } from './SourceBlock.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import styles from './SourceBlock.module.css'

export const SourceBlock: React.FC<SourceBlockProps> = ({
  block: {
    only_on: onlyOn,
    data: { quote, origin, url }
  },
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <blockquote
      data-testid="source-block"
      cite={url}
      className={makeClassName(classNames)}
    >
      <a
        className={styles.text}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {quote.map((item, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: item.data }} />
        ))}
        <footer>
          <cite className={styles.origin}>{origin}</cite>
        </footer>
      </a>
    </blockquote>
  )
}
