import React, { useState, useEffect } from 'react'
import { PopoverProps } from './Popover.types'
import { makeClassName } from '../utils/makeClassName'
import styles from './Popover.module.css'

export const Popover: React.FC<PopoverProps> = ({ children, onClose }) => {
  const [visible, setVisible] = useState(true)

  const handleClose = (): void => {
    setVisible(false)
    setTimeout(onClose, 350)
  }

  const handleScroll = (): void => {
    handleClose()
  }

  const escFunction = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }

  useEffect(() => {
    document.body.dataset.popover = 'true'

    window.addEventListener('keydown', escFunction, false)
    window.addEventListener('scroll', handleScroll, false)

    return () => {
      document.body.removeAttribute('data-popover')

      window.removeEventListener('keydown', escFunction, false)
      window.removeEventListener('scroll', handleScroll, false)
    }
  }, [visible])

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  return (
    <div
      data-testid="popover"
      className={makeClassName([
        [styles.root, true],
        [styles.visible, visible]
      ])}
    >
      <div className={styles.overlay} onClick={(): void => handleClose()} />

      <div className={styles.container}>
        <button
          type="button"
          className={styles.dismiss}
          onClick={(): void => handleClose()}
          aria-label="dismiss"
        />

        <div className={styles.body}>{children}</div>
      </div>
    </div>
  )
}
