import React from 'react'
import { ListBlockProps } from './ListBlock.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import styles from './ListBlock.module.css'

export const ListBlock: React.FC<ListBlockProps> = ({
  block: { type, data, only_on: onlyOn },
  styleContext
}) => {
  const TagName = type

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true],
    [styles[type], !!type && !!styles[type]]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <TagName className={makeClassName(classNames)} data-testid="list-block">
      {data.map((item, index) => (
        <li key={index} dangerouslySetInnerHTML={{ __html: item }} />
      ))}
    </TagName>
  )
}
