export const formatPaymentTimestamp = (timestamp: number) => {
  if (!timestamp) return null

  const date = new Date(timestamp * 1000)

  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  })
}
