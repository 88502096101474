import React from 'react'

import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { SimpleBlockProps } from './SimpleBlock.types'

import styles from './SimpleBlock.module.css'

export const SimpleBlock: React.FC<SimpleBlockProps> = ({
  block: { data, type, only_on: onlyOn },
  styleContext
}) => {
  let TagName

  let classNames: ClassNames = [
    [styles[type], !!type && !!styles[type]],
    [styles[onlyOn], true]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  switch (type) {
    case 'context_p':
    case 'lead':
    case 'note_caption':
    case 'note_credit': {
      TagName = 'p'
      break
    }
    case 'blockquote':
      return (
        <div className={makeClassName(classNames)}>
          <blockquote dangerouslySetInnerHTML={{ __html: data }} />
        </div>
      )

    case 'divider':
      return (
        <div className={makeClassName(classNames)}>
          <hr />
        </div>
      )

    case 'lead_hr': {
      TagName = 'div'
      break
    }
    default:
      TagName = type
  }

  return (
    <TagName
      className={makeClassName(classNames)}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
