import { localStorageService } from '@client/utils/localStorageService'

interface PaywallBannerStorage {
  [key: number]: boolean
}

export const setPaywallBannerInitialStorage = () => {
  return localStorageService.set('banner', { 1: false })
}

export const setPaywallBannerCounterStorage = (
  counter: number,
  value = true
) => {
  localStorageService.set('banner', { [counter]: value })
}

export const getPaywallBannerStorage = (): PaywallBannerStorage => {
  return localStorageService.get('banner')
}
