import React from 'react'
import { TableProps } from './Table.types'
import makeStyleContext from '../utils/makeStyleContext'
import { makeClassName, ClassNames } from '../utils/makeClassName'

import { MediaCaption } from '../MediaCaption'

import styles from './Table.module.css'

export const Table: React.FC<TableProps> = ({
  styleContext,
  block: {
    rows,
    options,
    display,
    columns_widths: columnsWidth,
    table_credit: tableCredit
  }
}) => {
  const colors = {
    '#ffffff': 'white',
    '#f5f5f5': 'gray',
    '#feeeee': 'pink',
    '#e6f3ff': 'blue',
    '#fffde6': 'yellow',
    '#fff9f2': 'orange',
    '#e7f2ef': 'green',
    '#f4f0f8': 'violet'
  }

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[display], !!display && !!styles[display]]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="table" className={makeClassName(classNames)}>
      <div className={styles.wrapper}>
        <div className={styles.table}>
          <div className={styles.tracks}>
            {rows.map((row, i) => {
              return (
                <div key={row.key} className={styles.row}>
                  {row.cells.map((cell, j) => (
                    <div
                      key={cell.key}
                      className={makeClassName([
                        [styles.cell, true],
                        [styles.header, options.header && i === 0],
                        [styles[colors[cell.color]], true],
                        [styles[columnsWidth[j]], true],
                        [
                          styles.fixedColumn,
                          options.fixed_first_column && j === 0
                        ]
                      ])}
                      dangerouslySetInnerHTML={{ __html: cell.val }}
                    />
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {tableCredit && (
        <MediaCaption credit={tableCredit} styleContext={styleContext} />
      )}
    </div>
  )
}
