import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ViewSwitcherContainer } from '@client/components/Common/ViewSwitcherContainer'
import { makeClassName } from '@client/utils/makeClassName'

import { Logo } from './Logo'

import styles from './Header.module.css'

export const Header = () => {
  const location = useLocation()

  const isAccount = location?.pathname === '/account'
  const isFrontPage = location?.pathname === '/'

  return (
    <header className={makeClassName([[styles.root, true]])} role="banner">
      <div className={styles.logo}>
        <Link to={'/'} aria-label="Schön">
          <Logo />
        </Link>
      </div>

      {isAccount && (
        <Link to={'/'} className={styles.back}>
          на главную
        </Link>
      )}

      {isFrontPage && (
        <div className={styles.switcher}>
          <ViewSwitcherContainer styleContext="isInMenu" theme="dark" />
        </div>
      )}
    </header>
  )
}
