import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUS } from '@client/enums'
import { ALERT_TEXTS, TYPES, useAlert } from '@common/Alert'
import { FormItem, FormMessage, FormTextarea } from '@common/Form'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { sendMessage } from '@store/CurrentUser/currentUserActions'
import { hideModal } from '@store/Modal/modalActions'
import { Button } from '@storybook'

import styles from './Modal.module.css'

export const SendMessageModal = () => {
  const dispatch = useDispatch()
  const { sendMessageStatus, firebase_user } = useSelector(getCurrentUser)

  const [text, setText] = useState('')
  const { show } = useAlert()

  useEffect(() => {
    if (sendMessageStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(hideModal())
      show(ALERT_TEXTS.ON_MESSAGE_SENT, {
        type: TYPES.INFO
      })
    }
  }, [dispatch, sendMessageStatus, show])

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value)
  }

  const handleSubmit = () => {
    dispatch(
      sendMessage.request({
        tag: 'profile',
        subject: 'Пожелание',
        text,
        email:
          firebase_user && firebase_user.email
            ? firebase_user.email
            : 'no email'
      })
    )
  }

  const ready = text.length > 0

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Связаться с «Медузой»</h3>
        <p>
          Какие-то проблемы? Не&nbsp;работает профиль? Какой-то другой вопрос
          или предложение? Смело пишите нам!
        </p>
      </div>

      <div className={styles.form}>
        <FormItem>
          <label className={styles.label} htmlFor="textarea">
            Сообщение
          </label>
          <FormTextarea
            placeholder="Введите сообщение&hellip;"
            styleContext="isInReaction"
            onChange={onTextChange}
            value={text}
            id="textarea"
          />
        </FormItem>
        <div className={styles.submit}>
          <Button
            theme="sand"
            onClick={handleSubmit}
            disabled={!ready}
            state={
              sendMessageStatus === REQUEST_STATUS.LOADING
                ? 'isLoading'
                : 'isDefault'
            }
          >
            Отправить
          </Button>
        </div>
        {sendMessageStatus === REQUEST_STATUS.FAILURE && (
          <FormMessage type="invalid">
            Ошибка. Попробуйте еще раз или напишите на&nbsp;
            <a href="mailto:support@meduza.io">support@meduza.io</a>
          </FormMessage>
        )}
      </div>
    </div>
  )
}
