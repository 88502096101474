import React from 'react'

import { makeClassName } from '../utils/makeClassName'

import { SwitcherProps } from './Switcher.types'

import styles from './Switcher.module.css'

export const Switcher: React.FC<SwitcherProps> = ({
  enabled,
  children,
  childrenPosition = 'right',
  onChange,
  styleContext,
  theme = 'light'
}) => (
  <label
    htmlFor="yourSwitcherInput"
    data-testid="switcher"
    className={makeClassName([
      [styles.root, true],
      [styles[theme], !!styles[theme]],
      [styles[styleContext], !!styles[styleContext]]
    ])}
  >
    {children && childrenPosition === 'left' && (
      <div
        className={makeClassName([
          [styles.children, true],
          [styles.left, true]
        ])}
      >
        {children}
      </div>
    )}
    <input
      id="yourSwitcherInput"
      className={styles.input}
      type="checkbox"
      checked={enabled}
      onChange={onChange}
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="20"
      viewBox="0 0 36 20"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="35"
        height="19"
        rx="9.5"
        fill="#FF3B0E"
        fillOpacity="0.08"
        stroke="url(#paint0_linear_1655_986)"
      />
      <g filter="url(#filter0_b_1655_986)">
        <path
          d="M17.7294 12.0725C16.5859 16.3403 12.1992 18.8729 7.93143 17.7294C3.6637 16.5859 1.13104 12.1992 2.27458 7.93143C3.41811 3.6637 7.80481 1.13104 12.0725 2.27458C16.3403 3.41811 18.8729 7.80481 17.7294 12.0725Z"
          fill="#FF3B0E"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_1655_986"
          x="-232.58"
          y="-232.58"
          width="485.164"
          height="485.164"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="117.29" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1655_986"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_1655_986"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1655_986"
          x1="22.1464"
          y1="20"
          x2="22.1464"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3B0E" stopOpacity="0.72" />
          <stop offset="1" stopColor="#FF3B0E" stopOpacity="0.24" />
        </linearGradient>
      </defs>
    </svg>
    {children && childrenPosition === 'right' && (
      <div
        className={makeClassName([
          [styles.children, true],
          [styles.right, true]
        ])}
      >
        {children}
      </div>
    )}
  </label>
)
