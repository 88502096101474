import { Lang } from '@client/types'
import { getLang } from '@selectors/currentUserSelectors'
import { fetchSearch, search } from '@store/Search/searchActions'
import { handleError } from '@utils/handleError'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import * as api from '../api'

function* searchSaga({ payload }: ReturnType<typeof search>) {
  const lang: Lang = yield select(getLang)
  yield put(
    fetchSearch.request({ query: payload, page: 0, lang, loadPage: true })
  )
}

function* fetchSearchSaga({ payload }: ReturnType<typeof fetchSearch.request>) {
  try {
    const response = yield call(api.fetchSearch, payload)
    yield put(fetchSearch.success({ response, query: payload.query }))
  } catch (err) {
    handleError(err, fetchSearch.failure.type)
    yield put(fetchSearch.failure(err))
  }
}

export default function* appSaga() {
  yield all([
    takeEvery(search.type, searchSaga),
    takeEvery(fetchSearch.request.type, fetchSearchSaga)
  ])
}
