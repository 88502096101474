import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { REQUEST_STATUS } from '@client/enums'
import { FormInput } from '@common/Form/FormInput'
import { FormMessage } from '@common/Form/FormMessage'
import { IconGoogle } from '@common/Modal/assets/google'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import { loginGoogle, sendEmail } from '@store/CurrentUser/currentUserActions'
import { showModal } from '@store/Modal/modalActions'
import { Button } from '@storybook'

interface LoginContainerProps {
  type: 'loginBookmark' | 'loginHeader'
  coordinates: number
}

import { IconLogin } from '../Icons/IconLogin'
import { IconSent } from '../Icons/IconSent'

import styles from './Modal.module.css'

export const LoginModal: React.FC<LoginContainerProps> = ({
  type,
  coordinates
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const { firebase_user, googleLoginStatus, emailSendStatus } = useSelector(
    getCurrentUser
  )

  useEffect(() => {
    if (firebase_user) {
      dispatch(
        showModal({
          type: 'postAuth',
          coordinates
        })
      )
    }
  }, [dispatch, firebase_user, coordinates, type])

  useEffect(() => {
    if (emailSendStatus === REQUEST_STATUS.SUCCESS) {
      setSent(true)
    }
  }, [emailSendStatus])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valid = event.target.value.match(
      /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    )
    setError(!valid)
    setEmail(event.target.value)
  }

  const onSendEmail = () => {
    dispatch(
      sendEmail.request({
        backRoute: history.location.pathname,
        email
      })
    )
  }

  const sentAgain = () => {
    setSent(false)
  }

  const onGoogleAuth = () => {
    dispatch(loginGoogle.request())
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSendEmail()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {sent && (
          <button className={styles.link} type="button" onClick={sentAgain}>
            ой, не тот имейл
          </button>
        )}

        <div className={styles.headerContent}>
          <div className={styles.iconLogin}>
            {sent ? <IconSent /> : <IconLogin />}
          </div>

          <h3>Hallöchen!</h3>

          <div className={styles.subtitle}>
            Здесь вы&nbsp;можете войти в&nbsp;свой личный кабинет или создать
            его, если еще не&nbsp;успели это сделать. Он&nbsp;вам точно
            понадобится для управления подпиской, а&nbsp;читать Sch&ouml;n
            станет еще удобнее. Например, скоро у&nbsp;нас появятся закладки
          </div>
        </div>
      </div>
      {sent ? (
        <div className={styles.message}>
          <p>
            Мы&nbsp;отправили письмо на&nbsp;почту <strong>{email}</strong>
            Там&nbsp;&mdash; ссылка, перейдите по&nbsp;ней
          </p>
        </div>
      ) : (
        <div className={styles.form}>
          <FormInput
            placeholder="Электронная почта"
            size="medium"
            type="email"
            onChange={onChange}
            onKeyPress={onKeyPress}
            invalid={error}
            value={email}
          />

          <div className={styles.submit}>
            <Button
              theme="black"
              disabled={error || email.length === 0}
              onClick={onSendEmail}
              state={
                emailSendStatus === REQUEST_STATUS.LOADING
                  ? 'isLoading'
                  : 'isDefault'
              }
            >
              Войти
            </Button>
          </div>
          {emailSendStatus === REQUEST_STATUS.FAILURE && (
            <FormMessage type="invalid">
              <>
                Произошла ошибка при отправке на ваш email, попробуйте еще раз
              </>
            </FormMessage>
          )}
        </div>
      )}

      <div className={styles.separator}>или</div>

      <div className={styles.auth}>
        <Button
          theme="gray"
          onClick={onGoogleAuth}
          state={
            googleLoginStatus === REQUEST_STATUS.LOADING
              ? 'isLoading'
              : 'isDefault'
          }
        >
          <div className={styles.authIcon}>
            <IconGoogle />
          </div>
          Войти через Google
        </Button>
        <FormMessage type="tip" styleContext="isInLogin">
          <>
            Нажимая на&nbsp;кнопку &laquo;Войти&raquo;, вы&nbsp;соглашаетесь
            с&nbsp;
            <a href="/feature/2023/12/07/obrabotka-dannyh" target="_blank">
              правилами обработки данных
            </a>{' '}
            и&nbsp; принимаете{' '}
            <a
              href="feature/2023/12/07/polzovatelskoe-soglashenie"
              target="_blank"
            >
              пользовательское соглашение
            </a>
          </>
        </FormMessage>
        {googleLoginStatus === REQUEST_STATUS.FAILURE && (
          <FormMessage type="invalid">
            <>Ошибка авторизации. Попробуйте еще раз</>
          </FormMessage>
        )}
      </div>
    </div>
  )
}
