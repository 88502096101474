import React from 'react'
import { EmbedBlock } from '@client/storybook/EmbedBlock/EmbedBlock'
import {
  Block,
  EpisodeMaterialContent,
  RichMaterialContent,
  SimpleMaterialContent
} from '@client/types'
import { useLightbox } from '@common/Lightbox'
import { RenderBlocks } from '@common/RenderBlocks'
import { makeClassName } from '@utils/makeClassName'

import styles from './GeneralMaterial.module.css'

export interface GeneralMaterialProps {
  material: RichMaterialContent | SimpleMaterialContent | EpisodeMaterialContent
}

export const GeneralMaterial: React.FC<GeneralMaterialProps> = ({
  material
}) => {
  const lightBox = useLightbox()

  const {
    content,
    layout,
    url,
    container: { cover }
  } = material

  const modifiers: string[] = [layout, 'center']

  const mobileCover =
    content.head[0] &&
    content.head[0].type === 'image' &&
    content.head[0].only_on == 'mobile'

  if (mobileCover) {
    modifiers.push('hasMobileCover')
  }

  const classes = [
    [styles.root, true],
    [styles.isSimple, !cover]
  ].concat(
    Array.isArray(modifiers)
      ? modifiers.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
      : [[styles[modifiers], !!modifiers && !!styles[modifiers]]]
  )

  return (
    <div className={makeClassName(classes)}>
      <div className={styles.container}>
        {mobileCover && (
          <div className={styles.cover}>
            <EmbedBlock block={content.head[0]} styleContext={['isInCover']} />
          </div>
        )}
        <div className={styles.head}>
          <div className={styles.materialHeader}>
            {content.head.map((block: Block) => {
              if (block.type === 'image' && block.only_on === 'mobile') {
                return null
              }
              return (
                <RenderBlocks
                  url={material.url}
                  key={block.id}
                  block={block}
                  styleContext={modifiers}
                />
              )
            })}
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.article}>
            {content.blocks.map((block) => {
              return (
                <RenderBlocks
                  url={url}
                  key={block.id}
                  block={block as Block}
                  styleContext={modifiers}
                  lightBox={lightBox}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
