import React, { useEffect, useState } from 'react'

import { imageBreakpoints, imageConditionalKeys } from '../constants'
import { toCamel } from '../utils/converCase'

import { PictureProps } from './Image.types'

export const RenderPicture: React.FC<PictureProps> = ({
  source,
  fallbackSource,
  optimized,
  alt,
  display,
  onLoad
}) => {
  const [fallback, setFallback] = useState(false)

  useEffect(() => {
    setFallback(!window.HTMLPictureElement)
  }, [])

  const keys =
    imageConditionalKeys[toCamel(display)] || imageConditionalKeys.default

  return (
    <picture>
      {source && (
        <source media="(min-width: 325px)" srcSet={`${source[0]} 2x`} />
      )}

      {optimized && !optimized.w325 && (
        <source media="(min-width: 0)" srcSet={`${optimized.original}`} />
      )}

      {optimized &&
        keys.map((key) => {
          const breakpoint = optimized[`w${key}`]

          if (!breakpoint) {
            return null
          }

          return (
            <React.Fragment key={key}>
              <source
                type="image/webp"
                media={imageBreakpoints[key]}
                srcSet={`${breakpoint['2x_webp']} 2x, ${breakpoint['1x_webp']} 1x `}
              />

              <source
                type="image/png"
                media={imageBreakpoints[key]}
                srcSet={`${breakpoint['2x']} 2x, ${breakpoint['1x']} 1x `}
              />
            </React.Fragment>
          )
        })}

      <img
        src={
          fallback
            ? fallbackSource
            : 'data:image/gif;base64,R0lGODlhAQABAPAAAPLy8gAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=='
        }
        alt={alt}
        onLoad={() => onLoad(true)}
      />
    </picture>
  )
}

export default RenderPicture
