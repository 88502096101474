import React, { useEffect, useRef, useState } from 'react'
import { Button } from '@storybook'

import { IconDeleteAccount } from '../Icons/IconDeleteAccount'

import styles from './Modal.module.css'

export const WrongBrowserModal = () => {
  const [url, setUrl] = useState('')
  const ref = useRef(null)

  const copyToClipboard = () => {
    ref.current.select()
    ref.current.setSelectionRange(0, 99999)
    navigator.clipboard.writeText(ref.current.value)
  }

  useEffect(() => {
    setUrl(window.location.href)
  }, [])
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.iconLogin}>
            <IconDeleteAccount />
          </div>
          <h3>Ой, лучше открыть ссылку в&nbsp;другом браузере!</h3>
          <div className={styles.subtitle}>
            Пожалуйста, скопируйте эту ссылку, и&nbsp;откройте в&nbsp;вашем
            привычном браузере.
          </div>
        </div>
        <div className={styles.textarea}>
          <textarea defaultValue={url} ref={ref}></textarea>
        </div>
      </div>

      <div className={styles.group}>
        <Button theme="black" onClick={copyToClipboard}>
          Скопировать ссылку
        </Button>
      </div>
    </div>
  )
}
