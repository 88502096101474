import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ScreenViewType } from '@client/constants'
import { changeScreenView } from '@store/App/appActions'
import { Switcher } from '@storybook'
import { localStorageService } from '@utils/localStorageService'

/*
 * Контейнер для кнопки переключения режима "по порядку"
 */

interface ViewSwitcherContainerProps {
  theme?: 'light' | 'dark'
  styleContext: 'isInLive' | 'isInPanel' | 'isInMenu'
}

export const ViewSwitcherContainer: React.FC<ViewSwitcherContainerProps> = ({
  theme,
  styleContext
}) => {
  const dispatch = useDispatch()
  const [type, setType] = useState(
    localStorageService.get('screenViewType') || ScreenViewType.GRID
  )

  const handleChange = () => {
    const newType =
      type === ScreenViewType.LIST ? ScreenViewType.GRID : ScreenViewType.LIST
    localStorageService.set('screenViewType', newType)
    dispatch(changeScreenView({ screenViewType: newType }))
    setType(newType)
  }

  return (
    <Switcher
      enabled={type === ScreenViewType.LIST}
      theme={theme}
      styleContext={styleContext}
      onChange={handleChange}
    >
      <span>По порядку</span>
    </Switcher>
  )
}
