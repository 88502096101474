import React from 'react'
import { ScreenDocument } from '@client/types'
import { Meta, MetaItem, Timestamp } from '@storybook'

interface BlockMetaContainerProps {
  block: ScreenDocument
}

export const BlockMetaContainer: React.FC<BlockMetaContainerProps> = ({
  block: { podcast, live, datetime, datetime_hide }
}) => {
  const isLive = live && live.live_now

  const shouldShowTimestamp = datetime && !isLive && !podcast && !datetime_hide

  return (
    <Meta>
      {shouldShowTimestamp && (
        <MetaItem bullets>
          <Timestamp publishedAt={datetime} type="date" locale="ru" />
        </MetaItem>
      )}
    </Meta>
  )
}
