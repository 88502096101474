import React from 'react'

import { Cover } from '../Cover'
import { HalfBlock } from '../HalfBlock'
import { ImportantLead } from '../ImportantLead'
import { RelatedRichBlock } from '../RelatedRichBlock'
import { RenderBlocks } from '../RenderBlocks'
import { Spoiler } from '../Spoiler'
import { BlockProvider } from '../utils/BlockContext'

import { RawHtmlBlockProps } from './RawHtmlBlock.types'

export const RawHtmlBlock: React.FC<RawHtmlBlockProps> = ({
  block,
  styleContext,
  isRead,
  isListened,
  isInBookmarks,
  bookmarkAction,
  lightBox,
  lang
}) => {
  const context = {
    lightBox: lightBox || null
  }

  switch (block.type) {
    case 'important_lead': {
      return <ImportantLead block={block} styleContext={styleContext} />
    }

    case 'spoiler': {
      return (
        <BlockProvider value={context}>
          <Spoiler block={block} styleContext={styleContext} />
        </BlockProvider>
      )
    }

    case 'cover': {
      return <Cover block={block} styleContext={styleContext} />
    }

    case 'half': {
      return <HalfBlock block={block} styleContext={styleContext} />
    }

    case 'related_rich': {
      return <RelatedRichBlock block={block} styleContext={styleContext} />
    }

    default: {
      return (
        <BlockProvider value={context}>
          <RenderBlocks
            block={block}
            styleContext={styleContext}
            isRead={isRead}
            isListened={isListened}
            isInBookmarks={isInBookmarks}
            bookmarkAction={bookmarkAction}
            lang={lang}
          />
        </BlockProvider>
      )
    }
  }
}
