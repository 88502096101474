import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Lang, MaterialContent, ShareData } from '@client/types'
import { ToolbarContainer } from '@common/ToolbarContainer'
import { SvgSymbol } from '@storybook'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

/*
 * Появляется при скролле материала сверху, содежит кнопки шеров и возможность закрыть материал
 */
import styles from './TopBar.module.css'

interface TopBarProps {
  material: MaterialContent
  lang: Lang
  showContent?: boolean
}

export const TopBar: React.FC<TopBarProps> = ({
  material: { url, layout },
  lang,
  showContent
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef<HTMLDivElement>(null)
  const [visible, setVisible] = useState(false)

  const handleScroll = () => {
    if (ref.current) {
      const elRect = ref.current.getBoundingClientRect()
      const bodyRect = document.body.getBoundingClientRect()
      const elTopOffset = elRect.top - bodyRect.top
      const scrollTop =
        Math.abs(
          window.scrollY ||
            window.pageYOffset ||
            document.body.scrollTop ||
            document.documentElement.scrollTop
        ) + 1
      setVisible(scrollTop > elTopOffset)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.addEventListener('scroll', handleScroll)
  }, [dispatch, lang])

  const handleCloseCLick = () => {
    if (history.action === 'POP') {
      history.push(lang === 'en' ? '/en' : '/')
    } else {
      history.goBack()
    }
  }

  const styleContext = [`isIn${capitalize(layout)}`, 'isInTopbar']

  const buttons: ShareData = { share: true }

  const classNames = [
    [[styles.root], true],
    [[styles.stuck], visible && !!showContent]
  ]

  const context = styleContext
    .filter((ctx) => styles[ctx])
    .map((ctx) => [[styles[ctx]], true])

  classNames.push(...context)

  return (
    <div className={makeClassName(classNames)} ref={ref}>
      <div className={styles.container}>
        {showContent && (
          <>
            <div className={styles.share}>
              <ToolbarContainer
                url={url}
                place="top"
                styleContext={styleContext}
                buttons={buttons}
              />
            </div>
          </>
        )}
        <div
          className={styles.close}
          aria-label={
            lang === 'ru'
              ? 'Вернуться на предыдущую страницу'
              : 'Return to previous page'
          }
        >
          <button
            type="button"
            onClick={handleCloseCLick}
            className={styles.button}
          >
            <SvgSymbol icon="cross" size="medium" styleContext="isInToolbar" />
          </button>
        </div>
      </div>
    </div>
  )
}
