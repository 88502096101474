import loadable from '@loadable/component'

export { MembershipContainer } from './MembershipContainer'

export const LoadableMembershipContainer = loadable(
  () => import('./MembershipContainer'),
  {
    resolveComponent: (components) => components.MembershipContainer
  }
)
