import React from 'react'
import { GroupedBlockProps } from './GroupedBlock.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import { SensitiveBlock } from '../SensitiveBlock'
import { EmbedBlockContainer } from '../EmbedBlock'
import { MediaCaption } from '../MediaCaption'

import styles from './GroupedBlock.module.css'

export const GroupedBlock: React.FC<GroupedBlockProps> = ({
  block,
  block: { only_on: onlyOn },
  styleContext
}) => {
  const maxHeight = Math.min(
    ...block.data.map(
      (item) =>
        (item.data && item.data.height) || (item.item && item.item.data.height)
    )
  )

  const normalizedBoxesWidth = block.data.map((item) => {
    const width =
      (item.data && item.data.width) || (item.item && item.item.data.heigh)
    const height =
      (item.data && item.data.height) || (item.item && item.item.data.heigh)
    return width / (height / maxHeight)
  })

  const sumWidth = normalizedBoxesWidth.reduce((sum, x) => sum + x)

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true],
    [styles.hasFigcaption, block.cc === 'default'],
    [styles[block.display], !!block.display && styles[block.display]]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="grouped-block" className={makeClassName(classNames)}>
      <div className={styles.items}>
        {block.data.map((item, index) => {
          const percentWidth = (normalizedBoxesWidth[index] / sumWidth) * 100

          return (
            <div
              className={styles.item}
              key={item.id}
              style={{ width: `${percentWidth}%` }}
            >
              {item.type === 'sensitive' ? (
                <SensitiveBlock
                  block={item}
                  styleContext={['isInGroupedBlock']}
                />
              ) : (
                <EmbedBlockContainer
                  block={item}
                  styleContext={['isInGroupedBlock']}
                />
              )}
            </div>
          )
        })}
      </div>
      {block.cc && (
        <figcaption className={styles.figcaption}>
          <MediaCaption
            credit={block.credit}
            caption={block.caption}
            styleContext={styleContext}
          />
        </figcaption>
      )}
    </div>
  )
}
