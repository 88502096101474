/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Lang, ScreenDocument } from '@client/types'

import { RichBlockImage } from '../RichBlockImage/RichBlockImage'
import { RichBlockText } from '../RichBlockText/RichBlockText'

import styles from './ChronologyItem.module.css'

export interface ChronologyItemProps {
  document: ScreenDocument
  styleContext?: string
  lang?: Lang
  handleItemClick?: () => void
}

export const ChronologyItem: React.FC<ChronologyItemProps> = ({
  document,
  styleContext
}) => {
  const { layout, image } = document
  const [renderMode, setRenderMode] = useState(null)

  // Определение значения correlation на основе ширины экрана
  useEffect(() => {
    const correlation = window.innerWidth <= 1023 ? 'isMobile' : 'is1to1'
    setRenderMode(correlation)
  }, [window.innerWidth])

  const classNames = [
    [styles.root, true],
    [styles[layout], !!layout && !!styles[layout]]
  ]

  if (styleContext) {
    classNames.push([
      styles[styleContext],
      !!styleContext && !!styles[styleContext]
    ])
  }

  return (
    <>
      {image ? (
        <RichBlockImage
          block={document}
          correlation={renderMode}
          origin="chronologyRichBlockImage"
        />
      ) : (
        <RichBlockText
          block={document}
          correlation={renderMode}
          origin="chronologyRichBlockText"
        />
      )}
    </>
  )
}
