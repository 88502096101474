import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { REQUEST_STATUS } from '@client/enums'
import { TYPES, useAlert } from '@common/Alert'
import { ALERT_TEXTS } from '@common/Alert/constants'
import { FormItem, FormTextarea } from '@common/Form'
import { getCurrentUser } from '@selectors/currentUserSelectors'
import {
  resetStatuses,
  sendTextError
} from '@store/CurrentUser/currentUserActions'
import { hideModal } from '@store/Modal/modalActions'
import { Button } from '@storybook'

import { i18n } from './i18n'

import styles from './Modal.module.css'

interface ExpandedInterface {
  after: string
  before: string
}

interface TextErrorContainerProps {
  text: string
  expanded: ExpandedInterface
}

export const TextErrorModal: React.FC<TextErrorContainerProps> = ({
  text,
  expanded
}) => {
  const dispatch = useDispatch()
  const { lang, sendTextErrorStatus } = useSelector(getCurrentUser)
  const [comment, setComment] = useState('')
  const { show: showAlert } = useAlert()

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  useEffect(() => {
    dispatch(resetStatuses())
  }, [dispatch])

  const handleSendTextError = () => {
    dispatch(
      sendTextError.request({
        text,
        expanded,
        comment,
        pathname: window.location.pathname.slice(1)
      })
    )
  }

  useEffect(() => {
    if (sendTextErrorStatus === REQUEST_STATUS.SUCCESS) {
      showAlert(ALERT_TEXTS.ON_ERROR_SENT, {
        type: TYPES.INFO
      })
      dispatch(hideModal())
    } else if (sendTextErrorStatus === REQUEST_STATUS.FAILURE) {
      showAlert(ALERT_TEXTS.ON_ERROR, {
        type: TYPES.ERROR
      })
      dispatch(hideModal())
    }
  }, [showAlert, dispatch, sendTextErrorStatus])

  const translate = i18n[lang]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>{translate.error.title}</h3>
        <p>
          «...
          <mark>{text}</mark>
          ...»
        </p>
      </div>
      <div className={styles.form}>
        <FormItem>
          <label className={styles.label} htmlFor="textArea">
            {translate.error.label}
          </label>
          <FormTextarea
            placeholder={translate.error.placeholder}
            styleContext="isInReaction"
            value={comment}
            onChange={handleCommentChange}
            id="textArea"
          />
        </FormItem>
        <div className={styles.submit}>
          <Button
            theme="gold"
            onClick={handleSendTextError}
            state={
              sendTextErrorStatus === REQUEST_STATUS.LOADING
                ? 'isLoading'
                : 'isDefault'
            }
          >
            {translate.send}
          </Button>
        </div>
      </div>
    </div>
  )
}
