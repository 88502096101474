import React from 'react'

export const MembershipFeatureOne = () => (
  <svg viewBox="0 0 79 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.2936 107.974C44.485 104.345 57.6273 100.333 70.9259 97.114C74.7989 96.1846 76.8791 100.11 72.5089 101.29C60.2734 104.601 48.1266 108.091 35.8963 111.409C31.1578 112.696 28.9374 114.993 27.9761 118.744C26.5081 124.465 24.2302 130.084 21.9838 135.672C21.0933 137.889 17.8046 141.298 15.098 139.965C12.6859 138.782 14.8452 135.332 15.9496 134.077C20.4948 128.914 20.7769 122.886 23.1697 116.327C16.8534 118.998 11.279 120.78 7.20394 124.309C5.73635 125.575 3.45526 127.996 1.49367 126.126C-2.95842 121.902 19.2766 113.27 21.7852 112.24C23.7339 111.441 24.9617 110.431 25.5311 108.758C30.3167 94.6691 35.1963 80.5998 40.0288 66.5189C40.5853 64.9167 38.2617 66.2237 37.7667 66.6866C30.51 73.495 23.269 80.3152 16.0645 87.1587C14.6905 88.465 13.4628 89.709 13.6613 91.6782C14.017 95.1946 -0.0909443 96.23 9.33027 87.1314C18.9259 77.8756 28.8333 68.9173 38.2891 59.5194C54.3347 43.5615 59.1452 20.5305 72.4096 2.50149C74.2903 -0.0475837 78.9728 0.715048 77.3415 4.28744C70.346 19.6552 62.3318 34.7032 53.9257 49.6849C43.7284 67.8583 37.541 88.1933 31.3041 107.978L31.2936 107.974Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)
export const MembershipFeatureTwo = () => (
  <svg viewBox="0 0 147 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M52.6479 66.7046C83.2971 68.7078 113.805 72.102 142.466 83.3373C143.735 83.8363 146.659 85.5743 145.078 87.3367C144.535 87.9423 143.124 88.6866 140.548 87.4755C128.569 81.3228 115.022 78.7659 101.407 76.3309C85.4154 73.4712 69.3503 71.2381 52.8621 71.4105C48.1915 71.461 44.6494 72.4703 41.3632 75.2291C33.8976 81.4952 26.1341 87.5343 18.3654 93.5566C14.5137 96.5449 2.0628 105.966 1.04654 95.6468C0.696502 92.0679 2.35788 89.0694 4.79245 86.2559C12.4932 77.3529 23.2241 71.7427 35.9873 69.0302C42.0685 67.7349 46.551 65.3293 50.7462 61.8262C66.7778 48.4477 81.8941 34.4126 91.2249 15.4985C93.6515 10.5873 91.3619 4.57645 85.3108 5.37585C80.1706 6.04823 74.8299 9.3952 70.8915 12.5714C61.4413 20.2017 50.8044 27.6487 42.7059 36.753C41.2587 38.3805 40.4333 39.9576 43.2597 41.3244C47.0318 43.1532 42.1755 45.4604 39.9474 44.9579C33.6143 43.5311 35.3862 36.5182 39.0592 33.3676C49.0431 24.7884 59.0112 16.1966 69.2563 7.81924C74.1343 3.83026 80.8331 1.1149 87.1446 0.846524C96.442 0.453522 99.4705 10.1607 96.2873 17.4666C87.4398 37.783 69.0077 52.5772 52.6531 66.6919L52.6479 66.7046ZM33.5684 74.9221C23.642 76.6631 12.0648 83.8714 7.95324 90.6254C1.61727 101.037 30.65 77.2556 33.5684 74.9221Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)

export const MembershipFeatureThree = () => (
  <svg viewBox="0 0 96 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.9739 49.8724C32.2622 49.5317 33.584 49.3046 34.9162 49.149C39.0602 48.677 40.1729 52.5169 35.8043 53.4302C33.0282 54.0099 23.4523 56.9621 24.0808 51.4158C24.3107 49.3929 25.1518 47.2818 26.3534 45.4902C33.7075 34.5295 43.2125 24.9961 52.4493 15.6354C60.319 7.66859 71.3111 2.12466 82.5 0.93723C94.6095 -0.344973 92.1065 11.3465 86.6691 17.6204C79.5012 25.88 71.9467 33.9293 64.2616 41.8861C60.4322 45.8519 55.9914 49.435 52.0418 53.6194C61.7471 49.8305 71.14 46.7724 81.7581 47.2565C91.9771 47.7233 97.7605 54.8559 93.5966 62.3795C87.5031 73.3909 74.9002 81.9466 65.5051 89.854C47.7055 104.838 29.6605 119.637 11.7512 134.537C10.8161 135.315 10.1264 136.316 9.46295 137.279C6.1586 142.069 -3.41915 140.113 3.51758 134.558C21.9533 119.764 40.2545 104.799 58.2797 89.5049C68.2004 81.0831 82.3843 72.0976 88.6961 60.3314C90.9844 56.0797 88.1894 52.3494 82.4582 51.8363C63.385 50.121 47.5915 61.7037 32.2047 71.3372C27.9977 73.9678 22.844 72.1942 27.6543 67.7583C45.0159 51.7331 63.7933 37.392 79.0519 19.1849C80.9114 16.9629 90.927 3.90286 82.0873 5.32355C73.3546 6.72403 64.8957 10.9083 58.3946 16.8803C47.9089 26.5067 36.9204 37.316 29.9896 49.885L29.9739 49.8724Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)

export const MembershipFeatureFour = () => (
  <svg viewBox="0 0 83 132" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.306 49.8571C27.0679 55.2121 20.6943 62.3431 12.7271 65.1189C4.45377 68.0098 3.65347 60.2707 7.14222 55.0173C16.5262 40.8631 24.8893 24.126 37.334 12.3693C39.1664 10.6415 45.9441 9.05528 46.0483 13.3324C46.1089 15.3455 39.994 16.4658 38.6714 18.051C27.7799 31.1283 17.9502 45.9748 9.94249 60.9596C14.315 59.4747 17.488 57.1047 21.0391 54.0542C37.9296 39.5284 55.0656 25.1751 69.1924 8.73584C71.3344 6.24619 74.307 4.18968 77.0708 2.08693C78.2933 1.15331 80.2524 0.0935272 81.5951 1.56124C82.4049 2.4486 82.384 4.22751 81.9033 5.37981C81.2555 6.92743 79.8867 8.30267 78.6903 9.67787C56.7427 34.9024 38.3371 61.7124 23.155 89.9649C17.1992 101.051 12.283 112.405 9.2999 124.273C8.64312 126.909 7.10416 131.451 3.70979 130.994C-2.17744 130.204 2.41 119.832 4.36807 117.393C5.20397 116.354 6.20704 115.307 6.60409 114.146C16.4357 85.7227 33.9486 60.9423 50.8704 36.3953C51.7372 35.1317 51.4988 33.335 49.4988 34.835C47.4988 36.335 40.2424 43.9035 33.306 49.8571Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)

export const MembershipFeatureFive = () => (
  <svg viewBox="0 0 115 119" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M41.7236 45.684C31.2487 49.9342 22.3881 55.6011 14.1022 61.8661C12.0744 63.3957 12.8052 66.7254 9.8705 67.0835C7.34189 67.3973 4.73487 67.83 2.24283 67.6137C0.586699 67.4695 0.555375 65.4461 2.33167 64.2033C15.9153 54.6668 29.3674 45.8383 44.2052 38.3543C47.2981 36.7933 49.4923 34.91 51.1119 32.2801C56.331 23.8051 61.2733 15.2496 64.711 6.16384C65.732 3.46317 68.1678 -0.980016 71.6438 1.51068C74.6843 3.68545 70.1615 7.73813 68.9793 10.0323C65.2315 17.29 61.6095 24.6119 57.8931 31.8857C74.2927 25.2182 91.1184 19.6106 107.817 13.7395C113.874 11.6086 116.38 15.4399 110.064 17.7522C94.0511 23.6143 78.1377 29.663 61.9525 35.1942C53.5151 38.0785 49.1214 43.3935 45.1769 50.6723C47.7421 49.4506 49.4244 48.6574 51.1014 47.8558C70.0835 38.769 80.0921 61.8341 71.7482 76.6358C64.1154 90.1796 53.2539 101.844 39.3622 111.583C32.6836 116.267 6.23031 124.178 3.38701 110.769C2.84929 108.201 6.35318 106.783 7.82774 108.92C14.0876 118.022 29.9685 112.698 37.1209 107.376C49.184 98.4086 58.9484 88.0334 66.1215 76.0675C70.902 68.0915 70.1434 45.0854 55.5317 50.9777C47.8681 54.0658 42.8555 60.1768 35.9141 64.1058C33.6209 65.401 29.33 64.4528 31.3062 61.1959C34.5406 55.8414 37.9913 50.6834 41.7236 45.6713V45.684Z"
      stroke="currentColor"
      strokeLinejoin="round"
    />
  </svg>
)
