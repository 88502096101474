/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'

import { Image } from '../Image'
import { RenderBlocks } from '../RenderBlocks'
import { Tag } from '../Tag'
import { toCapitalize } from '../utils/converCase'
import generateGradient from '../utils/generateGradient'
import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import postMessage from '../utils/postMessage'

import { RelatedRichBlockProps } from './RelatedRichBlock.types'

import styles from './RelatedRichBlock.module.css'

export const RelatedRichBlock: React.FC<RelatedRichBlockProps> = ({
  block: {
    only_on: onlyOn,
    data: {
      blocks,
      url,
      cover: { urls, gradients, tag, layout, ratio }
    }
  },
  styleContext
}) => {
  const style: React.CSSProperties = {}
  const styleHeader: React.CSSProperties = {}

  const cardType = `is${toCapitalize(layout)}`

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    if (inView) {
      if (
        (onlyOn === 'mobile' && window.innerWidth <= 511) ||
        (onlyOn === 'desktop' && window.innerWidth >= 512) ||
        !onlyOn
      ) {
        postMessage('richRelated', url, 'view')
      }
    }
  }, [inView])

  const handleClick = () => {
    postMessage('richRelated', url, 'click')
  }

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[cardType], !!layout && !!styles[cardType]],
    [styles.hasGradient, !!gradients],
    [styles[onlyOn], !!onlyOn && !!styles[onlyOn]]
  ]

  let context = ['isInMediaBlock']

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)

    context = [...styleContext, 'isInMediaBlock']
  }

  if (gradients) {
    styleHeader.backgroundImage = generateGradient(
      gradients.bg_rgb,
      'mediaBlockTop'
    )

    const theme = [gradients.text_rgb === '0,0,0' ? 'dark' : 'light']
    classNames = makeStyleContext(classNames, theme, styles)

    if (onlyOn === 'desktop') {
      style.backgroundImage = generateGradient(
        gradients.bg_rgb,
        'mediaBlockBottom'
      )
    }
  }

  return (
    <Link
      data-testid="related-rich-block"
      className={makeClassName(classNames)}
      to={`/${url}`}
      onClick={(): void => handleClick()}
      ref={ref}
    >
      {layout === 'rich' && (
        <>
          <div className={styles.overlay} style={style} />
          <div className={styles.overlayHeader} style={styleHeader} />
        </>
      )}

      <div className={styles.tag}>
        <Tag size="small" theme="inherit" styleContext="richRelated">
          {tag}
        </Tag>
      </div>
      <div className={styles.cover}>
        <div className={styles.picture}>
          <Image optimized={urls} ratio={ratio} display="narrow" />
        </div>
      </div>
      <div className={styles.body}>
        {blocks.map((item) => (
          <RenderBlocks key={item.id} block={item} styleContext={context} />
        ))}
      </div>
    </Link>
  )
}
