import { PaginationElement } from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import { fetchChrono } from '@store/Screens/screensActions'

export type ScreenChronologyState = Record<string, PaginationElement>

export const initialStateElement: PaginationElement = {
  is_fetching: true,
  has_next: false,
  ids: [],
  page: 0,
  requested: false,
  preserveState: false
}

const initialState: ScreenChronologyState = {}

export const createChronologyReducer = (state = initialState) => {
  return createReducer(state, (builder) => {
    builder
      .addCase(
        fetchChrono.request,
        (state, { payload: { screenName, loadPage } }) => {
          if (!loadPage) {
            state[screenName].is_fetching = true
            return
          }

          // Добавляем новый экран в хранилище с изначальными параметрами
          if (!state[screenName]) {
            state[screenName] = { ...initialStateElement, page: 0 }
            return
          }

          // Если экран уже был в хранилище, то указываем на это через ключ preserveState
          state[screenName].preserveState = true
        }
      )
      .addCase(
        fetchChrono.success,
        (
          state,
          {
            payload: {
              screenName,
              response: {
                data: { collection, has_next }
              }
            }
          }
        ) => {
          state[screenName].requested = true
          state[screenName].has_next =
            has_next || collection.length < state[screenName].ids.length

          // Номер страницы используется для загрузки следующей части хронологии
          // Если данные экрана были в хранилище, то не увеличиваем его значение
          if (!state[screenName].preserveState) {
            state[screenName].page += 1
          }

          state[screenName].is_fetching = false
          state[screenName].preserveState = false
          state[screenName].ids = Array.from(
            new Set([...state[screenName].ids, ...collection])
          )
        }
      )
  })
}

export const screensChronologyReducer = createChronologyReducer()
