export type ClassNames = (string[] | string | boolean)[][]

export const makeClassName = (list: ClassNames): string => {
  return list
    .filter(([, active]) => active)
    .map(([className]) => className)
    .join(' ')
}

export default makeClassName
