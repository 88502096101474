import React from 'react'
import { useSelector } from 'react-redux'
import { RichBlockImage } from '@client/components/Routes/Screen/RichBlockImage'
import {
  DesktopBlock,
  ImageUrls,
  PromoScreenDocument,
  RichScreenDocument,
  ScreenDocumentWithMobile
} from '@client/types'
import { getScreenDocuments } from '@selectors/screenDocumentsSelectors'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import { BlockPromo } from '../BlockPromo'
import { RichBlockText } from '../RichBlockText'

import styles from './Section.module.css'

interface RenderBlockProps {
  desktopBlock: DesktopBlock
  correlation: keyof ImageUrls
  origin?: string
  screenDocuments: Record<string, ScreenDocumentWithMobile>
  currentUrl?: string
}

interface RenderRichBlockProps {
  variant: 'image' | 'text'
  origin?: string
  block: RichScreenDocument
  correlation: keyof ImageUrls
}

const RenderRichBlock: React.FC<RenderRichBlockProps> = ({
  variant,
  origin,
  correlation,
  block
}) => {
  switch (variant) {
    case 'image': {
      return (
        <RichBlockImage
          block={block as RichScreenDocument}
          correlation={correlation}
          origin={origin}
        />
      )
    }
    case 'text': {
      return (
        <RichBlockText
          block={block as RichScreenDocument}
          correlation={correlation}
          origin={origin}
        />
      )
    }

    default: {
      return null
    }
  }
}

const RenderBlock: React.FC<RenderBlockProps> = ({
  desktopBlock,
  correlation,
  origin,
  screenDocuments,
  currentUrl
}) => {
  const blockClasses = [
    [styles.block, true],
    [styles[correlation], !!styles[correlation]],
    [styles.currentMaterial, currentUrl === desktopBlock.collection[0].key]
  ]
  const blockKey = `${desktopBlock.type}-${desktopBlock.published_at}`
  const blockType = `is${capitalize(desktopBlock.type)}`

  const blockVariant = desktopBlock.collection[0].type

  const block = screenDocuments[desktopBlock.collection[0].key]
  const url = desktopBlock.collection[0].key
  const calculatedOrigin =
    origin ||
    (desktopBlock.collection[0].origin && desktopBlock.collection[0].origin[0])

  if (desktopBlock.type === 'rich') {
    return (
      <div
        key={`${blockKey}-${url}`}
        className={makeClassName(
          blockClasses.concat([[styles[blockType], !!styles[blockType]]])
        )}
      >
        <RenderRichBlock
          variant={blockVariant as 'image' | 'text'}
          origin={calculatedOrigin!}
          block={block as RichScreenDocument}
          correlation={correlation}
        />
      </div>
    )
  }

  if (desktopBlock.type === 'promo') {
    return (
      <div
        key={`${blockKey}-${url}`}
        className={makeClassName(
          blockClasses.concat([[styles[blockType], !!styles[blockType]]])
        )}
      >
        <BlockPromo
          origin={calculatedOrigin!}
          block={block as PromoScreenDocument}
          correlation={correlation}
        />
      </div>
    )
  }
  return null
}

interface SectionProps {
  blocks: DesktopBlock[]
  currentUrl?: string
}

export const Section: React.FC<SectionProps> = ({ blocks, currentUrl }) => {
  const screenDocuments = useSelector(getScreenDocuments).byId

  const correlation = `is1to${blocks.length}` as keyof ImageUrls

  return (
    <div className={styles.root}>
      {blocks.map((block) => (
        <RenderBlock
          key={block.collection[0].key}
          desktopBlock={block}
          correlation={correlation}
          screenDocuments={screenDocuments}
          currentUrl={currentUrl}
        />
      ))}
    </div>
  )
}
