const generateGradient = (color: string, type: string): string => {
  const steps = {
    mediaBlockBottom: [
      [0, 7],
      [0.08, 12],
      [0.16, 17],
      [0.22, 21],
      [0.32, 26],
      [0.42, 32],
      [0.52, 38],
      [0.62, 47],
      [0.72, 57],
      [0.82, 65],
      [1, 82]
    ],
    mediaBlockTop: [
      [0.5, 0],
      [0, 97]
    ]
  }

  const gradient = steps[type].map((i) => `rgba(${color}, ${i[0]}) ${i[1]}%`)

  return `linear-gradient(-180deg, ${gradient})`
}

export default generateGradient
