import React from 'react'
import { ChapterBlockProps } from './ChapterBlock.types'
import { makeClassName } from '../utils/makeClassName'
import styles from './ChapterBlock.module.css'

export const ChapterBlock: React.FC<ChapterBlockProps> = ({
  block: { data, only_on: onlyOn },
  styleContext
}) => {
  return (
    <div
      data-testid="chapter-block"
      className={makeClassName([
        [styles.root, true],
        [styles[onlyOn], !!onlyOn]
      ])}
    >
      <div
        className={makeClassName([
          [styles.subtitle, true],
          [styles[styleContext], !!styleContext && !!styles[styleContext]]
        ])}
        dangerouslySetInnerHTML={{ __html: data }}
      />
    </div>
  )
}
