import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { MaterialContent, RootState } from '@client/types'
import { Skeleton } from '@common/Skeleton'
import { Layout } from '@components/Layout/Layout'
import { Meta } from '@components/Layout/Meta'
import { useBridge } from '@hooks/useBridge'
import { usePageViewAnalytics } from '@hooks/usePageViewAnalytics'
import { getApp } from '@selectors/appSelectors'
import { getLang } from '@selectors/currentUserSelectors'
import { getMaterial } from '@selectors/materialsSelectors'
import { hideFootnote, showFootnote } from '@store/Footnote/footnoteActions'
import { fetchMaterial } from '@store/Materials/materialsActions'
import { fetchUnderTheSun } from '@store/Screens/screensActions'
import equal from 'fast-deep-equal/es6'

import { TopBar } from './MaterialComponents/TopBar'
import { UnderTheSun } from './MaterialComponents/UnderTheSun'
import { renderMaterialComponents } from './renderMaterialComponents'

import styles from './Material.module.css'

interface FootnoteElement extends HTMLElement {
  dataset: {
    id: string
    highlight: string
    body: string
    title: string
  }
}

interface MaterialParams {
  lang?: string
  material?: string
  year?: string
  month?: string
  day?: string
  slug?: string
}

export const Material: React.FC<RouteConfigComponentProps<MaterialParams>> = ({
  location: { pathname },
  route,
  match: {
    params: { lang: paramsLang, material: materialName, year, month, day, slug }
  }
}) => {
  useBridge()
  const dispatch = useDispatch()

  const containerRoot = useRef<HTMLDivElement>(null)

  const { isEmbedded, isInApp } = useSelector(getApp)

  const url = [paramsLang, materialName, year, month, day, slug]
    .filter((part) => !!part)
    .join('/')

  const lang = useSelector(getLang)
  const material: MaterialContent = useSelector(
    (state: RootState) => getMaterial(state, url),
    equal
  )

  const hideUnderTheSun = !material

  usePageViewAnalytics({
    pathname,
    title: material && material.og.title,
    tag: material && material.tag && material.tag.name
  })

  const [showUnderTheSun, setShowUnderTheSun] = useState(true)

  useEffect(() => {
    dispatch(fetchMaterial.request(url))
    dispatch(fetchUnderTheSun.request())
    dispatch(hideFootnote())
    setShowUnderTheSun(!(isEmbedded || isInApp || hideUnderTheSun))
    window.scrollTo({ top: 0 })
  }, [dispatch, url, isEmbedded, isInApp, hideUnderTheSun])

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const target = event.target as HTMLElement

      const activeFootnote: HTMLElement | null = window.document.querySelector(
        '[data-highlight="true"]'
      )

      if (activeFootnote) {
        activeFootnote.dataset.highlight = 'false'
        dispatch(hideFootnote())
      }

      if (target.classList && target.classList.contains('FootnoteLink')) {
        const footnoteLink = event.target as FootnoteElement

        if (activeFootnote?.dataset?.id === footnoteLink.dataset.id) {
          return
        }

        const targetRect = footnoteLink.getBoundingClientRect()
        const rect = {
          top:
            targetRect.top +
            (document.body.scrollTop || document.documentElement.scrollTop) -
            8,
          left: targetRect.right
        }

        dispatch(
          showFootnote({
            id: footnoteLink.dataset.id,
            title: footnoteLink.dataset.title,
            body: footnoteLink.dataset.body,
            originRect: rect,
            containerRect: containerRoot.current.getBoundingClientRect()
          })
        )
      }
    },
    [dispatch]
  )

  useEffect(() => {
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  return (
    <Layout
      pathname={pathname}
      routeName={route && route.name}
      type="material"
      materialName={materialName}
    >
      {!material ? (
        <Skeleton styleContext="isInMaterial" />
      ) : (
        <div className={styles.root} ref={containerRoot}>
          <Meta material={material} lang={lang} />
          <div className={styles.container}>
            <TopBar
              material={material}
              lang={lang}
              showContent={materialName !== 'games' && materialName !== 'quiz'}
            />
            {renderMaterialComponents(material)}
          </div>

          {showUnderTheSun && <UnderTheSun material={material} lang={lang} />}
        </div>
      )}
    </Layout>
  )
}

Material.whyDidYouRender = true
