export const MediaQuerySizes = {
  MOBILE: 512,
  PORTRAIT_TABLET: 768,
  LANDSCAPE_TABLET: 1024,
  DESKTOP: 1010,
  WIDE_DESKTOP: 1200
}

export const imageBreakpoints = {
  325: '(min-width: 0)',
  520: '(min-width: 325px)',
  600: '(min-width: 600px)',
  650: '(min-width: 650px)',
  980: '(min-width: 1000px)',
  1335: '(min-width: 1000px)',

  960: '(min-width: 325px)',
  1280: '(min-width: 600px)',
  1908: '(min-width: 650px)',
  3840: '(min-width: 1000px)'
}

export const imageConditionalKeys = {
  xs: [325],
  superFull: [3840, 1908, 1280, 960],
  full: [980, 650, 520, 325],
  default: [650, 520, 325],
  narrow: [600, 325]
}

export const SocialLabels = {
  ru: {
    tg: 'Телеграм',
    fb: 'Фейсбук',
    tw: 'Твиттер',
    pdf: 'PDF',
    unblock: 'Magic link',
    bookmark: 'В закладки',
    reaction: 'Напишите нам'
  },
  en: {
    tg: 'Telegram',
    fb: 'Fb',
    tw: 'Twitter',
    pdf: 'PDF',
    unblock: 'Magic link',
    bookmark: 'Add to bookmarks'
  }
}
