import React from 'react'
import { EmbedBlockProps } from './EmbedBlock.types'
import { EmbedBlock } from './EmbedBlock'
import { IframeBlock } from './IframeBlock'

export const EmbedBlockContainer: React.FC<EmbedBlockProps> = ({
  block,
  styleContext,
  lang
}) => {
  if (block.data && block.data.wrap_with_iframe) {
    return <IframeBlock block={block} styleContext={styleContext} lang={lang} />
  }
  return <EmbedBlock block={block} styleContext={styleContext} />
}
