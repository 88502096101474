import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { Lang, PaginationElement, RootState } from '@client/types'
import { Skeleton } from '@common/Skeleton'
import { Layout } from '@components/Layout'
import { Meta } from '@components/Layout/Meta'
import { usePageViewAnalytics } from '@hooks/usePageViewAnalytics'
import { LoadableComponent } from '@loadable/component'
import { Chronology } from '@routes/Screen/Chronology'
import { Feed } from '@routes/Screen/Feed'
import { Grid } from '@routes/Screen/Grid'
import { CareData } from '@routes/Specials/Care/Care'
import { specialComponents } from '@routes/Specials/specialComponents'
import { getIsMobile } from '@selectors/appSelectors'
import { getScreenChronology } from '@selectors/screenChronologySelectors'
import { getSpecial } from '@selectors/screensSelectors'
import { fetchChrono, fetchSpecials } from '@store/Screens/screensActions'
import { capitalize } from '@utils/capitalize'
import equal from 'fast-deep-equal/es6'

interface SpecialParams {
  lang: Lang
  slug: string
}

export const Special: React.FC<RouteConfigComponentProps<SpecialParams>> = ({
  location: { pathname },
  route,
  match: {
    params: { lang = 'ru', slug }
  }
}) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)
  const isNative = slug === 'native'

  const specialData = useSelector(
    (state: RootState) => getSpecial(state, slug),
    equal
  )

  const chronologyData: PaginationElement = useSelector(
    (state: RootState) => getScreenChronology(state, 'partner'),
    equal
  )

  usePageViewAnalytics({ pathname, title: specialData && specialData.og.title })

  useEffect(() => {
    if (isNative) {
      dispatch(
        fetchChrono.request({
          screenName: 'partner',
          page: 0,
          lang,
          loadPage: true
        })
      )
    } else {
      dispatch(fetchSpecials.request({ slug }))
    }
  }, [dispatch, isNative, lang, slug])

  const handleFetchNextChrono = () => {
    if (chronologyData) {
      dispatch(
        fetchChrono.request({
          screenName: 'partner',
          page: chronologyData.page,
          lang,
          loadPage: false
        })
      )
    }
  }

  const isCare = slug === 'care'
  const screenSlug = `isIn${capitalize(slug)}`

  // @ts-ignore
  const Component:
    | LoadableComponent<{
        data?: CareData
        children?: React.ReactNode
      }>
    | string = specialComponents[slug] || 'div'

  return (
    <Layout pathname={pathname} routeName={route && route.name} type="specials">
      {specialData && (
        <Meta screen={`/${slug}`} lang={lang} og={specialData.og} />
      )}
      <Component data={specialData && specialData.extra_json}>
        {isNative ? (
          chronologyData ? (
            <Chronology
              lang={lang}
              screenName="partner"
              fetchNext={handleFetchNextChrono}
              styleContext="isInChronology"
            />
          ) : (
            <div />
          )
        ) : (
          <>
            {!specialData ? (
              <Skeleton styleContext="isInFeed" />
            ) : (
              <>
                {isMobile ? (
                  <Feed key="feed" blocks={[specialData.mobile]} />
                ) : (
                  <Grid
                    containers={specialData.desktop}
                    styleContext={
                      isCare ? undefined : ['isInSpecial', screenSlug]
                    }
                  />
                )}
              </>
            )}
          </>
        )}
      </Component>
    </Layout>
  )
}
