import React, { useCallback, useEffect, useRef, useState } from 'react'

import styles from './Error.module.css'

/*
 * Компонент страницы ошибки для Bugsnag ErrorBoundary
 */

export const ErrorComponent = () => {
  const ref = useRef<HTMLIFrameElement | null>(null)
  const [isLoaded, setIsLoaded] = useState(false)

  const handleMessage = useCallback(
    (e: MessageEvent) => {
      const iframe = ref.current

      if (
        typeof e.data === 'string' &&
        e.data.indexOf('duckling') > -1 &&
        !isLoaded
      ) {
        setIsLoaded(true)

        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage('start', '*')
        }
      }
    },
    [ref, isLoaded]
  )

  useEffect(() => {
    window.addEventListener('message', handleMessage)

    return () => window.removeEventListener('message', handleMessage)
  }, [handleMessage])

  return (
    <div className={styles.root}>
      <div className={styles.layout}>
        <iframe
          id="dinosaur"
          src="/embed/dinosaur-404-game/"
          width="100%"
          title="dinosaur"
          ref={ref}
        />
        <div>
          <h1>Ой! Произошла ошибка</h1>
          <p>
            Мы уже разбираемся, а вы можете перейти на{' '}
            <a href="/">главную страницу</a>
          </p>
        </div>
      </div>
    </div>
  )
}
