import React from 'react'
import { ImageUrls, ScreenDocument } from '@client/types'
import { makeClassName } from '@utils/makeClassName'

import styles from './BlockTitle.module.css'

interface BlockTitleProps {
  block: ScreenDocument
  styleContext: string
  featured?: boolean
  correlation?: keyof ImageUrls
}

export const BlockTitle: React.FC<BlockTitleProps> = ({
  block,
  styleContext,
  correlation,
  featured = false
}) => {
  const className = [
    [styles.root, true],
    [styles.isFeatured, featured],
    [
      !!correlation && styles[correlation],
      !!correlation && !!styles[correlation]
    ]
  ]

  if (styles[styleContext]) {
    className.push([styles[styleContext], true])
  }

  return (
    <div className={makeClassName(className)}>
      <h2 className={styles.first}>{block.title}</h2>
      {block.second_title && (
        <p className={styles.second}>{block.second_title}</p>
      )}
    </div>
  )
}
