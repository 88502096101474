/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext } from 'react'

import { PaywallAccess } from './PaywallProvider'

export interface PaywallContext {
  getCounter: () => number
  isAccessGranted: (url: string, account: any) => PaywallAccess
}

const PaywallContext = createContext<PaywallContext | null>(null)

export default PaywallContext
