import { REQUEST_STATUS } from '@client/enums'
import { MaterialContent, StatItem } from '@client/types'
import { createReducer } from '@reduxjs/toolkit'
import { fetchMaterial } from '@store/Materials/materialsActions'

interface TopBarAdsItem {
  title: string | null
  source: string | null
  url: string | null
}

export interface MaterialsState {
  requestMaterialStatus: REQUEST_STATUS
  requestStatStatus: REQUEST_STATUS
  requestTopbarAdsStatus: REQUEST_STATUS
  requestUnblockLinkStatus: REQUEST_STATUS
  loadMoreLiveStatus: REQUEST_STATUS
  byId: Record<string, MaterialContent>
  allIds: string[]
  currentMaterial: string | null
}

const initialState: MaterialsState = {
  requestMaterialStatus: REQUEST_STATUS.IDLE,
  requestStatStatus: REQUEST_STATUS.IDLE,
  requestTopbarAdsStatus: REQUEST_STATUS.IDLE,
  requestUnblockLinkStatus: REQUEST_STATUS.IDLE,
  loadMoreLiveStatus: REQUEST_STATUS.IDLE,

  byId: {},
  allIds: [],
  currentMaterial: null
}

export const materialsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMaterial.request, (state) => {
      state.requestMaterialStatus = REQUEST_STATUS.LOADING
    })
    .addCase(fetchMaterial.failure, (state) => {
      state.requestMaterialStatus = REQUEST_STATUS.FAILURE
    })
    .addCase(
      fetchMaterial.success,
      (
        state,
        {
          payload: {
            response: {
              data: { root }
            }
          }
        }
      ) => {
        state.byId[root.url] = root
        state.currentMaterial = root.url
        if (state.allIds.indexOf(root.url) === -1) state.allIds.push(root.url)
        state.requestMaterialStatus = REQUEST_STATUS.SUCCESS
      }
    )
})
