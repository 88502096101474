import React, { useState } from 'react'

import styles from './MembershipFaq.module.css'

const MembershipFaqItem = ({
  title,
  children,
  setActiveItem,
  activeItem,
  item
}) => {
  const isExpanded = activeItem === item

  const handleClick = () => {
    return setActiveItem(isExpanded ? 0 : item)
  }

  return (
    <div className={styles.item}>
      <button
        className={styles.control}
        onClick={handleClick}
        aria-expanded={isExpanded ? 'true' : 'false'}
      >
        <span className={styles.itemTitle}>{title}</span>
      </button>

      {activeItem === item && <div className={styles.body}>{children}</div>}
    </div>
  )
}

export const MembershipFaq = () => {
  const [activeItem, setActiveItem] = useState(0)

  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Вопросы и ответы</h2>

      <div className={styles.items}>
        <MembershipFaqItem
          title="Как я&nbsp;могу отменить подписку?"
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          item={1}
        >
          Вы&nbsp;можете сделать это самостоятельно в&nbsp;своем личном
          кабинете. Но, пожалуйста, напишите сначала нам на&nbsp;
          <a href="mailto:support@schon.berlin">support@schon.berlin</a>,
          расскажите, почему вы&nbsp;хотите это сделать&nbsp;&mdash; возможно,
          мы&nbsp;найдем лучшее решение!
        </MembershipFaqItem>
        <MembershipFaqItem
          title="А&nbsp;внести в&nbsp;нее изменения?"
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          item={2}
        >
          Если вы&nbsp;хотите внести какие-то изменения, к&nbsp;примеру
          переоформить месячную подписку на&nbsp;годовую, напишите нам на&nbsp;
          <a href="mailto:support@schon.berlin">support@schon.berlin</a>,
          и&nbsp;мы&nbsp;объясним, как это можно сделать.
        </MembershipFaqItem>
        <MembershipFaqItem
          title="У&nbsp;меня есть промокод со&nbsp;скидкой&nbsp;&mdash; как его использовать?"
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          item={3}
        >
          Тут все просто. Когда вы&nbsp;перейдете на&nbsp;страницу оплаты
          в&nbsp;сервисе Stripe, вы&nbsp;увидите специальное поле Promo-Code,
          где сможете активировать вашу скидку.
        </MembershipFaqItem>
        <MembershipFaqItem
          title="Какие есть способы оплаты?"
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          item={4}
        >
          В&nbsp;данный момент можно использовать международные карты (Visa,
          Mastercard, American Express, Discover, Diners Club, China UnionPay),
          а&nbsp;также Apple Pay и&nbsp;Google Pay. Вскоре мы&nbsp;планируем
          подключить PayPal, giropay, iDEAL, EPS, Bancontact, BLIK
          и&nbsp;Lastschrift.
        </MembershipFaqItem>
        <MembershipFaqItem
          title="Могу&nbsp;ли я&nbsp;купить подписку в&nbsp;подарок?"
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          item={5}
        >
          Мы&nbsp;планируем добавить эту возможность совсем скоро. Если
          вы&nbsp;хотите сделать это прямо сейчас, напишите нам на&nbsp;
          <a href="mailto:support@schon.berlin">support@schon.berlin</a>,
          и&nbsp;мы&nbsp;обязательно что-нибудь придумаем.
        </MembershipFaqItem>
        <MembershipFaqItem
          title="А&nbsp;если мой имейл или данные карты утекут?"
          setActiveItem={setActiveItem}
          activeItem={activeItem}
          item={6}
        >
          Мы&nbsp;не&nbsp;храним никакие ваши личные данные. К&nbsp;ним имеет
          доступ только платежная система Stripe, которая не&nbsp;раз доказала
          свою надежность.
        </MembershipFaqItem>
      </div>
    </div>
  )
}
