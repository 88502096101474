import { Lang, LiveItem } from '@client/types'
import { createAction } from '@reduxjs/toolkit'
import { createAsyncAction } from '@utils/createAsyncAction'
import { MaterialResponse } from 'types/apiTypes'

/*
 Запрос данных материала
 */

export const fetchMaterial = createAsyncAction<
  string,
  { response: MaterialResponse },
  undefined
>('@MATERIAL/FETCH')

/*
 Запрос на соединение сокетов в liveMaterial
 */

export const connectLive = createAsyncAction<string, string, string>(
  '@MATERIAL/CONNECT_LIVE'
)

/*
 Запрос на отключение сокетов в liveMaterial (при уходе со страницы)
 */

export const disconnectLive = createAsyncAction<string, string, string>(
  '@MATERIAL/DISCONNECT_LIVE'
)

/*
 Live: загрузка уже опубликованных сообщений в лайве при инициализации материала
 */

export const liveInitialItems = createAction<{
  url: string
  items_ids: string[]
  items: Record<string, LiveItem>
}>('@MATERIAL/LIVE_INITIAL_ITEMS')

/*
 Live: загрука новых сообщений из сокета
 */

export const liveNewItems = createAction<{
  url: string
  items_ids: string[]
  items: Record<string, LiveItem>
}>('@MATERIAL/LIVE_NEW_ITEM')

/*
 Live: обновление сообщения из сокета
 */

export const liveUpdateItems = createAction<{
  url: string
  items: Record<string, LiveItem>
}>('@MATERIAL/LIVE_UPDATE_ITEM')

/*
 Live: перевернуть трансляцию
 */

export const reverseLive = createAction<string>('@MATERIAL/REVERSE_LIVE')

/*
 Live: показывает скрытые новые сообщения
 */

export const showPendingLive = createAction<string>(
  '@MATERIAL/SHOW_PENDING_LIVE'
)

/*
 Статус запуска эмбеда (включается, например, когда нажали кнопку "начать игру"
 */

export const embedStarted = createAction<string>('@MATERIAL/EMBED_STARTED')

export const embedLoaded = createAction<string>('@MATERIAL/EMBED_LOADED')

export const embedUnload = createAction<string>('@MATERIAL/EMBED_UNLOAD')

export const topbarAdBecomeVisible = createAction<string>(
  '@MATERIAL/TOPBAR_AD_BECOME_VISIBLE'
)

export const topbarAdClicked = createAction<string>(
  '@MATERIAL/TOBBAR_AD_CLICKED'
)

export const informerBecomeVisible = createAction<string>(
  '@MATERIAL/INFORMER_BECOME_VISIBLE'
)

export const informerClicked = createAction<string>(
  '@MATERIAL/INFORMER_CLICKED'
)
