type NavItem = {
  title?: string
  key: string
  items: {
    link?: string
    name: string
    type: 'internal' | 'external' | 'notifications' | 'switcher'
  }[]
}

type footerItem = {
  tip: string
  heading: string
  howto?: {
    label: string
    url: string
  }
  nav: NavItem[]
}

export const footerData: footerItem = {
  tip: 'Нашли ошибку? Выделите ее и нажмите Ctrl+Enter',
  heading: 'Навигация',
  nav: [
    {
      key: 'Schon',
      items: [
        {
          link: '/feature/2023/12/07/o-nas',
          name: 'О нас',
          type: 'internal'
        },
        {
          link: 'https://www.instagram.com/schon.berlin',
          name: 'Инстаграм',
          type: 'external'
        },
        {
          link: 'https://t.me/schonberlin',
          name: 'Телеграм',
          type: 'external'
        },
        {
          link: 'https://www.facebook.com/schon.berlin',
          name: 'Фейсбук',
          type: 'external'
        },
        {
          link: '/feature/2023/12/07/obrabotka-dannyh',
          name: 'Обработка данных',
          type: 'internal'
        },
        {
          link: '/feature/2023/12/07/polzovatelskoe-soglashenie',
          name: 'Пользовательское соглашение',
          type: 'internal'
        }
      ]
    }
  ]
}
