import React from 'react'

export const IconGoogle = () => (
  <svg viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.432 11.873a2.617 2.617 0 0 1-.255.937c-.19.372-.528.755-1.193 1.088-.719.359-1.87.474-3 .245-.574-.117-1.036-.3-1.375-.502-.345-.207-.534-.417-.612-.573a6.477 6.477 0 0 0-.62-.96l-.008-.01-.057-.078-.402.298.402-.298a4.305 4.305 0 0 1-.458-.72c-.209-.438-.434-1.213-.212-2.86.091-.678.297-1.146.554-1.483.257-.337.587-.571.97-.747a5.863 5.863 0 0 1 1.902-.5c.689-.054 1.3.05 1.735.35a1.5 1.5 0 0 0 2.086-.38l-.39-.27.39.27a1.5 1.5 0 0 0-.381-2.088c-.627-.433-1.525-.853-2.353-.853-1.092 0-2.751.063-4.238.744C5.31 4.219 3.992 5.64 3.67 8.039c-.278 2.066-.044 3.46.477 4.552.248.52.537.92.753 1.212l.044.06c.21.284.303.412.37.547.783 1.565 2.55 2.363 4.076 2.673 1.583.321 3.458.237 4.937-.502 1.175-.588 2.013-1.406 2.524-2.412.501-.984.632-2.035.596-3.015-.056-1.556-1.459-2.405-2.674-2.31-1.206.094-2.418.1-3.707.1a1.5 1.5 0 1 0 0 3c1.097 0 2.225-.004 3.367-.07Z"
      stroke="#250000"
      strokeLinecap="round"
    />
  </svg>
)
