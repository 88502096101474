import React from 'react'
import { SimpleTitleProps } from './SimpleTitle.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import styles from './SimpleTitle.module.css'

export const SimpleTitle: React.FC<SimpleTitleProps> = ({
  block: {
    data: { first },
    only_on: onlyOn
  },
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], true]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <h1 data-testid="simple-title" className={makeClassName(classNames)}>
      {first}
    </h1>
  )
}
