import React from 'react'
import { RouteConfigComponentProps } from 'react-router-config'
import { Meta } from '@client/components/Layout/Meta'
import { Membership } from '@client/components/Modules/Membership'
import { PLANS } from '@client/constants'
import { Layout } from '@components/Layout'

export const MembershipContainer: React.FC<RouteConfigComponentProps> = ({
  location: { pathname },
  route
}) => {
  return (
    <Layout
      pathname={pathname}
      routeName={route && route.name}
      type="membership"
    >
      <Meta
        screen="membership"
        lang="ru"
        og={{
          title: 'Подписка – Schön',
          description: 'Подпишитесь на Schön',
          image:
            'https://impro.schon.berlin/G8xXguw9NgZl8NPcXArw3ks31rToZ_3H2da7ChtBpvo/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/Lzk3My9vcmlnaW5h/bC9mMmhxZmsxV18z/OGhsay1VbjQ0OUNn/LmpwZw.jpg',
          url: 'https://schon.berlin/membership'
        }}
      />

      <Membership plans={PLANS.prod} />
    </Layout>
  )
}
