import { useEffect, useState } from 'react'

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState('up')

  useEffect(() => {
    let animationFrameId
    let prevScrollY = window.scrollY

    const handleScroll = () => {
      cancelAnimationFrame(animationFrameId)

      animationFrameId = requestAnimationFrame(() => {
        const currentScrollY = window.scrollY

        if (currentScrollY > prevScrollY) {
          setScrollDirection('down')
        } else if (currentScrollY < prevScrollY) {
          setScrollDirection('up')
        }

        prevScrollY = currentScrollY
      })
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      cancelAnimationFrame(animationFrameId)
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return scrollDirection
}
