import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@common/Modal'
import { Footer } from '@components/Layout/Footer'
import { Header } from '@components/Layout/Header'
import { getApp } from '@selectors/appSelectors'
import { getLang } from '@selectors/currentUserSelectors'
import { getModal } from '@selectors/modalSelectors'
import equal from 'fast-deep-equal'

import { GDPRPanelContainer } from './GDPRPanel/GDPRPanelContainer'
import { FootnoteContainer } from './FootnoteContainer'

import styles from './Layout.module.css'

interface LayoutProps {
  pathname: string
  routeName: string
  materialName?: string
  type: string
  children: React.ReactNode
}

export const LayoutComponent: React.FC<LayoutProps> = ({
  pathname,
  routeName,
  materialName,
  type,
  children
}) => {
  const { isEmbedded, isPreview } = useSelector(getApp, equal)
  const lang = useSelector(getLang)
  const { type: modalType } = useSelector(getModal, equal)

  const shouldRenderHeader = routeName !== 'membership'

  return (
    <div
      className={styles.root}
      data-pathname={pathname}
      data-route={routeName}
      data-material={materialName}
    >
      <a className={styles.skipLink} href="#maincontent">
        {lang === 'ru' ? 'Перейти к материалам' : 'Skip to main content'}
      </a>

      {shouldRenderHeader && (
        <div className={styles.header} data-inapp-hide="true">
          <Header />
        </div>
      )}

      <main key="client" className={styles.content} id="maincontent">
        {children}
      </main>

      {modalType && <Modal />}

      <footer className={styles.footer}>
        <Footer />
      </footer>

      {!isEmbedded && !isPreview && <GDPRPanelContainer lang={lang} />}

      {type === 'material' && <FootnoteContainer />}
    </div>
  )
}

export const Layout = memo(LayoutComponent)

Layout.whyDidYouRender = true
