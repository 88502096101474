import React from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import 'dayjs/locale/ru'

import { TimestampProps } from './Timestamp.types'

import styles from './Timestamp.module.css'

export const Timestamp: React.FC<TimestampProps> = ({
  publishedAt,
  locale = 'ru',
  type
}) => {
  let published
  const timeFormat = locale === 'ru' ? 'D MMMM YYYY' : 'MMMM D, YYYY'

  const preparedPublishedAt = publishedAt * 1000

  dayjs.locale(locale)
  dayjs.extend(relativeTime)

  if (type === 'fromNow') {
    published = dayjs(preparedPublishedAt).fromNow()
  } else if (type === 'format') {
    published = dayjs(preparedPublishedAt).format('HH:mm')
  } else if (type === 'date') {
    published = dayjs(preparedPublishedAt).format(timeFormat)
  } else {
    throw new Error('Unknown time type')
  }

  return (
    <time
      suppressHydrationWarning={true}
      data-testid="timestamp"
      className={styles.root}
    >
      {published}
    </time>
  )
}
