import React from 'react'
import { TagProps } from './Tag.types'
import makeStyleContext from '../utils/makeStyleContext'
import { makeClassName, ClassNames } from '../utils/makeClassName'

import styles from './Tag.module.css'

export const Tag: React.FC<TagProps> = ({
  children,
  size = 'small',
  theme = 'gold',
  styleContext,
  correlation,
  onlyOn
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[size], !!size && !!styles[size]],
    [styles[theme], !!theme && !!styles[theme]],
    [styles[onlyOn], !!onlyOn && !!styles[onlyOn]],
    [styles[correlation], !!correlation && !!styles[correlation]]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="tag" className={makeClassName(classNames)}>
      {children}
    </div>
  )
}
