import React from 'react'

export const IconAvatar = () => (
  <svg viewBox="0 0 46 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.187 19.004c.07.12.15.24.23.34.06.42.14.83.23 1.23l.06.78c.01.15.05.3.1.44.38.94.83 1.48 1.27 1.77.12.13.26.25.4.36a1.49 1.49 0 0 0 1.46.22c.49-.19.85-.62.94-1.13.02-.13.05-.26.07-.39.11-.19.21-.4.3-.62 0 0 0-.02.01-.03.09-.22.16-.45.23-.68v-.01c.46-1.64.45-3.5.41-3.77-.01-.39-.07-.88-.18-1.4-.26-1.49-.81-2.89-2.09-3.28-.04-.02-.08-.05-.12-.06-.07-.04-.14-.06-.2-.09s-.12-.06-.18-.08c-.09-.03-.18-.04-.26-.05-.06 0-.12-.02-.18-.03-.09 0-.19.01-.28.03-.06 0-.11 0-.17.02-.15.04-.29.09-.43.17-.24.14-.51.29-.74.72-.1.02-.2.04-.3.08-.72.28-1.11 1.07-.9 1.81.05.16.09.36.12.57v.06c-.27 1.03-.29 2.16.2 3.02Z"
      fill="#FF3B0E"
    />
    <path
      d="M41.176 11.325c.15.11.31.2.5.26a1.506 1.506 0 0 0 1.86-1.02c1.09-3.76.06-7.17-2.77-9.12-3.13-2.15-7.67-1.88-11.13.67-4.16-1.58-9.19-1.82-13.26-.43-1.79.61-3.29 1.5-4.5 2.63-.1-.08-.2-.16-.32-.22-3.92-1.94-7.57-1.47-9.77 1.26-2.67 3.3-2.31 8.77.83 12.72.06.07.13.14.2.2-3.82 7.82-2.27 16.34 4.22 22.89 4.92 4.97 11.5 7.63 17.91 7.62 3.4 0 6.75-.75 9.79-2.29 5.82-2.74 9.81-8.52 10.95-15.87 1.1-7.06-.66-14.3-4.51-19.3Zm1.55 18.84c-.99 6.35-4.36 11.31-9.31 13.64-8.93 4.54-18.83.72-24.25-4.75-5.67-5.72-6.96-13.17-3.44-19.92.34-.66.14-1.46-.44-1.89.02-.36-.08-.73-.32-1.03-2.51-3.16-2.4-7.04-.84-8.97 1.25-1.55 3.32-1.73 5.85-.56-.44.76-.78 1.58-1.03 2.46a1.5 1.5 0 0 0 1.04 1.85 1.486 1.486 0 0 0 1.85-1.03c.91-3.25 3.5-4.74 5.51-5.42 3.74-1.27 8.61-.88 12.27.84.18.13.38.23.6.3.56.3 1.09.63 1.58.99.66.49 1.6.36 2.1-.31.5-.66.36-1.6-.3-2.1-.25-.19-.51-.37-.78-.54 2.14-1 4.54-.97 6.25.21 1.76 1.21 2.32 3.28 1.59 5.81-.11.37-.06.74.09 1.06-.51-.62-1.05-1.21-1.63-1.74a1.5 1.5 0 0 0-2.04 2.2c4.56 4.23 6.78 11.66 5.65 18.93v-.03Z"
      fill="#FF3B0E"
    />
    <path
      d="M29.747 23.645c.17.1.37.16.57.19.09.01.17.02.26.02.23 0 .45-.04.66-.11.13.01.26 0 .38 0 .12-.01.25-.04.37-.08 2.98-1.04 2.09-8.46 2.08-8.53-.1-.7-.35-2.51-1.81-2.71-.73-.82-1.48-.88-2.02-.77-1.56.33-1.87 2.32-1.99 3.07-.21 1.35-.25 3.2-.1 4.84.26 2.95.95 3.73 1.59 4.09l.01-.01Z"
      fill="#FF3B0E"
    />
    <path
      d="M36.196 25.334v.4c0 .21.04.41.12.6.06 5.46-2.25 9.41-6.56 11.17-.61.25-1.24.44-1.89.59.03-.08.08-.15.1-.23.11-.24.15-.5.13-.75.24-1.51.48-3.4.42-5.16.02-.05.02-.11.03-.17.18-.22.32-.49.35-.79.64-.59 1.12-1.43 1.26-1.98.06-.23.06-.45.01-.67.14-.46.15-.98-.08-1.55-.25-.62-.87-1-1.53-.94h-.1c-.88.08-1.7.17-2.42.32-.34-.14-.72-.16-1.08-.03-.06.01-.12.02-.2.03-.1.02-.2.04-.3.05-.56-.29-1.26-.22-1.73.25l-.01.01c-.21.21-.4.54-.54.92-.08.15-.14.32-.17.51v.06c-.13.31-.15.67-.04 1.02.08.24.22.44.39.6.02.02.03.05.05.07.05.13.1.25.19.37.05.07.12.13.18.19l.09.09c.07.08.14.15.23.21l2 1.5c.13.1.27.17.42.22.03 1.57-.2 3.31-.43 4.67-.21.56-.09 1.01.07 1.31.04.07.09.13.14.19-4.3.16-8.86-1.56-11.64-4.68-3.62-4.06-3.74-9.7-.34-15.88.4-.73.14-1.64-.59-2.04-.73-.4-1.64-.14-2.04.59-4 7.27-3.74 14.31.73 19.32 3.26 3.66 8.34 5.71 13.31 5.71 2.13 0 4.24-.38 6.18-1.17 5.68-2.32 8.75-7.72 8.4-14.82a1.47 1.47 0 0 0-.21-.69c-.22-.55-.76-.93-1.39-.93-.83 0-1.5.67-1.5 1.5l-.01.01Z"
      fill="#FF3B0E"
    />
  </svg>
)
