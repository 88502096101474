import React from 'react'

import styles from './MembershipTeam.module.css'

const MembershipTeamItem = ({ name, secondName, position, image }) => (
  <li className={styles.item}>
    <p>{position}</p>
    <h3>
      <span>{name}</span>
      <img src={image} alt={name} />
      <span>{secondName}</span>
    </h3>
  </li>
)

export const MembershipTeam = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Кто делает Schön</h2>

      <ul className={styles.team}>
        <MembershipTeamItem
          name="Дмитрий"
          secondName="Вачедин"
          position="Главный редактор"
          image="https://impro.schon.berlin/345UAv8e1wxLmAjEv_TusiAdDBGnDkWmToKb_cd5JKg/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzUzMC9vcmlnaW5h/bC85RUJ2Ml9SQ3Jh/Zzg0dk9zTmJkN3J3/LmpwZw.jpg"
        />
        <MembershipTeamItem
          name="Максим"
          secondName="Филимонов"
          position="Издатель"
          image="https://impro.schon.berlin/cpMIiqE3CGSWO_5dTXDg_6m4BnWJLVAofHbz4eL27hg/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzUzNS9vcmlnaW5h/bC93XzNtNnpBME9w/WU5sc2ZyeWdGdnpn/LmpwZw.jpg"
        />
        <MembershipTeamItem
          name="Алексей"
          secondName="Мунипов"
          position="Заместитель главного редактора"
          image="https://impro.schon.berlin/_Dkz-0wVa-cW1uFA3Bbga-21Jd7NIOBB_AXsjGPBl2s/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzU0MC9vcmlnaW5h/bC9oZk5iMHc3eUty/Rld0TXhBX2hHRkRn/LmpwZw.jpg"
        />
        <MembershipTeamItem
          name="Дарья"
          secondName="Постнова"
          position="Старшая редакторка"
          image="https://impro.schon.berlin/Z6PK7cEs4dZWR6QrTaaYWGAanWJCu-037m6mlMmFQZM/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzU0NS9vcmlnaW5h/bC9fTmpJRUNoTG00/c1JJWUQ5NVJfNHZn/LmpwZw.jpg"
        />
        <MembershipTeamItem
          name="Ярослав"
          secondName="Максимов"
          position="Арт-директор"
          image="https://impro.schon.berlin/1yXcdffliakKu-b__weVoXB4TN4jYKRjP37PZlzpP2c/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzU1MC9vcmlnaW5h/bC8xa0JyM3JYb3l4/Y3ZhbW5nWXUyNmt3/LmpwZw.jpg"
        />
        <MembershipTeamItem
          name="Дарья"
          secondName="Глобина"
          position="Фоторедактор и фотограф"
          image="https://impro.schon.berlin/aO82NUjcSEWaKv4K6aLyZOuCHzOXuoOFiLa7Lp2jUZU/resizing_type:fit/width:5340/height:0/enlarge:0/quality:80/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzUyNS9vcmlnaW5h/bC83VlA4SzFqaWZs/YVA2SjVWcjU2S2V3/LmpwZw.jpg"
        />
      </ul>
    </div>
  )
}
