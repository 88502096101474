const SHOW_CONSOLE = false

interface PageViewParams {
  pathname: string
  title: string
  referrer: string
  tagName?: string
}

interface GaOptions {
  page_title: string
  page_location: string
  dimension1: string
  dimension2?: string
}

export const Analytics = {
  pageView({ pathname, title, referrer, tagName }: PageViewParams) {
    const options: GaOptions = {
      page_title: title,
      page_location: pathname,
      dimension1: 'website'
    }

    if (tagName) {
      options.dimension2 = tagName
    }

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE && console.log('GAActions.pageView', options, { referrer })
      return
    }

    window.gtag('event', 'page_view', options)
  },

  bannerShow(place: string, url: string) {
    const category = 'view'
    const action = place
    const label = url

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.bannerShow', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  materialScrolled(documentUrl: string, percentage: number) {
    const category = 'scrolling'
    const action = `${percentage}%`
    const label = documentUrl

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.materialScrolled', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  share(service: string, place: string, materialUrl: string) {
    const category = 'share'
    const action = `click:${place || 'unknown'}`
    const label = `${service}:${materialUrl}`

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE && console.log('GAActions.share', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  switchView(isChronology: boolean) {
    const category = 'ui_action'
    const action = 'button_press'
    const label = 'chrono-or-not'
    const value = isChronology

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.switchView', category, action, label, value)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value: value
    })
  },

  startAudioPlaying(episodeKey: string) {
    const category = 'audio'
    const action = 'play'
    const label = episodeKey

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.startAudioPlaying', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      dimension1: 'website'
    })
  },

  finishedEpisode(episodeKey: string) {
    const category = 'audio'
    const action = 'finished'
    const label = episodeKey

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.finishedEpisode', category, action, label)
      return
    }
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      dimension1: 'website'
    })
  },

  topbarAdVisible({ url, type }: { url: string; type: string }) {
    const category = url
    const action = 'show'
    const label = type

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.topbarAdVisible', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  topbarAdClick({ url, type }: { url: string; type: string }) {
    const category = url
    const action = 'click'
    const label = type

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.topbarAdClick', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  informerBecomeVisible({ url, type }: { url: string; type: string }) {
    const category = url
    const action = 'show'
    const label = type

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.informerBecomeVisible', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  informerClicked({ url, type }: { url: string; type: string }) {
    const category = url
    const action = 'click'
    const label = type

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE &&
        console.log('GAActions.informerClicked', category, action, label)
      return
    }

    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      non_interaction: true,
      dimension1: 'website'
    })
  },

  uiKitAction({
    category,
    element,
    action
  }: {
    category?: string
    element: string
    action: string
  }) {
    const label = `UIKit_${category}` || 'UIKit'

    if (process.env.NODE_ENV === 'development') {
      SHOW_CONSOLE && console.log(`GAActions.${label} ${action}ed`, element)
      return
    }

    window.gtag('event', action, {
      event_category: label,
      event_label: element,
      dimension1: 'website'
    })
  }
}
