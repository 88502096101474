import React, { useRef, useState } from 'react'

import { Button } from '../Button'
import { RenderBlocks } from '../RenderBlocks'
import { SvgSymbol } from '../SvgSymbol'
import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import postMessage from '../utils/postMessage'

import { SpoilerProps } from './Spoiler.types'

import styles from './Spoiler.module.css'

export const Spoiler: React.FC<SpoilerProps> = ({
  block: { display, button, only_on: onlyOn, title, blocks },
  styleContext
}) => {
  const [spoiled, setSpoiled] = useState(false)
  const ref = useRef(null)

  const handleButtonClick = (): void => {
    setSpoiled(!spoiled)

    if (spoiled) {
      postMessage('', 'closeSpoiler', 'click')
      const rect = ref.current.getBoundingClientRect()
      if (rect.top < 0) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }
    } else {
      postMessage('', 'openSpoiler', 'click')
    }
  }

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], !!onlyOn],
    [styles[display], !!display && styles[display]],
    [styles.spoiled, !!spoiled],
    [styles.withoutTitle, !title]
  ]

  const context = ['center']

  const isOutline = title || spoiled

  if (styleContext) {
    context.concat(styleContext)
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div ref={ref} className={makeClassName(classNames)} data-testid="spoiler">
      <div className={styles.container}>
        {title && (
          <div className={styles.header}>
            <h3>{title}</h3>
          </div>
        )}
        <div className={styles.body}>
          {blocks.map((item) => (
            <RenderBlocks key={item.id} block={item} />
          ))}
        </div>
        <div className={styles.footer}>
          <Button
            size="default"
            theme={isOutline ? 'outlineSpoiler' : 'simpleSpoiler'}
            onClick={(): void => handleButtonClick()}
            styleContext="isInSpoiler"
          >
            {spoiled ? button.collapse : button.expand}

            <div className={styles.icon} />
          </Button>
        </div>
      </div>
    </div>
  )
}
