/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react'
import { FREE_MATERIALS } from '@client/constants'
import { localStorageService } from '@client/utils/localStorageService'
import { setPaywallBannerInitialStorage } from '@client/utils/Paywall'
import dayjs from 'dayjs'

import Context, { PaywallContext } from './PaywallContext'

type Props = {
  children?: React.ReactNode
}

export interface PaywallStorage {
  timestamp: number
  history: string[]
}

export interface PaywallAccess {
  isPaywall: boolean
  isFree: boolean
  counter: number
  visited: boolean
}

const STORAGE_KEY = 'config'
const FREE_ACCESS = 3
const FREE_ACCESS_DURATION = 30

const PaywallProvider: React.FC<Props> = ({ children }) => {
  const today = dayjs()

  const paywallContext = useRef<PaywallContext>()

  const setInitialStorage = () => {
    const initial = {
      timestamp: today.add(FREE_ACCESS_DURATION, 'day').unix(),
      history: []
    }

    localStorageService.set(STORAGE_KEY, initial)

    return initial
  }

  const validateStorage = (storage?: PaywallStorage): boolean => {
    if (!storage || storage.timestamp < today.unix()) {
      return false
    }

    return true
  }

  const getSafeStorage = (): PaywallStorage => {
    const storage: PaywallStorage | null = localStorageService.get(STORAGE_KEY)
    const isValideStorage = validateStorage(storage)

    if (!isValideStorage) {
      setPaywallBannerInitialStorage()
      return setInitialStorage()
    }

    return storage
  }

  const updateStorage = (storage: PaywallStorage, material: string): void => {
    const nextStorage = {
      timestamp: storage.timestamp,
      history: [...storage.history, material]
    }
    localStorageService.set(STORAGE_KEY, nextStorage)
  }

  const findMaterial = (history: string[], material: string): boolean => {
    const isAlreadyRead = history.find((item) => item === material)

    return Boolean(isAlreadyRead)
  }

  const setMaterial = (material: string, limit: number): boolean => {
    const storage = getSafeStorage()
    const alreadyExists = findMaterial(storage.history, material)

    if (alreadyExists) {
      return true
    }

    const hasMax = storage.history?.length >= limit

    if (hasMax) {
      return false
    }

    updateStorage(storage, material)

    return true
  }

  const getCounter = (): number => {
    const storage = getSafeStorage()

    return storage?.history?.length || 0
  }

  const getGiftAccess = (url: string, limit: number) => {
    const storage = getSafeStorage()

    const alreadyVisited = findMaterial(storage.history, url)
    const isAccessGranted = setMaterial(url, limit)
    const counter = getCounter()

    return {
      isPaywall: true,
      isFree: isAccessGranted,
      visited: alreadyVisited,
      counter: counter
    }
  }

  const isAccessGranted = (url: string, account: any): PaywallAccess => {
    const isFreeMaterial = findMaterial(FREE_MATERIALS, url)

    if (isFreeMaterial || account?.is_access_granted) {
      return { isPaywall: false, isFree: false, counter: 0, visited: false }
    }

    return getGiftAccess(url, FREE_ACCESS)
  }

  paywallContext.current = {
    getCounter,
    isAccessGranted
  }

  return (
    <Context.Provider value={paywallContext.current}>
      {children}
    </Context.Provider>
  )
}

export default PaywallProvider
