/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { MembershipPlansItem } from './MembershipPlansItem'

import styles from './MembershipPlans.module.css'

interface BannerProps {
  plans: {
    monthly: string
    yearly: string
  }
  account: any
  firebaseUser: any
}

export const MembershipPlans: React.FC<BannerProps> = ({
  plans,
  account,
  firebaseUser
}) => {
  const handleCheckoutClick = async (priceID: string) => {
    const token = await window.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => token)

    const checkout = await fetch('/api/paywall/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ price_id: priceID })
    }).then((res) => res.json())

    if (!checkout?.session_url) {
      return null
    }
    return window.location.replace(checkout?.session_url)
  }

  return (
    <div className={styles.root}>
      <MembershipPlansItem
        id={plans.monthly}
        type="monthly"
        handleCheckoutClick={handleCheckoutClick}
        firebaseUser={firebaseUser}
        account={account}
      />
      <MembershipPlansItem
        id={plans.yearly}
        type="yearly"
        handleCheckoutClick={handleCheckoutClick}
        firebaseUser={firebaseUser}
        account={account}
        featured={true}
      />
    </div>
  )
}
