import React from 'react'
import { MaterialContent } from '@client/types'
import { GeneralMaterial } from '@routes/Material/GeneralMaterial'

export const renderMaterialComponents = (material: MaterialContent) => {
  switch (material.layout) {
    default:
      return <GeneralMaterial material={material} />
  }
}
