import { utmMedium, utmSource } from '@client/constants'
import { MaterialContent, ScreenContent, ShareServices } from '@client/types'
import { popupWindow } from '@utils/popupWindow'

const targets: Record<
  string,
  {
    counter?: string
    share: string
    response?: string
  }
> = {
  fb: {
    counter: '//graph.facebook.com/{{href}}',
    share: '//www.facebook.com/sharer/sharer.php?u={{href}}',
    response: 'shares'
  },

  tw: {
    counter: '//cdn.api.twitter.com/1/urls/count.json?url={{href}}',
    share: '//twitter.com/intent/tweet?text={{text}}&url={{href}}',
    response: 'count'
  },

  tg: {
    share:
      '//telegram.me/share/url?url={{href}}&text=Schön%20%E2%80%94%20издание%20про%20Берлин%20@schonberlin'
  }
}

export const sharePopup = (
  service: ShareServices,
  material: MaterialContent | ScreenContent
) => {
  if (service === 'pdf') {
    const path = window.location.origin + material.og.pdf?.standard.path

    window.open(path, 'Download')

    return
  }

  const [origin] = window.location.href.split('?')
  let href = origin

  href += `?utm_source=${utmSource[service]}`
  href += `&utm_medium=${utmMedium[service]}`
  href += '&utm_campaign=share'

  let url = targets[service].share.replace(
    /\{\{href\}\}/,
    window.encodeURIComponent(href)
  )

  url = url.replace(/\{\{text\}\}/, window.encodeURIComponent(document.title))

  popupWindow(url, 'Share', 480, 320)
}
