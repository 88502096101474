import React from 'react'

export const ButtonLoader: React.FC = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M15 0C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm0 2c7.18 0 13 5.82 13 13s-5.82 13-13 13S2 22.18 2 15 7.82 2 15 2z"
        opacity="0.3"
      />
    </g>
    <g fillRule="evenodd">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 15 15"
        to="360 15 15"
        dur="1000ms"
        repeatCount="indefinite"
      />
      <path d="M15 0c8.18 0 14.83 6.547 14.997 14.686L30 15h-2c0-7.077-5.655-12.833-12.693-12.996L15 2V0z" />
    </g>
  </svg>
)
