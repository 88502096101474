import React, { useState } from 'react'

import { DotsOnImage } from '../DotsOnImage'
import { Image } from '../Image'
import { MediaCaption } from '../MediaCaption'
import { Popover } from '../Popover'
import DangerousHTML from '../utils/DangerousHtml'
import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { EmbedBlockProps } from './EmbedBlock.types'
import { EmbedGif } from './EmbedGif'

import styles from './EmbedBlock.module.css'

export const EmbedBlock: React.FC<EmbedBlockProps> = ({
  block,
  block: {
    data,
    data: {
      html,
      display,
      only_on: onlyOn,
      provider,
      cc,
      optimized,
      gif_url: gifUrl,
      mp4_url: mp4Url
    }
  },
  styleContext,
  children
}) => {
  const [isPopoverShown, setIsPopoverShown] = useState(false)
  const style: React.CSSProperties = {}
  const isProportional = block.data.height && block.data.width

  const fullWidthProviders = [
    'youtube',
    'vimeo',
    'onetv',
    'coub',
    'meduza',
    'facebook_video'
  ]

  const mobileFullwidth =
    fullWidthProviders.indexOf(provider) > -1 || block.type === 'dots_on_image'

  const renderCC = (context?: string | string[]): JSX.Element => (
    <MediaCaption
      credit={data.credit}
      caption={data.caption}
      styleContext={context || styleContext}
    />
  )

  const renderEmbed = (style: React.CSSProperties): JSX.Element => {
    switch (block.type) {
      case 'gif': {
        return <EmbedGif gif={gifUrl} mp4Url={mp4Url} style={style} />
      }
      case 'image': {
        return (
          <div className={styles.figure}>
            <Image
              source={!optimized && [block.data.image_url]}
              optimized={block.data.optimized}
              width={block.data.width}
              height={block.data.height}
              alt={block.data.caption}
              display={block.data.display}
              lazy={block.data.lazy}
              styleContext={styleContext}
            />
          </div>
        )
      }
      case 'game_embed': {
        return (
          <div className={styles.gameEmbed}>
            <iframe
              src={block.data.game_src}
              id={block.data.game_id}
              title="Game embed"
            />
          </div>
        )
      }
      case 'dots_on_image': {
        return (
          <div className={styles.figure}>
            <DotsOnImage block={data} />
          </div>
        )
      }

      default: {
        return (
          <div className={styles.object}>
            <div className={styles.wrapper} style={style}>
              <DangerousHTML className={styles.objectWrap} html={html} />
            </div>
          </div>
        )
      }
    }
  }

  if (isProportional) {
    style.paddingBottom = `${(block.data.height / block.data.width) * 100}%`
    style.height = 0
  }

  let classNames: ClassNames = [
    [styles.root, true],
    [styles.cc, cc],
    [styles[display], !!display && styles[display]],
    [styles[provider], !!provider && !!styles[provider]],
    [styles[onlyOn], !!onlyOn && styles[onlyOn]],
    [styles.mobileFullwidth, mobileFullwidth],
    [styles.proportional, isProportional]
  ]

  // Убираем из контекста все, что не начинается с приставки isIn,
  // чтобы не применились лишние модификаторы
  // TODO: в w6 модификаторы должны передаваться явно

  if (styleContext && Array.isArray(styleContext)) {
    const filteredContext = styleContext
      .filter((key) => key.startsWith('isIn') && styles[key])
      .reduce((acc, key) => {
        acc.push(key)

        return acc
      }, [])

    classNames = makeStyleContext(classNames, filteredContext, styles)
  }

  return (
    <figure className={makeClassName(classNames)}>
      {children && (
        <div className={styles.object}>
          <div className={styles.wrapper} style={style}>
            <div className={styles.objectWrap}>{children}</div>
          </div>
        </div>
      )}

      {!children && renderEmbed(style)}

      {cc === 'default' && <figcaption>{renderCC()}</figcaption>}

      {cc === 'button' && (
        <button
          className={styles.control}
          type="button"
          aria-label="Open"
          onClick={(): void => {
            setIsPopoverShown(!isPopoverShown)
          }}
        />
      )}

      {cc === 'button' && isPopoverShown && (
        <Popover
          onClose={(): void => {
            setIsPopoverShown(false)
          }}
        >
          {renderCC('isInPopover')}
        </Popover>
      )}
    </figure>
  )
}
