import React from 'react'
import { Link } from 'react-router-dom'
import { ImageUrls, RichScreenDocument } from '@client/types'
import { mapDesktopPosition } from '@client/utils/mapDesktopPosition'
import { mapMobilePosition } from '@client/utils/mapMobilePosition'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { capitalize } from '@utils/capitalize'
import { makeClassName } from '@utils/makeClassName'

import styles from './RichBlockImage.module.css'

interface RichBlockProps {
  block: RichScreenDocument
  correlation: keyof ImageUrls
  origin: string
}

export const RichBlockImage: React.FC<RichBlockProps> = ({
  block,
  correlation,
  origin
}) => {
  const {
    layout,
    image,
    image: { base_urls: baseUrls }
  } = block

  const appearance = block.appearance.image

  const is1to4 = correlation === 'is1to4'

  const desktopPosition = is1to4
    ? null
    : mapDesktopPosition(appearance.desktop_sticker.position)

  const mobilePosition = mapMobilePosition(appearance.mobile_sticker.position)

  const style = {
    '--title-color': `rgb(${appearance.title_color})`
  } as React.CSSProperties

  const mobileImageKey = appearance.mobile_sticker.enabled
    ? 'isMobileWithSticker'
    : 'isMobileWithoutSticker'

  const imageKey = correlation === 'isMobile' ? mobileImageKey : correlation

  const withMobileSticker = appearance.mobile_sticker.enabled

  return (
    <article
      className={makeClassName([
        [styles.root, true],
        [
          styles.isSticker,
          withMobileSticker && origin !== 'chronologyRichBlockImage'
        ],
        [
          styles.withoutSticker,
          !withMobileSticker || origin === 'chronologyRichBlockImage'
        ],
        [styles[desktopPosition], !!desktopPosition],
        [styles[mobilePosition], !!mobilePosition && withMobileSticker],
        [styles[correlation], !!correlation && !!styles[correlation]]
      ])}
      style={style}
    >
      <Link className={styles.link} to={`/${block.url}`}>
        <div className={styles.imageWrap}>
          <picture className={styles.picture}>
            <source
              type="image/webp"
              srcSet={`
                    ${image.optimised_urls[imageKey]}`}
            />
            <source
              srcSet={`
                  ${baseUrls[imageKey]}`}
            />
            <img
              src={(baseUrls && baseUrls[imageKey]) || image[imageKey]}
              alt=""
            />
          </picture>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <div className={styles.title}>
              <BlockTitle
                block={block}
                correlation={correlation}
                styleContext={`isInRichImage`}
              />
            </div>

            <div className={styles.meta}>
              <BlockMetaContainer block={block} />
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}