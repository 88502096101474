// import { HistoryElement } from '@client/types'
// import { User as FirebaseUser } from 'firebase/auth'
import { eventChannel } from 'redux-saga'
import { FirebaseFunctionsData } from 'types/storeTypes'

/*
Вызов firebase functions
*/

export function* callFunctions(
  endpoint: string,
  data?: FirebaseFunctionsData
): unknown {
  const firebase = window.firebaseApp
  const functions = firebase.functions()

  const response = yield functions.httpsCallable(endpoint)(data)
  return response.data
}

export function* loginGoogle(): unknown {
  const firebase = window.firebaseApp
  const googleAuthProvider = new window.firebase.auth.GoogleAuthProvider()
  return yield firebase.auth().signInWithPopup(googleAuthProvider)
}

export function* sendEmail(
  email: string,
  actionCodeSettings: { url: string; handleCodeInApp: boolean }
): unknown {
  const firebase = window.firebaseApp
  return yield firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
}

export function* logout(): unknown {
  const firebase = window.firebaseApp
  return yield firebase.auth().signOut()
}

export function* markMaterialRead(uid: string, articleKey: string) {
  const firebase = window.firebase
  const db = window.firebaseApp.firestore()

  const historyShelf = db
    .collection('users')
    .doc(uid)
    .collection('doc')
    .doc('history')

  yield historyShelf.set(
    {
      [articleKey]: firebase.firestore.Timestamp.fromDate(new Date())
    },
    { merge: true }
  )
}

export function* episodeTimeUpdate(
  uid: string,
  episodeUrl: string,
  progress: number
) {
  const firebase = window.firebaseApp
  const db = firebase.firestore()

  yield db
    .collection('users')
    .doc(uid)
    .collection('doc')
    .doc('episode_history_shelf')
    .set(
      {
        // TODO: костыль для приложения
        [episodeUrl]: progress === 1 ? 1.01 : progress
      },
      { merge: true }
    )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createFirestoreSubscriber(user: any) {
  const firebase = window.firebaseApp
  const db = firebase.firestore()

  return eventChannel((emit) => {
    const unsubscribers: (() => void)[] = []

    const unsubscribe = db
      .collection('users')
      .doc(user.uid)
      .onSnapshot((item) => {
        const data = item.data()
        emit({ message: 'account', data })
      })

    unsubscribers.push(unsubscribe)

    return () => {
      unsubscribers.forEach((unsubscribe) => {
        unsubscribe()
      })
    }
  })
}
