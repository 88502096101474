import React from 'react'

export const IconExternal = () => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.499 7.50024C10.499 7.50024 8.08582 9.5724 7.04852 10.8627C6.77983 11.1969 6.49804 11.5218 6.19449 11.8718L6.03823 12.0521C5.69079 12.4536 5.32028 12.887 4.97075 13.3467L4.9534 13.3635C4.9338 13.3821 4.91143 13.4027 4.87737 13.4339L4.87295 13.438C4.84271 13.4657 4.80259 13.5025 4.76207 13.5408C4.68031 13.6182 4.55675 13.739 4.43852 13.8878C4.32173 14.0348 4.16595 14.2631 4.07115 14.5648C3.81967 15.365 4.29536 16.2082 5.13364 16.4483C5.88257 16.6628 6.66753 16.3232 7.00569 15.6777C7.02419 15.6604 7.04503 15.6413 7.07302 15.6156L7.07498 15.6138C7.10587 15.5855 7.14721 15.5476 7.18877 15.5081C7.2673 15.4335 7.39778 15.306 7.51876 15.1461C7.809 14.7625 8.12993 14.3853 8.48155 13.979L8.62982 13.808C8.93164 13.46 9.25539 13.0869 9.56278 12.7045C9.80536 12.4028 10.0573 12.0627 10.2861 11.7537C10.3975 11.6034 10.5034 11.4604 10.6002 11.3328C10.9081 10.9265 11.1898 10.5824 11.4858 10.2924C11.5045 10.2815 11.5237 10.2708 11.5539 10.2541C11.5773 10.2411 11.6074 10.2244 11.6488 10.2012C11.7351 10.1527 11.8806 10.0698 12.0281 9.96261C12.174 9.85655 12.3755 9.68899 12.5484 9.44475C12.579 9.55364 12.6195 9.67106 12.6737 9.78923C12.8287 10.1276 12.8585 10.4961 13.0666 10.8214C13.1201 10.9049 13.1817 10.9831 13.2503 11.0546C13.2807 11.0863 13.389 11.199 13.56 11.3043L13.5629 11.3061C13.6328 11.3495 14.3515 11.7959 15.1839 11.3582C15.439 11.1911 15.7845 10.8013 15.8856 10.607C16.0426 10.1848 16.0338 9.75797 15.8339 9.34886C15.6813 9.03469 15.6201 8.6702 15.5643 8.32869C15.3721 7.15175 15.499 6.18591 15.499 5.0002C15.499 4.16476 14.7895 3.48755 13.9144 3.48755C13.396 3.48755 12.954 3.68997 12.4598 3.78136C11.8633 3.83909 10.5755 4.03816 10.1193 4.15912C10.0022 4.19018 9.89836 4.21772 9.81214 4.23889C9.70638 4.26485 9.586 4.28149 9.34086 4.31484L9.33521 4.31561C9.12767 4.34385 8.80688 4.38748 8.48042 4.47982C7.6413 4.71716 7.1626 5.55885 7.41123 6.35987C7.65986 7.16089 8.54167 7.61781 9.38079 7.38047C9.92721 7.22592 10.1193 7.2346 10.499 7.50024Z"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)
