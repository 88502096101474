import React, { useRef } from 'react'
import { motion, useScroll, useTransform } from 'framer-motion'

import {
  MembershipFeatureFive,
  MembershipFeatureFour,
  MembershipFeatureOne,
  MembershipFeatureThree,
  MembershipFeatureTwo
} from './MembershipFeaturesIcons'

import styles from './MembershipFeatures.module.css'

const progress = {
  0: [0.9, 0.9, 0.9, 0.9, 0.4, 0.2],
  1: [0.2, 0.4, 0.9, 0.9, 0.4, 0.2],
  2: [0.9, 0.9, 0.9, 0.9, 0.4, 0.2]
}

const ContentLine = ({ children, variant }) => {
  const contentRef = useRef()

  const { scrollYProgress } = useScroll({
    target: contentRef,
    offset: ['start center', 'start end'],
    layoutEffect: false
  })

  const scrollValue = useTransform(
    scrollYProgress,
    [0, 0.1, 0.2, 0.6, 0.8, 1],
    progress[variant]
  )

  return (
    <li className={styles.feature} ref={contentRef}>
      <motion.span style={{ opacity: scrollValue }}>{children}</motion.span>
    </li>
  )
}

export const MembershipFeatures = () => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Что вы получите</h2>

      <ul className={styles.featured}>
        <ContentLine variant={1}>
          Свое издание про&nbsp;Берлин —<br />
          без вас его не будет
          <MembershipFeatureOne />
        </ContentLine>
        <ContentLine variant={1}>
          Живое и поддерживающее
          <br />
          сообщество
          <MembershipFeatureTwo />
        </ContentLine>
        <ContentLine variant={1}>
          Полный доступ
          <br />
          ко всем статьям
          <MembershipFeatureThree />
        </ContentLine>
        <ContentLine variant={1}>
          Закрытые события
          <br />
          для подписчиков
          <MembershipFeatureFour />
        </ContentLine>
        <ContentLine variant={0}>
          Подарки
          <br />
          от наших друзей
          <MembershipFeatureFive />
        </ContentLine>
      </ul>
    </div>
  )
}
