import React, { useContext, useState } from 'react'

import { Lazy } from '../Lazy'
import BlockContext from '../utils/BlockContext'
import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import postMessage from '../utils/postMessage'

import { ImageProps } from './Image.types'
import RenderPicture from './RenderPicture'

import styles from './Image.module.css'

export const Image: React.FC<ImageProps> = ({
  alt,
  display,
  optimized,
  width,
  height,
  source,
  lazy,
  ratio,
  styleContext,
  fullscreen = true
}) => {
  const { lightBox } = useContext(BlockContext)
  const [isLoaded, setIsLoaded] = useState(lazy ? false : true)

  const handleClick = (): void => {
    if (!lightBox || !fullscreen || (optimized && !optimized.original)) {
      return
    }

    postMessage('', 'fullscreen_click', 'click')
    lightBox.show(optimized, width, height, alt)
  }

  const blockStyles = {
    maxWidth: width <= 649 ? width : '100%'
  }

  const pictureStyles = {
    paddingBottom: `${100 / (ratio || width / height)}%`
  }

  const fallbackSource =
    (optimized?.w325 && optimized.w325['1x']) ||
    optimized?.original ||
    source[0]

  let classNames: ClassNames = [
    [styles.root, true],
    [styles.isLoaded, isLoaded],
    [
      styles.fullscreen,
      lightBox && optimized && optimized.original && fullscreen
    ]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div
      className={makeClassName(classNames)}
      style={blockStyles}
      data-testid="image"
    >
      <div
        className={styles.wrapper}
        style={pictureStyles}
        onClick={(): void => handleClick()}
        onKeyPress={(): void => handleClick()}
        role="button"
        tabIndex={0}
      >
        {lazy ? (
          <>
            <Lazy threshold={0.25}>
              <RenderPicture
                fallbackSource={fallbackSource}
                source={source}
                optimized={optimized}
                alt={alt}
                display={display}
                onLoad={setIsLoaded}
              />
            </Lazy>
            <noscript>
              <img src={fallbackSource} alt={alt} />
            </noscript>
          </>
        ) : (
          <RenderPicture
            fallbackSource={fallbackSource}
            source={source}
            optimized={optimized}
            alt={alt}
            display={display}
            onLoad={setIsLoaded}
          />
        )}
      </div>
    </div>
  )
}
