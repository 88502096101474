import React, { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { makeClassName } from '../utils/makeClassName'
import { LazyProps } from './Lazy.types'

import styles from './Lazy.module.css'

export const Lazy: React.FC<LazyProps> = ({ children, threshold }) => {
  const [ref, inView, entry] = useInView({
    threshold: threshold || 0
  })

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (entry && entry.boundingClientRect && entry.boundingClientRect.y < 0) {
      setVisible(true)
    }
  }, [entry])

  useEffect(() => {
    if (inView) {
      setVisible(true)
    }
  }, [inView])

  return (
    <div
      className={makeClassName([
        [styles.root, true],
        [styles.visible, visible]
      ])}
      ref={ref}
      data-testid="lazy"
    >
      {visible && children}
    </div>
  )
}
