import React, { useState } from 'react'
import { SensitiveBlockProps } from './SensitiveBlock.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { EmbedBlockContainer } from '../EmbedBlock'
import { MediaCaption } from '../MediaCaption'
import { Button } from '../Button'

import styles from './SensitiveBlock.module.css'

export const SensitiveBlock: React.FC<SensitiveBlockProps> = ({
  block,
  block: { cc, caption, credit, blurred_image: blurredImage },
  block: {
    item: {
      data: { display }
    }
  },
  styleContext
}) => {
  const [visible, isVisible] = useState(false)

  const handleClick = () => {
    isVisible(true)
  }

  let classNames: ClassNames = [
    [styles.root, true],
    [styles.visible, visible],
    [styles[display], display && styles[display]]
  ]

  let context = ['isInSensitiveBlock']

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
    context = [...styleContext, 'isInSensitiveBlock']
  }

  return (
    <div data-testid="sensitive-block" className={makeClassName(classNames)}>
      <div className={styles.container}>
        <div
          className={styles.overlay}
          style={{ backgroundImage: `url(${blurredImage})` }}
        >
          <div className={styles.content}>
            <p dangerouslySetInnerHTML={{ __html: block.title }} />

            <div className={styles.button}>
              <Button onClick={() => handleClick()} theme="light">
                {block.button.text}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.media}>
          <EmbedBlockContainer block={block.item} styleContext={context} />
        </div>
      </div>

      {cc && (
        <figcaption className={styles.figcaption}>
          <MediaCaption
            credit={credit}
            caption={caption}
            styleContext={styleContext}
          />
        </figcaption>
      )}
    </div>
  )
}
