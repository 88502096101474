/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeClassName } from '@client/utils/makeClassName'
import { showModal } from '@store/Modal/modalActions'

import { MembershipData } from '../MembershipData'

import styles from './MembershipPlans.module.css'

interface MembershipPlansItem {
  type: 'monthly' | 'yearly'
  id: string
  account: any
  firebaseUser: any
  featured?: boolean
  handleCheckoutClick: (priceID: string) => void
}

export const MembershipPlansItem: React.FC<MembershipPlansItem> = ({
  id,
  account,
  firebaseUser,
  handleCheckoutClick,
  type,
  featured = false
}) => {
  const plan = MembershipData.membership_plans[type]

  const dispatch = useDispatch()

  const handleAuthClick = () => {
    dispatch(
      showModal({
        type: 'loginHeader',
        coordinates: 0
      })
    )
  }

  const hasAccess = account?.is_access_granted

  return (
    <div
      className={makeClassName([
        [styles.block, true],
        [styles.featured, featured]
      ])}
    >
      <h3
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: plan.title }}
      />

      <div className={styles.price}>
        {plan.price}
        <span>€</span>
      </div>
      {plan.subtitle && <div className={styles.subtitle}>{plan.subtitle}</div>}
      {plan.description && (
        <div className={styles.description}>{plan.description}</div>
      )}

      <div className={styles.footer}>
        {!firebaseUser && (
          <button className={styles.button} onClick={() => handleAuthClick()}>
            Подписаться
          </button>
        )}
        {firebaseUser && (
          <>
            {hasAccess ? (
              <Link to="/account" className={styles.button}>
                Подписаться
              </Link>
            ) : (
              <button
                className={styles.button}
                onClick={() => handleCheckoutClick(id)}
              >
                Подписаться
              </button>
            )}
          </>
        )}
      </div>
    </div>
  )
}
