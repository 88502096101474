import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RichBlockImage } from '@client/components/Routes/Screen/RichBlockImage'
import {
  MobileItem,
  PromoScreenDocument,
  RichScreenDocument,
  ScreenDocumentWithMobile
} from '@client/types'
import { getScreenDocuments } from '@selectors/screenDocumentsSelectors'

import { BlockPromo } from '../BlockPromo'
import { RichBlockText } from '../RichBlockText'

/*
 * Лента блоков для экранов мобилы
 */
import styles from './Feed.module.css'

interface RenderBlockProps {
  block: ScreenDocumentWithMobile
  starred?: boolean
  origin: string[]
  type?: 'image' | 'text' | 'promo'
}

const RenderBlock: React.FC<RenderBlockProps> = ({
  block,
  type,
  origin
}): JSX.Element | null => {
  switch (type) {
    case 'image': {
      return (
        <div className={styles.block} key={block.url}>
          <RichBlockImage
            block={block}
            correlation="isMobile"
            origin={origin && origin[0]}
          />
        </div>
      )
    }
    case 'text': {
      return (
        <div className={styles.block} key={block.url}>
          <RichBlockText
            block={block}
            correlation="isMobile"
            origin={origin && origin[0]}
          />
        </div>
      )
    }
    case 'promo': {
      return (
        <div className={styles.block} key={block.url}>
          <BlockPromo
            block={block as PromoScreenDocument}
            correlation="isMobile"
            origin={origin && origin[0]}
          />
        </div>
      )
    }

    default: {
      return <div>Unknown Block</div>
    }
  }
}

interface RenderBlocksProps {
  block: MobileItem
  screenDocuments: Record<string, ScreenDocumentWithMobile>
}

const RenderBlocks: React.FC<RenderBlocksProps> = ({
  block,
  screenDocuments
}) => {
  if (block.groupped) {
    return (
      <div key={`grouped-${block.keys.join('-')}`} className={styles.group}>
        {block.keys.map((item) => (
          <div className={styles.groupItem} key={item}>
            <RenderBlock block={screenDocuments[item]} origin={block.origin} />
          </div>
        ))}
      </div>
    )
  }
  return (
    <RenderBlock
      block={screenDocuments[block.key]}
      starred={block.starred}
      origin={block.origin}
      type={block.type}
    />
  )
}

interface RenderSectionBlocksProps {
  section: MobileItem[]
  screenDocuments: Record<string, ScreenDocumentWithMobile>
}
const RenderSectionBlocks: React.FC<RenderSectionBlocksProps> = ({
  section,
  screenDocuments
}) => {
  return (
    <div className={styles.container}>
      {section.map((block, i) => (
        <RenderBlocks
          key={`${block.key}-${i}`}
          block={block}
          screenDocuments={screenDocuments}
        />
      ))}
    </div>
  )
}

interface FeedProps {
  blocks: MobileItem[][]
}

const FeedComponent: React.FC<FeedProps> = ({ blocks }) => {
  const screenDocuments = useSelector(getScreenDocuments).byId

  return (
    <section className={styles.root}>
      {blocks.map((section) => {
        return (
          <RenderSectionBlocks
            section={section}
            key={`section-${section.map((block) => block.key).join('-')}`}
            screenDocuments={screenDocuments}
          />
        )
      })}
    </section>
  )
}

export const Feed = memo(FeedComponent)

Feed.whyDidYouRender = true
