import React from 'react'

import { MetaItem } from '../MetaItem'
import { Timestamp } from '../Timestamp'
import { TimestampProps } from '../Timestamp/Timestamp.types'
import { toCamel } from '../utils/converCase'

import { MetaContainerProps } from './Meta.types'
import { Meta } from './'

export const MetaContainer: React.FC<MetaContainerProps> = ({
  lang,
  block,
  block: {
    data: { components, theme }
  },
  styleContext
}) => {
  const themeColor =
    styleContext && styleContext.indexOf('dark') !== -1 ? 'light' : 'unset'

  let context = []

  if (styleContext && Array.isArray(styleContext)) {
    context = styleContext.filter((item) => {
      return item !== 'dark'
    })
  }

  const hasSource = !!components.find((item) => item.type === 'source_name')

  return (
    <Meta
      styleContext={context}
      theme={theme || themeColor}
      onlyOn={block.only_on}
      hasSource={hasSource}
    >
      {components.map((component) => {
        switch (component.type) {
          case 'datetime': {
            const format = ((component.format && toCamel(component.format)) ||
              'date') as TimestampProps['type']

            return (
              <MetaItem
                hasSource={hasSource}
                bullets
                key={component.id}
                type="datetime"
              >
                <Timestamp
                  publishedAt={component.datetime}
                  type={format || 'date'}
                  locale={lang}
                />
              </MetaItem>
            )
          }

          case 'string': {
            return (
              <MetaItem bullets key={component.id}>
                {component.text}
              </MetaItem>
            )
          }

          default: {
            return null
          }
        }
      })}
    </Meta>
  )
}
