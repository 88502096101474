import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BannerTypes, DFPCodes } from '@client/constants'
import { getIsMobile } from '@client/selectors/appSelectors'
import { CallToActions, StyleContext } from '@client/types'
import { LightboxContext } from '@common/Lightbox/types'
import { ToolbarContainer } from '@common/ToolbarContainer'
import { getLang } from '@selectors/currentUserSelectors'
import { callToAction } from '@store/App/appActions'
import { RawHtmlBlock } from '@storybook'
import { Block } from 'types/Blocks'

import { DFPBanner } from '../DFPBanner'

import { BeetSubscription } from './BeetSubscription'
import { EmailSubscription } from './EmailSubscription'

/*
 * Компонент, который рендерит блоки для материалов
 */

export interface RenderBlocksProps {
  block: Block
  url?: string
  isRead?: boolean
  isListened?: boolean
  isInBookmarks?: boolean
  styleContext: StyleContext
  lightBox?: LightboxContext | null
}

export const RenderBlocks: React.FC<RenderBlocksProps> = ({
  url,
  block,
  isRead,
  isListened,
  isInBookmarks,
  styleContext,
  lightBox
}) => {
  const dispatch = useDispatch()
  const lang = useSelector(getLang)

  const [isRendered, setIsRendered] = useState(false)
  const isMobile = useSelector(getIsMobile)

  useEffect(() => {
    setIsRendered(true)
  }, [])

  switch (block.type) {
    case 'share': {
      const buttons = block.data

      return (
        <ToolbarContainer
          url={url}
          place="bottom"
          buttons={buttons}
          styleContext={styleContext}
          label="Поделиться"
        />
      )
    }

    case 'banner': {
      if (isRendered) {
        const bannerId = isMobile
          ? DFPCodes.MOBILE_IN_CONTENT
          : DFPCodes.DESKTOP_LONGREAD_BANNER
        return (
          <div key={`${block.id}-${block.data.id}`}>
            <DFPBanner id={bannerId} type={BannerTypes.WITHIN} />
          </div>
        )
      }
      return <div key={`${block.id}-${block.data.id}`} />
    }

    case 'beet_subscription': {
      return <BeetSubscription />
    }

    case 'brief_subscription':
    case 'signal_subscription': {
      return <EmailSubscription styleContext={styleContext} type={block.type} />
    }

    default: {
      return (
        <RawHtmlBlock
          key={block.id}
          block={block}
          styleContext={styleContext}
          isRead={isRead}
          isListened={isListened}
          isInBookmarks={isInBookmarks}
          bookmarkAction={(service: CallToActions, place: string) =>
            dispatch(callToAction({ service, place }))
          }
          lightBox={lightBox}
          lang={lang}
        />
      )
    }
  }
}
