import React from 'react'

import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { QuoteBlockProps } from './QuoteBlock.types'

import styles from './QuoteBlock.module.css'

export const QuoteBlock: React.FC<QuoteBlockProps> = ({
  block: { data },
  styleContext
}) => {
  let classNames: ClassNames = [[styles.root, true]]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="quote-block" className={makeClassName(classNames)}>
      <div className={styles.container}>
        <p dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    </div>
  )
}
