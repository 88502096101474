import React from 'react'
import { useSelector } from 'react-redux'
import { Skeleton } from '@client/components/Common/Skeleton'
import { Lang, RootState } from '@client/types'
import { InformerContainer } from '@common/Informer'
import { getChronologyDocuments } from '@selectors/screenChronologySelectors'
import { getScreens } from '@selectors/screensSelectors'
import { getSearchDocuments } from '@selectors/searchSelectors'
import { Button } from '@storybook'
import { makeClassName } from '@utils/makeClassName'
import dayjs from 'dayjs'

import 'dayjs/locale/ru'

import { ChronologyItem } from './ChronologyItem'
import i18n from './i18n'

import styles from './Chronology.module.css'

/*
 * Лента хронологии для режима "по порядку"
 */

export interface ChronologyProps {
  lang: Lang
  screenName?: string
  header?: string
  styleContext: string
  fetchNext: () => void
  handleItemClick?: () => void
}

export const Chronology: React.FC<ChronologyProps> = ({
  lang,
  header,
  screenName,
  styleContext,
  fetchNext,
  handleItemClick
}) => {
  dayjs.locale(lang)

  const { informer } = useSelector(getScreens)

  const { hasNext, isFetching, items } = useSelector((state: RootState) =>
    screenName
      ? getChronologyDocuments(state, screenName)
      : getSearchDocuments(state)
  )

  const isToday = (timestamp) => {
    const today = dayjs().startOf('day')
    const dateToCheck = dayjs(timestamp)
    return dateToCheck.isSame(today, 'day')
  }

  return (
    <div
      className={makeClassName([
        [[styles[styleContext]], !!styleContext && styles[styleContext]],
        [[styles.empty], items.length < 1]
      ])}
    >
      {header && <div className={styles.header}>{header}</div>}

      <div className={styles.feed}>
        {items.length === 0 && <Skeleton styleContext="isInChronology" />}

        {items.map(({ timestamp, documents }, index) => (
          <section className={styles.container} key={timestamp}>
            <div className={styles.wrapper}>
              <h2 className={styles.timestamp}>
                {isToday(timestamp * 1000)
                  ? 'Сегодня'
                  : dayjs(timestamp * 1000).format(i18n[lang].dateFormat)}
              </h2>

              {/* Show informer in first container */}
              {index === 0 && informer && (
                <div className={styles.informer}>
                  <InformerContainer informer={informer} />
                </div>
              )}

              {documents.map((d) => (
                <div className={styles.item} key={d.url}>
                  <div className={styles.block}>
                    <ChronologyItem
                      handleItemClick={handleItemClick}
                      lang={lang}
                      document={d}
                    />
                  </div>
                </div>
              ))}
            </div>
          </section>
        ))}
      </div>

      {hasNext && items.length > 0 && (
        <div className={styles.footer}>
          <div className={styles.control}>
            <Button
              disabled={isFetching}
              size="default"
              theme="ghost"
              onClick={fetchNext}
            >
              {isFetching ? i18n[lang].loading : i18n[lang].more}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

Chronology.whyDidYouRender = true
