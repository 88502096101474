const POST_MESSAGE_PREFIX = 'mdzKitMsg'

const postMessage = (
  category: string,
  element: string,
  action: string
): void => {
  const target = window.top

  const msg = `${POST_MESSAGE_PREFIX}=${JSON.stringify({
    category,
    element,
    action
  })}`

  if (typeof target !== 'undefined') {
    target.postMessage(msg, '*')
  }
}

export default postMessage
