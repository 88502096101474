import React from 'react'

import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { MediaCaptionProps } from './MediaCaption.types'

import styles from './MediaCaption.module.css'

export const MediaCaption: React.FC<MediaCaptionProps> = ({
  credit,
  caption,
  styleContext
}) => {
  let classNames: ClassNames = [[styles.root, true]]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="media-caption" className={makeClassName(classNames)}>
      {caption && (
        <div
          className={styles.caption}
          dangerouslySetInnerHTML={{ __html: caption }}
        />
      )}
      {credit && (
        <div
          className={styles.credit}
          dangerouslySetInnerHTML={{ __html: credit }}
        />
      )}
    </div>
  )
}
