export const icons = {
  meduzaLogo: {
    width: 544,
    height: 115,
    content:
      '<path d="M59.1008614,49.65632 L59.1008614,106.01472 L84.9480411,114.24 L84.9480411,49.65632 L101.574882,49.65632 L101.574882,106.01472 L127.270909,114.24 L144.048902,91.392 L127.422062,91.392 L127.422062,35.7952 L101.726035,26.80832 L84.9480411,46.60992 L84.9480411,35.7952 L59.2520145,26.80832 L42.4740206,46.60992 L42.4740206,35.7952 L16.7779939,26.80832 L0,49.65632 L16.6268408,49.65632 L16.6268408,106.01472 L42.4740206,114.24 L42.4740206,49.65632 L59.1008614,49.65632 L59.1008614,49.65632 Z M336.61795,91.392 L336.61795,35.0336 L310.770769,26.80832 L310.770769,105.25312 L336.466796,114.24 L353.24479,94.4384 L353.24479,105.25312 L378.940817,114.24 L395.71881,91.392 L379.091969,91.392 L379.091969,35.0336 L353.24479,26.80832 L353.24479,91.392 L336.61795,91.392 L336.61795,91.392 Z M527.373159,38.08 L502.130592,26.80832 L472.202278,41.1264 L502.130592,52.39808 L461.016949,72.352 L461.016949,104.94848 L486.86413,114.24 L502.130592,94.28608 L502.130592,105.25312 L527.977771,114.24 L544,91.23968 L527.373159,91.23968 L527.373159,38.08 L527.373159,38.08 Z M177.453737,114.24 L207.079744,102.35904 L177.453737,91.23968 L177.453737,75.24608 L216.300083,56.3584 L194.231731,26.80832 L151.606557,47.37152 L151.606557,103.12064 L177.453737,114.24 L177.453737,114.24 Z M455.424284,89.56416 L423.984441,79.968 L453.459295,42.19264 L416.577937,26.80832 L397.230341,51.48416 L428.821339,61.23264 L398.741873,98.85568 L436.076687,114.24 L455.424284,89.56416 L455.424284,89.56416 Z M486.86413,91.392 L486.86413,68.84864 L502.130592,61.53728 L502.130592,91.392 L486.86413,91.392 L486.86413,91.392 Z M177.453737,41.58336 L190.452904,59.55712 L177.453737,65.80224 L177.453737,41.58336 L177.453737,41.58336 Z M222.95082,103.12064 L248.797999,114.24 L291.27202,97.18016 L291.27202,29.7024 L222.95082,0 L222.95082,26.30784 L265.424841,43.21536 L265.424841,97.33248 L248.797999,91.23968 L248.797999,43.71584 L222.95082,51.94112 L222.95082,103.12064 L222.95082,103.12064 Z" />'
  },

  menu: {
    width: 24,
    height: 24,
    content:
      '<path d="M2 21h20M2 12h20M2 3h20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" vector-effect="non-scaling-stroke" />'
  },

  fb: {
    width: 16,
    height: 18,
    content:
      '<path d="M15.117 1H.883A.883.883 0 0 0 0 1.883v14.234A.883.883 0 0 0 .883 17h7.663v-6.196H6.461V8.389h2.085V6.61a2.91 2.91 0 0 1 3.106-3.192c.622-.003 1.244.03 1.863.095v2.16h-1.279c-1.002 0-1.196.476-1.196 1.176v1.541h2.39l-.31 2.415h-2.08V17h4.077a.883.883 0 0 0 .883-.883V1.883A.883.883 0 0 0 15.117 1" />'
  },

  tw: {
    width: 20,
    height: 18,
    content:
      '<path d="M20 3.1c-.7.3-1.5.6-2.4.7.8-.5 1.5-1.4 1.8-2.4-.8.5-1.7.8-2.6 1-.7-.8-1.8-1.4-3-1.4-2.3 0-4.1 1.9-4.1 4.3 0 .3 0 .7.1 1-3.3-.1-6.4-1.8-8.4-4.4-.3.6-.5 1.4-.5 2.2 0 1.5.7 2.8 1.8 3.6-.7 0-1.3-.2-1.9-.5v.1c0 2.1 1.4 3.8 3.3 4.2-.3.1-.7.2-1.1.2-.3 0-.5 0-.8-.1.5 1.7 2 2.9 3.8 3-1.3 1-3.1 1.6-5 1.6-.3 0-.7 0-1-.1C1.8 17.3 4 18 6.3 18 13.8 18 18 11.5 18 5.8v-.6c.8-.5 1.5-1.2 2-2.1" fill-rule="nonzero"/>'
  },

  vk: {
    width: 20,
    height: 18,
    content:
      '<path d="M16.517 9.851s2.587-3.768 2.886-5.058c.1-.396-.1-.694-.498-.694h-2.288c-.498 0-.697.199-.896.595 0 0-1.194 2.678-2.686 4.364-.498.496-.697.694-.995.694-.2 0-.299-.198-.299-.694V4.793c0-.595-.1-.793-.597-.793H7.463c-.2 0-.398.198-.398.397 0 .595.796.694.796 2.28v3.174c0 .595 0 .893-.299.893-.796 0-2.686-2.777-3.681-5.851-.2-.595-.398-.794-.995-.794H.597C.299 4.1 0 4.298 0 4.694c0 .595.697 3.471 3.483 7.34C5.373 14.611 7.86 16 10.05 16c1.393 0 1.691-.198 1.691-.793v-1.984c0-.496.2-.694.498-.694s.895.1 2.189 1.388C15.92 15.405 16.02 16 16.915 16h2.587c.299 0 .498-.1.498-.595 0-.595-.796-1.587-1.89-2.876-.498-.595-1.195-1.29-1.493-1.686-.398-.397-.299-.595-.1-.992" fill-rule="nonzero"/>'
  },

  ok: {
    width: 9,
    height: 18,
    content:
      '<path d="M4.5 2c-2.2 0-4 1.8-4 4s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4m0 5.7c-.9 0-1.6-.8-1.6-1.7 0-.9.7-1.6 1.6-1.6.9 0 1.7.7 1.7 1.7 0 .8-.8 1.6-1.7 1.6" fill-rule="nonzero"/> <path d="M6.127 12.982a9.194 9.194 0 0 0 2.307-.904c.602-.301.703-1.004.402-1.507-.301-.502-1.104-.703-1.605-.401-1.605 1.004-3.812 1.004-5.417 0-.502-.302-1.304-.201-1.605.3-.401.604-.2 1.206.401 1.608a9.194 9.194 0 0 0 2.307.904L.61 15.092c-.502.401-.502 1.104 0 1.607a1.357 1.357 0 0 0 1.705 0l2.207-2.11L6.73 16.7a1.357 1.357 0 0 0 1.705 0c.502-.402.502-1.105 0-1.608l-2.307-2.109" fill-rule="nonzero"/>'
  },

  tg: {
    width: 18,
    height: 18,
    content:
      '<path d="M9 18c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9zm-1.5-7.4c1.1 1 2 3.3 2 3.3.4.2.7.1.8-.4l2.1-7.4c.1-.6-.2-.8-.6-.7L4 8.3c-.6.2-.6.5-.1.7 0 .1 2.4.6 3.6 1.6z" fill-rule="nonzero"/>'
  },

  meduzaSymbol: {
    width: 24,
    height: 18,
    content:
      '<path d="M10.026233,17.0968697 L13.9737671,18.3530831 L13.9737671,10.9855851 L13.9737671,8.48948164 L16.5131165,8.48948164 L16.5131165,17.0968697 L20.4375656,18.3530831 L23,14.8636014 L20.4606507,14.8636014 L20.4606507,6.37252945 L16.5362015,5 L13.9737671,8.02421743 L13.9737671,6.37252945 L10.049318,5 L7.48688354,8.02421743 L7.48688354,6.37252945 L3.56243443,5 L1,8.48948164 L3.53934943,8.48948164 L3.53934943,17.0968697 L7.48688354,18.3530831 L7.48688354,8.48948164 L10.026233,8.48948164 L10.026233,17.0968697 Z" />'
  },

  backward: {
    width: 25,
    height: 27,
    content:
      '<path d="M13 0v2.01c6.672.263 12 5.754 12 12.49C25 21.404 19.404 27 12.5 27S0 21.404 0 14.5c0-3.493 1.441-6.758 3.936-9.105a.75.75 0 111.028 1.092A10.965 10.965 0 001.5 14.5c0 6.075 4.925 11 11 11s11-4.925 11-11c0-5.907-4.656-10.727-10.499-10.989L13 6 8 3l5-3zm3.295 11.33v1.25h-3.16v1.63c.32-.32.86-.56 1.47-.56 1.13 0 2.14.81 2.14 2.16 0 1.42-1.06 2.31-2.67 2.31-1.16 0-2-.38-2.57-1l.79-.99c.45.47 1.07.73 1.77.73.79 0 1.24-.45 1.24-.99 0-.59-.44-.99-1.2-.99-.55 0-1 .16-1.4.54l-.99-.26v-3.83h4.58zm-5.94 0V18h-1.42v-4.84l-1.11 1.13-.81-.85 2.1-2.11h1.24z" fill-rule="nonzero"/>'
  },

  forward: {
    width: 25,
    height: 27,
    content:
      '<path d="M12 0l5 3-5 3V3.511C6.157 3.773 1.5 8.592 1.5 14.5c0 6.075 4.925 11 11 11s11-4.925 11-11c0-3.075-1.267-5.946-3.464-8.013a.75.75 0 011.028-1.092A12.465 12.465 0 0125 14.5C25 21.404 19.404 27 12.5 27S0 21.404 0 14.5C0 7.764 5.328 2.272 12 2.01V0zm4.295 11.33v1.25h-3.16v1.63c.32-.32.86-.56 1.47-.56 1.13 0 2.14.81 2.14 2.16 0 1.42-1.06 2.31-2.67 2.31-1.16 0-2-.38-2.57-1l.79-.99c.45.47 1.07.73 1.77.73.79 0 1.24-.45 1.24-.99 0-.59-.44-.99-1.2-.99-.55 0-1 .16-1.4.54l-.99-.26v-3.83h4.58zm-5.94 0V18h-1.42v-4.84l-1.11 1.13-.81-.85 2.1-2.11h1.24z" fill-rule="nonzero"/>'
  },

  download: {
    width: 25,
    height: 27,
    content:
      '<path d="M12.5 2C19.404 2 25 7.596 25 14.5S19.404 27 12.5 27 0 21.404 0 14.5 5.596 2 12.5 2zm0 1.5c-6.075 0-11 4.925-11 11s4.925 11 11 11 11-4.925 11-11-4.925-11-11-11zm6 15.75a.75.75 0 01.102 1.493l-.102.007h-12a.75.75 0 01-.102-1.493l.102-.007h12zm-6-12a.75.75 0 01.743.648L13.25 8v7.598l1.834-1.222a.75.75 0 11.832 1.248l-3 2a.75.75 0 01-.832 0l-3-2a.75.75 0 11.832-1.248l1.834 1.223V8a.75.75 0 01.648-.743l.102-.007z" fill-rule="nonzero" fill="currentColor" />'
  },

  play: {
    width: 62,
    height: 62,
    content:
      '<path d="M24.35 41.772a.5.5 0 0 1-.739-.439V20.667a.5.5 0 0 1 .74-.44l18.944 10.334a.5.5 0 0 1 0 .878L24.351 41.772z" fill-rule="nonzero"/>'
  },

  pause: {
    width: 22,
    height: 22,
    content:
      '<path d="M12.832 4h2.8c.276 0 .5.224.5.5v12.394c0 .277-.224.5-.5.5h-2.8c-.276 0-.5-.223-.5-.5V4.5c0-.276.224-.5.5-.5zM6.5 4h2.8c.277 0 .5.224.5.5v12.394c0 .277-.223.5-.5.5H6.5c-.276 0-.5-.223-.5-.5V4.5c0-.276.224-.5.5-.5z" />'
  },

  speedHalf: {
    width: 25,
    height: 27,
    content:
      '<path d="M7.212 15.198V12.29l-.676.724L6 12.406 7.35 11h.825v4.198h-.963zM8.056 18H7.3l4.23-7h.765l-4.24 7zm2.99 0v-.735c1.717-1.27 2.134-1.679 2.134-2.14 0-.389-.288-.578-.665-.578-.467 0-.884.263-1.083.525l-.496-.64c.417-.493 1.053-.693 1.599-.693.913 0 1.599.462 1.599 1.312 0 .682-.556 1.28-1.708 2.14h1.737V18h-3.118zm5.998-2.918l-1.39 1.48-.567-.577 1.4-1.49-1.4-1.48.566-.588 1.39 1.49 1.4-1.49.557.588-1.4 1.48 1.4 1.49-.556.577-1.4-1.48z" /><path d="M12.5 27C19.404 27 25 21.404 25 14.5S19.404 2 12.5 2 0 7.596 0 14.5 5.596 27 12.5 27zm0-1.5c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11z" />'
  },

  speedOne: {
    width: 25,
    height: 27,
    content:
      '<path d="M9.955 18v-4.84l-1.11 1.13-.81-.85 2.1-2.11h1.24V18h-1.42zm4.6-2.78l-1.4 1.41-.57-.55 1.41-1.42-1.41-1.41.57-.56 1.4 1.42 1.41-1.42.56.56-1.41 1.41 1.41 1.42-.56.55-1.41-1.41z" fill-rule="nonzero"/><path d="M12.5 27C19.404 27 25 21.404 25 14.5S19.404 2 12.5 2 0 7.596 0 14.5 5.596 27 12.5 27zm0-1.5c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11z" fill-rule="nonzero"/>'
  },

  speedOneHalf: {
    width: 25,
    height: 27,
    content:
      '<path d="M5.62 18v-4.84l-1.11 1.13-.81-.85 2.1-2.11h1.24V18H5.62zm3.37-2.67v-2.77l-.68.69-.54-.58 1.36-1.34h.83v4h-.97zM9.84 18h-.76l4.26-6.67h.77L9.84 18zm3.01 0v-.7c1.73-1.21 2.15-1.6 2.15-2.04 0-.37-.29-.55-.67-.55-.47 0-.89.25-1.09.5l-.5-.61c.42-.47 1.06-.66 1.61-.66.92 0 1.61.44 1.61 1.25 0 .65-.56 1.22-1.72 2.04h1.75V18h-3.14zm6.04-2.78l-1.4 1.41-.57-.55 1.41-1.42-1.41-1.41.57-.56 1.4 1.42 1.41-1.42.56.56-1.41 1.41 1.41 1.42-.56.55-1.41-1.41z" fill-rule="nonzero"/><path d="M12.5 27C19.404 27 25 21.404 25 14.5S19.404 2 12.5 2 0 7.596 0 14.5 5.596 27 12.5 27zm0-1.5c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11z" fill-rule="nonzero"/>'
  },

  speedTwo: {
    width: 25,
    height: 27,
    content:
      '<path d="M7.147 18v-1.158c2.685-2.078 3.471-2.792 3.471-3.64 0-.6-.52-.9-1.061-.9-.748 0-1.318.311-1.76.797L7 12.127C7.64 11.352 8.613 11 9.527 11c1.396 0 2.507.827 2.507 2.202 0 1.18-.924 2.203-2.625 3.506h2.675V18H7.147zm7.916-2.874l-1.377 1.457-.56-.568 1.386-1.468-1.386-1.458.56-.58 1.377 1.469 1.386-1.468.551.579-1.386 1.458L17 16.015l-.55.568-1.387-1.457z" fill-rule="nonzero"/><path d="M12.5 27C19.404 27 25 21.404 25 14.5S19.404 2 12.5 2 0 7.596 0 14.5 5.596 27 12.5 27zm0-1.5c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11z" fill-rule="nonzero"/>'
  },

  podcastBookmark: {
    width: 25,
    height: 27,
    content:
      '<path d="M12.5 27C19.404 27 25 21.404 25 14.5S19.404 2 12.5 2 0 7.596 0 14.5 5.596 27 12.5 27zm0-1.5c-6.075 0-11-4.925-11-11s4.925-11 11-11 11 4.925 11 11-4.925 11-11 11z" /> <path d="M17.071 8.25H8a.75.75 0 00-.75.75v11.545l.007.104a.75.75 0 001.228.468l4.05-3.437 4.051 3.437a.75.75 0 001.235-.572V9a.75.75 0 00-.75-.75zm-.75 1.499v9.176l-3.3-2.8-.098-.07a.75.75 0 00-.873.07l-3.3 2.799V9.749h7.571z" data-stroke="true" /> <path d="M17.071 8.25H8a.75.75 0 00-.75.75v11.545l.007.104a.75.75 0 001.228.468l4.05-3.437 4.051 3.437a.75.75 0 001.235-.572V9a.75.75 0 00-.75-.75z" data-fill="true" />'
  },

  reaction: {
    width: 16,
    height: 16,
    content:
      '<path data-theme="light" fill="#D09959" d="M1 2h14v12H1z"/> <path d="M0 1.778C0 .8.8 0 1.778 0h12.444C15.2 0 16 .8 16 1.778v12.444C16 15.2 15.2 16 14.222 16H1.778C.8 16 0 15.2 0 14.222V1.778zM6.8 11.7l2.5.8V6.2h1.6v5.5l2.5.8 1.6-2.3h-1.6V4.8L10.9 4 9.3 5.9V4.8L6.8 4 5.1 5.9V4.8L2.6 4 1 6.2h1.6v5.5l2.5.8V6.2h1.7v5.5z" />'
  },

  cross: {
    width: 20,
    height: 20,
    content:
      '<path d="m4.016 2.192-.061.007-.059.021c-.586.216-1.01.544-1.174 1.019-.16.464-.019.919.16 1.265.182.354.45.685.683.952.113.13.21.237.295.33.11.118.195.213.262.298C4.971 7.17 5.92 8.19 6.85 9.19c.369.397.735.79 1.092 1.185-.203.22-.407.438-.611.657-.84.902-1.826 1.644-2.857 2.404l-.17.126c-.968.713-1.971 1.452-2.857 2.337-.353.353-.501.783-.47 1.206.032.41.227.777.496 1.045.268.269.636.464 1.045.495.423.032.853-.116 1.206-.469.935-.935 1.979-1.742 3.056-2.574l.243-.188c1.004-.777 2.028-1.584 2.964-2.524 1.398 1.793 2.889 3.537 4.53 5.14.35.419.807.597 1.261.58.443-.018.852-.22 1.148-.495.292-.272.527-.668.527-1.115 0-.462-.25-.869-.699-1.145-1.67-1.631-3.163-3.44-4.614-5.3 1.914-2.107 3.848-4.163 5.923-6.092.508-.204.828-.578.929-1.037.101-.462-.036-.931-.278-1.29-.243-.361-.63-.668-1.109-.759-.5-.095-1.023.06-1.479.49a113.902 113.902 0 0 0-6.07 6.192c-.287-.313-.574-.623-.86-.933-1.204-1.303-2.394-2.59-3.466-3.98-.3-.389-.863-1.044-1.714-.954Z" stroke="currentColor" stroke-linecap="round"/>'
  },

  link: {
    width: 24,
    height: 24,
    content:
      '<path d="M9.158 18.928c-1.065 0-2.13-.405-2.94-1.215-1.624-1.623-1.624-4.262 0-5.884l.643-.645c.327-.326.854-.326 1.18 0 .325.326.325.853 0 1.178l-.645.645c-.972.972-.972 2.554 0 3.526.973.972 2.554.973 3.526 0l2.842-2.84c.97-.972.97-2.555 0-3.527-.325-.325-.325-.854 0-1.18.325-.324.853-.324 1.178 0 1.623 1.625 1.623 4.264 0 5.885l-2.84 2.84c-.81.81-1.877 1.216-2.942 1.216" /> <path d="M10.578 14.184c-.213 0-.426-.082-.59-.244-.784-.785-1.217-1.83-1.217-2.94 0-1.113.433-2.158 1.22-2.944l2.84-2.84c1.622-1.62 4.26-1.62 5.883 0 1.622 1.623 1.622 4.262 0 5.883l-.645.643c-.325.326-.852.326-1.178 0-.325-.325-.325-.852 0-1.178l.645-.644c.972-.97.972-2.554 0-3.526s-2.556-.972-3.527 0l-2.84 2.842c-.47.47-.73 1.096-.73 1.762 0 .667.26 1.293.73 1.763.325.327.325.854 0 1.18-.163.162-.376.244-.59.244" />'
  },

  search: {
    width: 16,
    height: 16,
    content:
      '<g transform="translate(2 1)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" vector-effect="non-scaling-stroke"><circle cx="5.5" cy="5.5" r="5.5"/><path d="M11.37 13.442l-.995-1.272"/></g>'
  },

  card: {
    width: 15,
    height: 15,
    content:
      '<path d="M9 5.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5H4a.5.5 0 0 1-.5-.5V6a.5.5 0 0 1 .5-.5h5zm-.5 1h-4v4h4v-4zm2.5-3a.5.5 0 0 1 .5.5v5a.5.5 0 1 1-1 0V4.5H6a.5.5 0 0 1 0-1h5z" fill-rule="nonzero"/>'
  },

  podcast: {
    width: 15,
    height: 15,
    content:
      '<path d="M5.252 11.432A.5.5 0 0 1 4.5 11V4a.5.5 0 0 1 .752-.432l6 3.5a.5.5 0 0 1 0 .864l-6 3.5zm.248-1.303L10.008 7.5 5.5 4.87v5.26z" fill-rule="nonzero" />'
  },

  arrow: {
    width: 12,
    height: 12,
    content:
      '<path d="M3 7l3 3.5L9 7M6 10V1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" vector-effect="non-scaling-stroke" />'
  },

  brent: {
    width: 9,
    height: 12,
    content:
      '<g fill="#999" fill-rule="nonzero"><path d="M4.332 0l.528.606c2.528 2.902 3.804 5.07 3.804 6.634A4.332 4.332 0 110 7.24C0 5.676 1.276 3.508 3.804.606L4.332 0zm0 2.143l-.176.212C2.313 4.589 1.4 6.248 1.4 7.24a2.932 2.932 0 105.864 0c0-.992-.914-2.651-2.756-4.885l-.176-.212z"/><path d="M2.973 7.475c0 .415.417.863.835.924l.097.008v.7c-.79 0-1.548-.716-1.626-1.5l-.006-.132h.7z"/></g> '
  },

  user: {
    width: 16,
    height: 16,
    content:
      '<path fill="currentColor" fill-rule="nonzero" d="M8 1a4.25 4.25 0 012.676 7.552 6.751 6.751 0 014.074 6.198.75.75 0 11-1.5 0 5.25 5.25 0 00-10.5 0 .75.75 0 11-1.5 0 6.752 6.752 0 014.074-6.199A4.25 4.25 0 018 1zm0 1.5A2.75 2.75 0 108 8a2.75 2.75 0 000-5.5z" />'
  },

  bookmark: {
    width: 17,
    height: 21,
    content:
      '<path d="M16 20l-7.5-6.333L1 20V1h15z" stroke="currentColor" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"/>'
  },

  shareBookmark: {
    width: 11,
    height: 15,
    content:
      '<path clip-rule="evenodd" d="M10 14 5.5 9.667 1 14V1h9v13Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>'
  },

  receipt: {
    width: 10,
    height: 13,
    content:
      '<g fill="currentColor" fill-rule="nonzero"><path d="M10 0H0v13l2-1.74 1.5 1.305L5 11.26l1.5 1.305L8 11.26 10 13V0zM9 .997v9.81l-1-.87-1.5 1.304L5 9.937 3.5 11.24 2 9.937l-1 .87V.996h8z"/><path d="M7 3v1H3V3zM7 6v1H3V6z"/></g>'
  },

  read: {
    width: 15,
    height: 15,
    content:
      '<g fill="none" fill-rule="evenodd"><path opacity=".98" d="M0 0h15v15H0z"/><path d="M13.085 1.694a.513.513 0 01.855.56l-.051.077-3.163 3.975a.514.514 0 01-.73.075l-.068-.069-1.812-2.208a.512.512 0 01.729-.716l.065.066 1.409 1.718 2.766-3.478z" fill="#6EAC65" fill-rule="nonzero"/><path d="M6.454 12.997l-.06-.01-.078-.023a.49.49 0 01-.266-.24l.011.015a2.349 2.349 0 00-.535-.607c-.89-.745-2.117-.917-3.813-.12a.5.5 0 01-.706-.37L1 11.558V4.568a.5.5 0 01.287-.453c2.053-.963 3.695-.733 4.88.26.327.272.556.548.698.77l.084.144.018.04.022.076.01.071L7 11.233l.126-.09c.95-.656 2.16-.857 3.608-.417l.266.087v-2.81a.5.5 0 01.41-.491l.09-.008a.5.5 0 01.492.41l.008.09v3.555a.5.5 0 01-.713.452c-1.696-.796-2.922-.624-3.813.12a2.533 2.533 0 00-.497.544l-.038.064a.486.486 0 01-.485.258zm-4.29-8.17L2 4.892v5.92c1.637-.572 2.977-.348 4 .42l.002-5.58a2.605 2.605 0 00-.476-.513c-.81-.677-1.897-.88-3.362-.314z" fill="currentColor" fill-rule="nonzero"/></g>'
  },
  listened: {
    width: 15,
    height: 15,
    content:
      '<g fill-rule="nonzero"><path d="M4.978 4.354a.5.5 0 01-.332.624c-1.197.367-2.064 1.527-2.14 2.897l-.005.167a.749.749 0 01.999.708v3a.75.75 0 01-1.458.248L2 12a1 1 0 01-1-1v-1a1 1 0 01.5-.866V8.072c0-1.886 1.172-3.535 2.854-4.05a.5.5 0 01.624.332zM10 7a.5.5 0 01.492.41l.008.09.001 1.634a1 1 0 01.492.745L11 10v1a1 1 0 01-.883.993L10 12l-.042-.002a.75.75 0 01-1.451-.146L8.5 11.75v-3a.75.75 0 011.001-.707L9.5 7.5l.008-.09A.5.5 0 0110 7z" fill="currentColor" /><path d="M11.585 1.194a.513.513 0 01.855.56l-.051.077-3.163 3.975a.514.514 0 01-.73.075l-.068-.069-1.812-2.208a.512.512 0 01.729-.716l.065.066 1.409 1.718 2.766-3.478z" fill="#6EAC65" /></g>'
  },
  magic: {
    width: 15,
    height: 15,
    content:
      '<g fill-rule="evenodd" clip-rule="evenodd"> <path d="M7.804.4a.6.6 0 0 1 .6.6v2.035a.6.6 0 0 1-1.2 0V1a.6.6 0 0 1 .6-.6ZM7.804 10.577a.6.6 0 0 1 .6.6v2.035a.6.6 0 1 1-1.2 0v-2.035a.6.6 0 0 1 .6-.6ZM14.636 7.106a.6.6 0 0 1-.6.6h-2.078a.6.6 0 1 1 0-1.2h2.078a.6.6 0 0 1 .6.6ZM4.25 7.106a.6.6 0 0 1-.6.6H1.573a.6.6 0 0 1 0-1.2H3.65a.6.6 0 0 1 .6.6ZM12.639 2.37a.585.585 0 0 1 0 .84l-1.442 1.412a.615.615 0 0 1-.857 0 .585.585 0 0 1 0-.84l1.442-1.412a.615.615 0 0 1 .857 0ZM12.639 11.843a.615.615 0 0 1-.857 0L10.34 10.43a.585.585 0 0 1 0-.84.615.615 0 0 1 .857 0l1.442 1.413a.585.585 0 0 1 0 .84ZM5.269 4.621a.615.615 0 0 1-.858 0L2.97 3.208a.585.585 0 0 1 0-.84.615.615 0 0 1 .858 0l1.442 1.413a.585.585 0 0 1 0 .84ZM8.926 6.074a.6.6 0 0 1 0 .849l-.863.863-.849-.849.863-.863a.6.6 0 0 1 .849 0ZM7.348 8.5l-5.924 5.924a.6.6 0 1 1-.848-.848L6.5 7.652l.848.848Z"/> </g>'
  }
}
