import React from 'react'
import { FootnoteProps } from './Footnote.types'
import makeClassName from '../utils/makeClassName'

import { SvgSymbol } from '../SvgSymbol'

import styles from './Footnote.module.css'

export const Footnote: React.FC<FootnoteProps> = ({ children, onClose }) => {
  return (
    <div
      data-testid="footnote"
      className={makeClassName([[styles.root, true]])}
    >
      <button
        className={styles.dismiss}
        type="button"
        onClick={(): void => onClose()}
      >
        <SvgSymbol icon="cross" size="unset" />
      </button>

      <div className={styles.body}>{children}</div>
    </div>
  )
}
