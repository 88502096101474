import React from 'react'
import { Helmet } from 'react-helmet-async'
import { MetaScreenTitles } from '@client/constants'
import { Lang, MaterialContent, Og } from '@client/types'

const getTitle = (material: MaterialContent) => {
  const { second_title: secondTitle, one_picture: onePicture } = material
  const { title } = material

  const SENTENCE_TERMINATORS = ['.', '?', '!']
  let materialTitle = title

  if (secondTitle && !onePicture) {
    materialTitle +=
      SENTENCE_TERMINATORS.indexOf(title.charAt(title.length - 1)) > -1
        ? ` ${secondTitle}`
        : `: ${secondTitle}`
  }

  return materialTitle
}

/*
 * Сложный и нуждающийся в додумывании универсальный компонент мета-данных для og-тегов и прочего
 */

interface MetaProps {
  material?: MaterialContent
  lang: Lang
  screen?: string
  og?: Og
}

export const Meta: React.FC<MetaProps> = ({ material, lang, og, screen }) => {
  const title = material
    ? `${material.og ? material.og.title : getTitle(material)}`
    : `${
        (og && og.title) || (screen && MetaScreenTitles[lang][screen.slice(1)])
      }`

  const hasOg = og && og.url

  const fallbackShare = 'https://schon.berlin/share.png'

  const isScreen = screen && screen === 'news'

  let shareImage = material
    ? material.og.image || fallbackShare
    : screen && og
    ? og && og.image
    : fallbackShare

  if (isScreen) {
    shareImage = fallbackShare
  }

  const canonicalUrl = screen
    ? hasOg
      ? hasOg
      : `https://schon.io/${screen === 'news' ? '' : screen}`
    : material && material.og.url

  return (
    <Helmet titleTemplate="%s">
      <html prefix="og:http://ogp.me/ns#" lang={lang === 'en' ? 'en' : 'ru'} />
      <title>{og ? og.title : title}</title>
      {screen && [
        <meta
          key="description"
          name="description"
          content={og && og.description ? og.description : 'Берлинское медиа'}
        />,
        <meta
          key="keywords"
          name="keywords"
          content={
            og && og.keywords
              ? og.keywords
              : 'берлин, шён, новости, город, политика, экономика, афиша, фото, репортаж, интервью'
          }
        />
      ]}
      {material && <title>{title}</title>}
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:site_name" content="Schön" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:locale" content="ru_RU" />
      {/* <meta property="fb:pages" content="120886387772311" /> */}
      <meta property="fb:app_id" content="1727008057808107" />
      <meta
        name="facebook-domain-verification"
        content="oy14jw4lf4va7ny1oby3u57hnhn03t"
      />
      <meta property="al:web:should_fallback" content="true" />
      {/* <meta name="twitter:site" content="@meduzaproject" /> */}
      <meta name="twitter:title" content={title} />

      {/* favicons */}
      <link rel="icon" href="/favicon.ico" sizes="32x32" />
      <link rel="icon" href="/icon.svg" type="image/svg+xml" />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />

      {material && [
        <meta key="og:type" property="og:type" content="article" />,
        <meta
          key="twitter:card"
          name="twitter:card"
          content={
            ['video'].indexOf(material.layout) === -1
              ? 'summary_large_image'
              : 'player'
          }
        />
      ]}
      {material && material.og && material.og.keywords && (
        <meta
          key="material-keywords"
          name="keywords"
          content={material.og.keywords}
        />
      )}
      {/* <link
        rel="alternate"
        type="application/rss+xml"
        title={title}
        href={`${lang === 'en' ? '/en/rss/all' : '/rss/all'}`}
      />
      <link
        key="alternate-rss-news"
        rel="alternate"
        type="application/rss+xml"
        title="Schon — Новости"
        href="/rss/news"
      /> */}
      {material &&
        material.og &&
        material.og.description && [
          <meta
            key="description"
            name="description"
            content={material.og.description}
          />,
          <meta
            key="og:description"
            property="og:description"
            content={material.og.description}
          />,
          <meta
            key="twitter:description"
            name="twitter:description"
            content={material.og.description}
          />
        ]}
      {shareImage && [
        <link key="image_src" rel="image_src" href={shareImage} />,
        <meta key="og:image" property="og:image" content={shareImage} />,
        <meta key="og:image:width" property="og:image:width" content="1200" />,
        <meta key="og:image:height" property="og:image:height" content="630" />,
        <meta key="twitter:image" name="twitter:image" content={shareImage} />
      ]}
    </Helmet>
  )
}
