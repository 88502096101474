import { ScreenViewType, utmSource } from '@client/constants'
import { getApp } from '@selectors/appSelectors'
import { getLastOpenedMaterial } from '@selectors/currentUserSelectors'
import {
  trackBannerBlock,
  trackMaterialScroll,
  trackUiKitAction
} from '@store/Analytics/analyticsActions'
import { callToAction, changeScreenView, pageView } from '@store/App/appActions'
import { AppState } from '@store/App/appReducer'
import {
  informerBecomeVisible,
  informerClicked,
  topbarAdBecomeVisible,
  topbarAdClicked
} from '@store/Materials/materialsActions'
import { Analytics } from '@utils/Analytics'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

/*
Отправляет аналитику после нажатия на кнопку шера в материале
*/

function* callToActionSaga({
  payload: { service, place }
}: ReturnType<typeof callToAction>) {
  const lastOpenedMaterialUrl: string | null = yield select(
    getLastOpenedMaterial
  )
  if (utmSource.hasOwnProperty(service) && lastOpenedMaterialUrl)
    yield call(Analytics.share, service, place, lastOpenedMaterialUrl)
}

/*
 Отправляет аналитику после изменения режима "по порядку" на экране
 */

function* changeViewTypeSaga({
  payload: { screenViewType, restore }
}: ReturnType<typeof changeScreenView>) {
  if (!restore) {
    yield call(Analytics.switchView, screenViewType === ScreenViewType.LIST)
  }
}

/*
 Отправляет аналитику после того, как рекламный блок экрана появится во вьюпорте
 */

function* trackBannerBlockSaga({
  payload: { origin, document }
}: ReturnType<typeof trackBannerBlock>) {
  yield call(
    Analytics.bannerShow,
    origin === 'under-the-sun' ? 'screen-below-material' : origin,
    document.url
  )
}

/*
 Отправляет аналитику во время скролла материала
 */

function* trackMaterialScrollSaga({
  payload: { url, percentage }
}: ReturnType<typeof trackMaterialScroll>) {
  yield call(Analytics.materialScrolled, url, percentage)
}

/*
 Отправляет аналитику после того, как становится видна плашка над материалом после скролла
 */

function* topbarAdBecomeVisibleSaga({
  payload
}: ReturnType<typeof topbarAdBecomeVisible>) {
  yield call(Analytics.topbarAdVisible, {
    url: payload,
    type: 'scrollingInformer'
  })
}

/*
 Отправляет аналитику на клик на плашку над материалом
 */

function* topbarAdClickedSaga({ payload }: ReturnType<typeof topbarAdClicked>) {
  yield call(Analytics.topbarAdClick, {
    url: payload,
    type: 'scrollingInformer'
  })
}

/*
 Отправляет аналитику когда становится видно иноформер
 */

function* informerBecomeVisibleSaga({
  payload
}: ReturnType<typeof informerBecomeVisible>) {
  yield call(Analytics.informerBecomeVisible, {
    url: payload,
    type: 'feedInformer'
  })
}

/*
 Отправляет аналитику после клика на информер
 */

function* informerClickedSaga({ payload }: ReturnType<typeof informerClicked>) {
  yield call(Analytics.informerClicked, {
    url: payload,
    type: 'scrollingInformer'
  })
}

/*
 Отправляет аналитику на pageview в GA и апи
 */

function* pageViewSaga({
  payload: { pathname, title, tag }
}: ReturnType<typeof pageView>) {
  yield call(Analytics.pageView, {
    pathname,
    title,
    referrer: window.document.referrer,
    tagName: tag
  })
}

/*
 Отправляет аналитику из ui-kit (получает данные об этом из postMessage через useBridge
 */

function* trackUiKitSaga({ payload }: ReturnType<typeof trackUiKitAction>) {
  yield call(Analytics.uiKitAction, payload)
}

export default function* appSaga() {
  const { isPreview }: AppState = yield select(getApp)
  if (!isPreview) {
    yield all([
      takeEvery(callToAction.type, callToActionSaga),
      takeEvery(changeScreenView.type, changeViewTypeSaga),
      takeEvery(trackBannerBlock.type, trackBannerBlockSaga),
      takeEvery(trackMaterialScroll.type, trackMaterialScrollSaga),
      takeEvery(topbarAdBecomeVisible.type, topbarAdBecomeVisibleSaga),
      takeEvery(topbarAdClicked.type, topbarAdClickedSaga),
      takeEvery(informerBecomeVisible.type, informerBecomeVisibleSaga),
      takeEvery(informerClicked.type, informerClickedSaga),
      takeEvery(pageView.type, pageViewSaga),
      takeEvery(trackUiKitAction.type, trackUiKitSaga)
    ])
  }
}
