import React from 'react'
import { Link } from 'react-router-dom'

import { Image } from '../Image'
import { RenderBlocks } from '../RenderBlocks'
import makeClassName, { ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { RelatedBookProps } from './RelatedBook.types'

import styles from './RelatedBook.module.css'

export const RelatedBook: React.FC<RelatedBookProps> = ({
  block,
  styleContext
}) => {
  const theme = block.data.theme.text_color === '0,0,0' ? 'isDark' : 'isLight'

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[theme], theme],
    [
      styles[block.data.onlyOn],
      !!block.data.onlyOn && !!styles[block.data.onlyOn]
    ]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  const cssProps = {
    '--bgColor': `rgb(${block.data.theme.background_color || '0, 0, 0'})`
  } as React.CSSProperties

  return (
    <Link
      to={`/${block.data.url}`}
      className={makeClassName(classNames)}
      style={cssProps}
    >
      <div className={styles.container}>
        <div className={styles.imageWrap}>
          <div className={styles.image}>
            <picture className={styles.picture}>
              <Image
                optimized={block.data.cover.urls}
                ratio={0}
                display="narrow"
                styleContext={['isStatic']}
                lazy={true}
              />
            </picture>
          </div>
        </div>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            {block.data.blocks.map((item) => (
              <RenderBlocks
                key={item.id}
                block={item}
                styleContext={['isInMediaBlock', 'isInBookRelated', 'isCustom']}
              />
            ))}
          </div>
        </div>
      </div>
    </Link>
  )
}
