import React from 'react'
import { Link } from 'react-router-dom'
import { ImageUrls, RichScreenDocument } from '@client/types'
import { mapDesktopPosition } from '@client/utils/mapDesktopPosition'
import { mapMobilePosition } from '@client/utils/mapMobilePosition'
import { BlockMetaContainer } from '@routes/Screen/BlockMetaContainer'
import { BlockTitle } from '@routes/Screen/BlockTitle'
import { makeClassName } from '@utils/makeClassName'

import styles from './RichBlockText.module.css'

interface RichBlockProps {
  block: RichScreenDocument
  correlation: keyof ImageUrls
  origin: string
}

export const RichBlockText: React.FC<RichBlockProps> = ({
  block,
  correlation
}) => {
  const appearance = block.appearance.text

  const mobilePosition = mapMobilePosition(appearance.position)
  const isFeatured = appearance.title_size === 'large'

  const style = {
    '--title-color': appearance.title_color
  } as React.CSSProperties

  return (
    <article
      className={makeClassName([
        [styles.root, true],
        [styles[mobilePosition], !!mobilePosition],
        [styles[correlation], !!correlation && !!styles[correlation]]
      ])}
      style={style}
    >
      <Link className={styles.link} to={`/${block.url}`}>
        <div className={styles.contentWrap}>
          <div className={styles.content}>
            <div className={styles.title}>
              <BlockTitle
                block={block}
                correlation={correlation}
                featured={isFeatured}
                styleContext={`isInRichText`}
              />
            </div>

            <div className={styles.meta}>
              <BlockMetaContainer block={block} />
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}
