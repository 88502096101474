import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getCurrentUser } from '@client/selectors/currentUserSelectors'
import { hideModal } from '@store/Modal/modalActions'
import { Button } from '@storybook'

import { IconSuccessLogin } from '../Icons/IconSuccessLogin'

import styles from './Modal.module.css'

interface PostAuthModalProps {
  coordinates: number
}

export const PostAuthModal: React.FC<PostAuthModalProps> = ({
  coordinates
}) => {
  const [isRendered, setIsRendered] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const { account } = useSelector(getCurrentUser)

  const handleSubscribe = () => {
    history.push('/membership')

    window.scrollTo(0, coordinates)
    dispatch(hideModal())
  }

  const handleClick = () => {
    window.scrollTo(0, coordinates)
    dispatch(hideModal())
  }

  useEffect(() => {
    if (!isRendered) {
      history.push('/account')
      setIsRendered(true)
    }
  }, [isRendered, history])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.iconLogin}>
            <IconSuccessLogin />
          </div>
          <h3>
            Все получилось!
            <br />
            Вы в личном кабинете
          </h3>
          <div className={styles.subtitle}>
            Тут вы можете управлять своей подпиской.
            <br />А еще мы скоро добавим разные удобные функции вроде закладок.
          </div>
        </div>
      </div>

      <div className={styles.group}>
        {!account?.is_access_granted && (
          <Button theme="black" onClick={handleSubscribe}>
            Подписаться на schön
          </Button>
        )}
        <Button theme="gray" onClick={handleClick}>
          Продолжить
        </Button>
      </div>
    </div>
  )
}
