/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MembershipPlans } from '@client/components/Modules/Membership/MembershipPlans'
import { getCurrentUser } from '@selectors/currentUserSelectors'

import {
  IconMembershipLogoDesktop,
  IconMembershipLogoMobile
} from '../../Common/Icons/IconMembershipLogo'

import { MembershipFaq } from './MembershipFaq'
import { MembershipFeatures } from './MembershipFeatures'
import { MembershipHero } from './MembershipHero'
import { MembershipNav } from './MembershipNav'
import { MembershipTeam } from './MembershipTeam'

import styles from './Membership.module.css'

export const Membership = ({ plans }: any) => {
  const { firebase_user, account } = useSelector(getCurrentUser)

  const history = useHistory()

  useEffect(() => {
    if (!firebase_user) {
      history.push('/feature/2024/06/13/izdanie-schon-zakryvaetsya')
    }
  }, [history, firebase_user])

  return (
    <div className={styles.root}>
      <header className={styles.header}>
        <MembershipNav firebaseUser={firebase_user} />

        <div className={styles.heading}>
          <div className={styles.headerDesktop}>
            <IconMembershipLogoDesktop />
          </div>
          <div className={styles.headerMobile}>
            <IconMembershipLogoMobile />
          </div>
        </div>
      </header>
      <section className={styles.hero}>
        <MembershipHero />
      </section>

      <section className={styles.plans}>
        <h2 className={styles.title}>
          Подписка&nbsp;&mdash; это&nbsp;ваш способ сказать нам, что
          мы&nbsp;нужны
        </h2>

        <div className={styles.variants}>
          <MembershipPlans
            plans={plans}
            firebaseUser={firebase_user}
            account={account}
          />
        </div>
      </section>

      <section className={styles.features}>
        <MembershipFeatures />
      </section>
      <section className={styles.team}>
        <MembershipTeam />
      </section>
      <section className={styles.faq}>
        <MembershipFaq />
      </section>

      <section className={styles.contacts}>
        <h3>Связаться с нами</h3>
        <p>
          <a href="mailto:red@schon.berlin">red@schon.berlin</a>
        </p>
      </section>
    </div>
  )
}
