import React from 'react'
import { IconExternal } from '@client/components/Common/Icons/IconExternal'
import { Button } from '@client/storybook'

import styles from './Account.module.css'

export const AccountManagment = () => {
  const handleCheckoutClick = async () => {
    const token = await window.firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((token) => token)

    const portal = await fetch('/api/paywall/customer_portal', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`
      }
    }).then((res) => res.json())

    if (!portal?.customer_portal_url) {
      return null
    }

    return window.location.replace(portal.customer_portal_url)
  }

  return (
    <div className={styles.control}>
      <Button onClick={handleCheckoutClick} theme="simple">
        Управлять подпиской
        <span className={styles.external}>
          <IconExternal />
        </span>
      </Button>
      <p className={styles.description}>
        На платежном сервисе Stripe можно приостановить или возобновить подписку
      </p>
    </div>
  )
}

AccountManagment.whyDidYouRender = true
