import { ClassNames } from './makeClassName'

const makeStyleContext = (
  classNames: ClassNames,
  styleContext: string[] | string,
  styles: Record<string, string>
): ClassNames => {
  const context = Array.isArray(styleContext)
    ? styleContext
        .filter((ctx) => styles[ctx])
        .map((ctx) => [[styles[ctx]], true])
    : [[styles[styleContext], !!styleContext && !!styles[styleContext]]]

  return classNames.concat(context)
}

export default makeStyleContext
