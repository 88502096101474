import React from 'react'
import { MetaProps } from './Meta.types'
import makeStyleContext from '../utils/makeStyleContext'
import { makeClassName, ClassNames } from '../utils/makeClassName'

import styles from './Meta.module.css'

export const Meta: React.FC<MetaProps> = ({
  hasSource,
  theme,
  children,
  onlyOn,
  styleContext
}) => {
  let classNames: ClassNames = [
    [styles.root, true],
    [styles[theme], !!theme && !!styles[theme]],
    [styles[onlyOn], !!onlyOn],
    [styles.hasSource, hasSource]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="meta" className={makeClassName(classNames)}>
      {children}
    </div>
  )
}
