import React from 'react'

import { SocialLabels } from '../constants'
import { SvgSymbol } from '../SvgSymbol/'
import { ClassNames, makeClassName } from '../utils/makeClassName'

import { ToolbarButtonProps } from './ToolbarButton.types'

import styles from './ToolbarButton.module.css'

export const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  type,
  theme,
  isActive,
  lang = 'ru',
  text,
  styleContext,
  icon,
  onClick
}) => {
  const classNames: ClassNames = [
    [styles.root, true],
    [styles[type], !!styles[type]],
    [styles[theme], !!styles[theme] && !!theme],
    [styles.isActive, !!isActive]
  ].concat(
    Array.isArray(styleContext)
      ? styleContext.map((ctx) => [styles[ctx], !!ctx && styles[ctx]])
      : [[styles[styleContext], !!styleContext && !!styles[styleContext]]]
  )

  const label = SocialLabels[lang][type]

  return (
    <button
      data-testid="toolbar-button"
      className={makeClassName(classNames)}
      type="button"
      onClick={(event): void => onClick(event, type)}
    >
      {icon && (
        <div className={styles.icon}>
          <SvgSymbol size="medium" icon={icon} styleContext="isInToolbar" />
        </div>
      )}
      <span className={styles.text}>{text || label}</span>
    </button>
  )
}
