import React, { useState, useEffect, useRef } from 'react'
import viewportSize from '../utils/viewportSize'
import { MediaQuerySizes } from '../constants'
import { EmbedGifProps } from './EmbedBlock.types'

import styles from './EmbedBlock.module.css'

export const EmbedGif: React.FC<EmbedGifProps> = ({ gif, mp4Url, style }) => {
  const [videoFrozen, setVideoFrozen] = useState(false)
  const videoElem = useRef(null)

  const handleVideoTouch = (): void => {
    videoElem.current.play()
    setVideoFrozen(false)
  }

  const handleVideoError = (): void => {
    const video = videoElem.current

    video.setAttribute('poster', video.dataset.gif)

    setVideoFrozen(false)
  }

  useEffect(() => {
    const video = videoElem.current
    const { width: viewportWidth } = viewportSize()

    if (viewportWidth >= MediaQuerySizes.MOBILE) {
      video.setAttribute('autoplay', true)
      video.play()
    } else {
      setVideoFrozen(true)
      video.pause()
      video.addEventListener('touchstart', handleVideoTouch)
    }

    video.addEventListener('error', handleVideoError)

    return (): void => {
      video.removeEventListener('touchstart', handleVideoTouch)
      video.removeEventListener('error', handleVideoError)
    }
  }, [])

  return (
    <div className={styles.figure}>
      <div
        className={styles.objectWrap}
        style={style}
        data-frozen={videoFrozen}
      >
        <video
          playsInline
          muted
          loop
          autoPlay
          preload="metadata"
          data-gif={gif}
          ref={videoElem}
        >
          <source src={`https://meduza.io${mp4Url}`} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}
