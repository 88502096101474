import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConfigComponentProps } from 'react-router-config'
import { ScreenViewType } from '@client/constants'
import {
  Lang,
  PaginationElement,
  RootState,
  ScreenContent,
  ScreenNames
} from '@client/types'
import { Skeleton } from '@common/Skeleton'
import { Layout } from '@components/Layout/Layout'
import { Meta } from '@components/Layout/Meta'
import { usePageViewAnalytics } from '@hooks/usePageViewAnalytics'
import { Chronology } from '@routes/Screen/Chronology'
import { Feed } from '@routes/Screen/Feed'
import { Grid } from '@routes/Screen/Grid'
import { getAppScreenViewType, getIsMobile } from '@selectors/appSelectors'
import { getScreenChronology } from '@selectors/screenChronologySelectors'
import { getScreen } from '@selectors/screensSelectors'
import { changeScreenView } from '@store/App/appActions'
import { fetchChrono, fetchScreen } from '@store/Screens/screensActions'
import { localStorageService } from '@utils/localStorageService'
import equal from 'fast-deep-equal'

interface ScreenParams {
  lang: Lang
  screenName: ScreenNames
}

export const Screen: React.FC<RouteConfigComponentProps<ScreenParams>> = ({
  location: { pathname },
  route,
  match: {
    params: { lang = 'ru', screenName = 'news' }
  }
}) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)
  const appScreenViewType = useSelector(getAppScreenViewType)
  const chronologyData: PaginationElement = useSelector(
    (state: RootState) => getScreenChronology(state, screenName),
    equal
  )
  const screenData: ScreenContent | undefined = useSelector(
    (state: RootState) => getScreen(state, screenName),
    equal
  )

  const [screenViewType, setScreenViewType] = useState<string>(
    ScreenViewType.GRID
  )

  usePageViewAnalytics({ pathname, title: screenData && screenData.og.title })

  useEffect(() => {
    const localStorageScreenViewType: string =
      localStorageService.get('screenViewType') || ScreenViewType.GRID

    if (localStorageScreenViewType !== appScreenViewType) {
      dispatch(
        changeScreenView({
          screenViewType: localStorageScreenViewType as ScreenViewType
        })
      )
      setScreenViewType(localStorageScreenViewType)
    }
  }, [dispatch, appScreenViewType])

  useEffect(() => {
    setScreenViewType(appScreenViewType)
  }, [appScreenViewType])

  useEffect(() => {
    dispatch(fetchScreen.request({ name: screenName, lang }))
    dispatch(
      fetchChrono.request({
        screenName: screenName,
        page: 0,
        lang,
        loadPage: true
      })
    )
  }, [dispatch, screenName, lang])

  const handleFetchNextChrono = () => {
    if (chronologyData) {
      dispatch(
        fetchChrono.request({
          screenName: screenName,
          page: chronologyData.page,
          lang,
          loadPage: false
        })
      )
    }
  }

  const renderBlocks = () => {
    if (screenData) {
      if (isMobile) {
        return (
          <>
            <Feed key="feed" blocks={screenData.mobile} />
            <Skeleton desktopOnly={true} />
          </>
        )
      }

      if (!isMobile) {
        return <Grid containers={screenData.desktop} />
      }
    }

    return <Skeleton />
  }

  return (
    <Layout pathname={pathname} routeName={route && route.name} type="screen">
      <>
        <Meta
          screen={screenName}
          lang={lang}
          og={screenData && screenData.og}
        />
        {screenViewType === ScreenViewType.GRID || lang === 'en' ? (
          renderBlocks()
        ) : chronologyData ? (
          <Chronology
            lang={lang}
            screenName={screenName}
            fetchNext={handleFetchNextChrono}
            styleContext="isInChronology"
          />
        ) : (
          <div />
        )}
      </>
    </Layout>
  )
}

Screen.whyDidYouRender = true
