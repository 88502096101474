import React from 'react'

import { makeClassName } from '../utils/makeClassName'

import { MetaItemProps } from './MetaItem.types'

import styles from './MetaItem.module.css'

export const MetaItem: React.FC<MetaItemProps> = ({
  hasSource,
  type,
  children
}) => {
  const classNames = [
    [styles.root, true],
    [styles[type], !!type && !!styles[type]],
    [styles.hasSource, !!hasSource]
  ]

  return (
    <div data-testid="meta-item" className={makeClassName(classNames)}>
      {children}
    </div>
  )
}
