import React from 'react'

import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { ToolbarProps } from './Toolbar.types'

import styles from './Toolbar.module.css'

export const Toolbar: React.FC<ToolbarProps> = ({
  children,
  styleContext,
  label
}) => {
  let classNames: ClassNames = [[styles.root, true]]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <div data-testid="toolbar" className={makeClassName(classNames)}>
      {label && <div className={styles.label}>{label}</div>}
      <ul className={styles.list}>{children}</ul>
    </div>
  )
}
