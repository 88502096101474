import React, { useCallback, useEffect, useRef, useState } from 'react'

type DangerousHtmlProps = {
  className?: string
  html: string
  tagName?: keyof JSX.IntrinsicElements
}

const DangerousHTML: React.FC<DangerousHtmlProps> = ({
  className = '',
  tagName: TagName = 'div',
  html
}) => {
  const element = useRef<HTMLDivElement>(null)
  const [canRenderHtml, setCanRenderHtml] = useState(false)

  useEffect(() => {
    setCanRenderHtml(true)
  }, [])

  const replaceScriptElementsRecursive = useCallback((node) => {
    if (
      node.tagName === 'SCRIPT' &&
      (!node.id || node.id.indexOf('script-') !== 0)
    ) {
      const script = document.createElement('script')

      script.id = `script-${Math.random().toString(16).slice(2)}`
      script.innerHTML = node.innerHTML

      const attrs = node.attributes

      for (let i = 0; i < attrs.length; i += 1) {
        const { name, value } = attrs[i]

        script.setAttribute(name, value)
      }

      node.parentElement.replaceChild(script, node)
    } else {
      let i = 0
      const children = node.childNodes

      while (i < children.length) {
        replaceScriptElementsRecursive(children[i])
        i += 1
      }
    }
  }, [])

  const prepareAnchors = useCallback(() => {
    const anchors = Array.from(element.current.querySelectorAll('a'))

    anchors.forEach((a) => {
      a.setAttribute('target', '_blank')
    })
  }, [])

  useEffect(() => {
    if (
      canRenderHtml &&
      html.indexOf('script') > -1 &&
      element.current.firstElementChild
    ) {
      element.current.firstElementChild.innerHTML = html
      replaceScriptElementsRecursive(element.current.firstElementChild)
      prepareAnchors()
    }
  }, [
    canRenderHtml,
    element,
    html,
    replaceScriptElementsRecursive,
    prepareAnchors
  ])

  return (
    <div ref={element}>
      <TagName
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  )
}

export default DangerousHTML
