/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useDispatch } from 'react-redux'
import { ToolbarButtonTypes } from '@client/types'
import { StyleContext } from '@client/types'
import { callToAction } from '@store/App/appActions'
import { Toolbar, ToolbarButton, ToolbarItem } from '@storybook'

/*
 * Тулбар с кнопками шеров и кнопкой закладки, который появляется при
 * скролле материала сверху (в TopBar)
 * и есть внизу каждого материала
 */

export type ShareButtonsType = {
  type: ToolbarButtonTypes
  label?: string
}

export interface ToolbarContainerProps {
  url?: string
  place: string
  buttons: {
    type: ToolbarButtonTypes
    label?: string
  }[]
  lang: 'ru' | 'en'
  label: string | null
  pdf?: string
  styleContext?: StyleContext
}

export const ToolbarContainerButtons: React.FC<ToolbarContainerProps> = ({
  buttons,
  lang,
  label = null,
  styleContext,
  place
}) => {
  const dispatch = useDispatch()

  const isDark = styleContext && styleContext.indexOf('dark') > -1

  const theme = isDark ? 'dark' : 'unset'

  return (
    <>
      <Toolbar styleContext={styleContext} label={label}>
        {buttons.map((button) => {
          switch (button.type) {
            default: {
              return (
                <ToolbarItem key={button.type}>
                  <ToolbarButton
                    type={button.type}
                    lang={lang}
                    styleContext={styleContext}
                    onClick={() =>
                      dispatch(
                        callToAction({
                          place,
                          service: button.type
                        })
                      )
                    }
                    theme={theme}
                  />
                </ToolbarItem>
              )
            }
          }
        })}
      </Toolbar>
    </>
  )
}
