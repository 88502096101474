export const PaywallBannerScheme = {
  free: {
    0: {
      aside: null,
      message: {
        panel: 'Это ваша бесплатная статья',
        title:
          'Эта статья все еще бесплатна для&nbsp;вас. Не&nbsp;беспокойтесь, мы не&nbsp;учитываем ее&nbsp;повторное прочтение',
        text:
          'Но&nbsp;чтобы прочитать все другие материалы без надоедливых желтых плашек, пожалуйста, подпишитесь на&nbsp;Sch&ouml;n'
      }
    },
    1: {
      aside: {
        counter: '❸',
        title: 'Бесплатных статьи'
      },
      message: {
        panel: 'Осталось 3 бесплатных статьи',
        title: 'Спасибо, что читаете Schön!',
        text:
          'Три статьи можно прочитать бесплатно. А&nbsp;потом нужно оформить подписку. Это недорого&nbsp;&mdash; примерно как тарелка супа фо&nbsp;в&nbsp;хорошей вьетнамской забегаловке.'
      }
    },
    2: {
      aside: {
        counter: '❷',
        title: 'Бесплатных статьи'
      },
      message: {
        panel: 'Осталось 2 бесплатных статьи',

        title: 'Здорово, что вам нравится Schön!',
        text:
          'Три статьи можно прочитать бесплатно&nbsp;&mdash; кстати, это уже вторая! Потом нужно оформить подписку. Это недорого&nbsp;&mdash; примерно как один билет в&nbsp;кино.'
      }
    },
    3: {
      aside: {
        counter: '①',
        title: 'Бесплатная статья'
      },
      message: {
        panel: 'Осталась 1 бесплатная статья',
        title:
          'Увы, это последняя бесплатная статья! Нам не&nbsp;хочется с&nbsp;вами расставаться',
        text:
          'Пожалуйста, подпишитесь на&nbsp;Sch&ouml;n. Это недорого&nbsp;&mdash; примерно как два вкусных денера с&nbsp;халуми'
      }
    }
  },
  locked: {
    images: [
      'https://impro.schon.berlin/MzkOGj1DiRNB8CORIYaClgCt6HAg8GWmBuBszRUcxgI/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDEz/Lzk5NS9zbWFsbC9i/Slhra2M2aVRxYUNE/UkdvM1BwcHdBLmpw/Zw.webp',
      'https://impro.schon.berlin/NRPz1tyXOfQrZP2hljz_S2QuKzYyswdRR9l22X0wWiA/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzA1Ni9zbWFsbC9V/Rm5TYWg1OC1GUUJE/MzBZck1SUnRRLmpw/Zw.webp',
      'https://impro.schon.berlin/W9SNIJ_aKUNNeQvyqJ8kstS9wM9bKzpsDU6tZVrMzQg/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAwNS9zbWFsbC9r/MnpESXVDWEZEY1h4/Y1lEaGhPUGJ3Lmpw/Zw.webp',
      'https://impro.schon.berlin/pbUja5ANRLHX4XOmlAFdRec6_Lv17_OI7IInGUfM26Y/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAxMC9zbWFsbC9l/MmdTRUhRcmxqbXRC/aHh0OVdaWmR3Lmpw/Zw.webp',
      'https://impro.schon.berlin/4WceCoOWe0L7YJbsLCnUL1YK1GEmJkoY1bt9_KOF0KY/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAxNS9zbWFsbC85/bGZuMnFPM0NaS3RF/RVVteE91aGdnLmpw/Zw.webp',
      'https://impro.schon.berlin/iZ6K7NVWFk7l7NGOxvJx6Xc9Vmj9uV_cYgR7wQwyX8U/resizing_type:fit/width:0/height:0/enlarge:1/quality:100/aHR0cHM6Ly9hc3Nl/dHMuc2Nob24uYmVy/bGluL2ltYWdlL2F0/dGFjaG1lbnRzL2lt/YWdlcy8wMDAvMDE0/LzAyMC9zbWFsbC9W/d1lnemNpVDgyV0dV/anF6VGZGXzFRLmpw/Zw.webp'
    ],
    message: {
      title: 'Ой, бесплатные статьи закончились. Может, стоит подписаться?',
      text:
        'Наша редакция живет только благодаря читателям. Подпишитесь, это недорого — как коктейль в неплохом баре. Кроме того, подписка — это пропуск в наше сообщество. То есть не только бесплатные статьи, но еще и закрытые встречи для тех, кто в клубе, а также другие приятные мелочи.'
    }
  }
}
