import { RouteConfig } from 'react-router-config'
import { LoadableMembershipContainer } from '@client/components/Routes/MembershipContainer'
import { LoadableAccount } from '@routes/Account'
import { LoadableMaterial } from '@routes/Material'
import { LoadableScreen } from '@routes/Screen'
import { LoadableSpecial } from '@routes/Specials'

// TODO: Update router and types
type RouteConfigOveride = Omit<RouteConfig, 'component'>

export const Routes: RouteConfigOveride[] = [
  {
    name: 'screen',
    exact: true,
    path:
      '/:screenName(articles|cards|razbor|games|shapito|podcasts|under-the-sun)?',
    component: LoadableScreen,
    getUrl: (name = 'news', lang = 'ru') => `api/v1${lang}/screens/${name}`
  },
  {
    name: 'material',
    exact: true,
    path: [
      '/:material(feature)/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:slug',
      '/:material(games|quiz|survey|cards|slides|podcasts|brand|pages)/:slug'
    ],
    component: LoadableMaterial
  },
  {
    name: 'account',
    exact: true,
    path: '/account',
    component: LoadableAccount
  },
  {
    name: 'membership',
    exact: true,
    path: '/membership',
    component: LoadableMembershipContainer
  },
  {
    name: 'special',
    exact: true,
    path: '/specials/:slug',
    component: LoadableSpecial
  },

  {
    name: '404',
    path: '*',
    error: true
  }
]
