import React, { useState } from 'react'
import { CoverProps } from './Cover.types'
import { makeClassName, ClassNames } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'
import generateGradient from '../utils/generateGradient'
import { RenderBlocks } from '../RenderBlocks'
import { MediaCaption } from '../MediaCaption'
import { Image } from '../Image'
import { Popover } from '../Popover'

import styles from './Cover.module.css'

export const Cover: React.FC<CoverProps> = ({
  block: {
    only_on: onlyOn,
    data: {
      blocks,
      cover: { credit, caption, urls, mobile_ratio: mobileRatio, gradients, cc }
    }
  },
  styleContext
}) => {
  const [isPopoverShown, setIsPopoverShown] = useState(false)

  const style: React.CSSProperties = {}

  let classNames: ClassNames = [
    [styles.root, true],
    [styles[onlyOn], !!onlyOn && !!styles[onlyOn]]
  ]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  if (gradients) {
    const theme = [gradients.text_rgb === '0,0,0' ? 'dark' : 'light']

    classNames = makeStyleContext(classNames, theme, styles)

    style.backgroundImage = generateGradient(
      gradients.bg_rgb,
      'mediaBlockBottom'
    )
  }

  const renderCC = (context?: string): JSX.Element => (
    <MediaCaption
      credit={credit}
      caption={caption}
      styleContext={context || styleContext}
    />
  )

  return (
    <div data-testid="cover" className={makeClassName(classNames)}>
      <div className={styles.image}>
        <Image
          optimized={urls}
          ratio={mobileRatio}
          display="narrow"
          lazy={true}
        />
      </div>
      <div className={styles.body} style={style}>
        {blocks.map((item) => (
          <RenderBlocks
            key={item.id}
            block={item}
            styleContext={styleContext}
          />
        ))}
      </div>
      {cc === 'button' && (
        <button
          className={styles.control}
          type="button"
          aria-label="Open"
          onClick={(): void => {
            setIsPopoverShown(!isPopoverShown)
          }}
        />
      )}

      {cc === 'button' && isPopoverShown && (
        <Popover
          onClose={(): void => {
            setIsPopoverShown(false)
          }}
        >
          {renderCC('isInPopover')}
        </Popover>
      )}
    </div>
  )
}
