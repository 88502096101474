import React from 'react'

import { ClassNames, makeClassName } from '../utils/makeClassName'
import makeStyleContext from '../utils/makeStyleContext'

import { MaterialNoteProps } from './MaterialNote.types'

import styles from './MaterialNote.module.css'

export const MaterialNote: React.FC<MaterialNoteProps> = ({
  block: { data },
  styleContext
}) => {
  let classNames: ClassNames = [[styles.root, true]]

  if (styleContext) {
    classNames = makeStyleContext(classNames, styleContext, styles)
  }

  return (
    <>
      <div data-testid="material-note" className={makeClassName(classNames)}>
        {data &&
          data.map((item) => (
            <p
              className={styles[item.type]}
              key={item.id}
              dangerouslySetInnerHTML={{ __html: item.data }}
            />
          ))}
      </div>
    </>
  )
}
