import { fetchMaterial } from '@store/Materials/materialsActions'
import { handleError } from '@utils/handleError'
import { all, call, put, takeEvery } from 'redux-saga/effects'

import * as api from '../api'

/*
 Запрос за данными материала в апи
 */

export function* fetchMaterialSaga({
  payload
}: ReturnType<typeof fetchMaterial.request>) {
  try {
    const response = yield call(api.fetchMaterial, payload)
    yield put(fetchMaterial.success({ response }))
  } catch (err) {
    yield call(handleError, err, fetchMaterial.failure.type)
    yield put(fetchMaterial.failure())
  }
}

export default function* appSaga() {
  yield all([takeEvery(fetchMaterial.request.type, fetchMaterialSaga)])
}
