import React from 'react'

import { makeClassName } from '../utils/makeClassName'

import { icons } from './icons'
import { SvgSymbolProps } from './SvgSymbol.types'

import styles from './SvgSymbol.module.css'

export const SvgSymbol: React.FC<SvgSymbolProps> = ({
  icon,
  size,
  styleContext
}) => {
  const data = icons[icon]

  return (
    <svg
      data-testid="svg-symbol"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={makeClassName([
        [styles.root, true],
        [styles[size], !!styles[size]],
        [styles[icon], !!styles[icon]],
        [styles[styleContext], !!styles[styleContext]]
      ])}
      width={data.width}
      height={data.height}
      viewBox={`0 0 ${data.width} ${data.height}`}
      dangerouslySetInnerHTML={{ __html: data.content }}
    />
  )
}
